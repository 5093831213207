/* Content List CSS */

.simg {
  width: 100%;
}

.podcastTag-content {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  display: inline-block !important;
  align-items: center !important;
  letter-spacing: -0.011em !important;
  text-transform: uppercase !important;
  color: #8DC211 !important;
  border: 2px solid#8DC211 !important;
  padding: 1px 14px !important;
  margin: 0px 10px 0 0px !important;
  border-radius: 12px !important;
  float: none !important;
  box-shadow: none !important;

}

.playlistTag-content {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  display: inline-block !important;
  align-items: center !important;
  letter-spacing: -0.011em !important;
  text-transform: uppercase !important;
  color: #5b544e !important;
  border: 2px solid#5b544e !important;
  padding: 1px 14px !important;
  margin: 0px 10px 0 0px !important;
  border-radius: 12px !important;
  float: none !important;
  box-shadow: none !important;

}

.liveTag-content {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  display: inline-block !important;
  align-items: center !important;
  letter-spacing: -0.011em !important;
  text-transform: uppercase !important;
  color: #1138c2 !important;
  border: 2px solid#1138c2 !important;
  padding: 1px 14px !important;
  margin: 0px 10px 0 0px !important;
  border-radius: 12px !important;
  float: none !important;
  box-shadow: none !important;

}

.videoTag-content {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  display: inline-block !important;
  align-items: center !important;
  letter-spacing: -0.011em !important;
  text-transform: uppercase !important;
  color: #d52b1d !important;
  border: 2px solid#d52b1d !important;
  padding: 1px 14px !important;
  margin: 0px 10px 0 0px !important;
  border-radius: 12px !important;
  float: none !important;
  box-shadow: none !important;

}

.cont-content {
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 14px !important;
  /* letter-spacing: -0.011em !important; */
  color: #82786F !important;
  margin: 8px 10px 0 4px !important;
  box-shadow: none !important;
  text-align: left !important;
  display: block !important;
}

.upName-content {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  /* letter-spacing: -0.011em !important; */
  color: #82786F !important;
  margin: 6px 4px !important;
  box-shadow: none !important;
  text-align: left;
  width: 70%;
}

.desc-content {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  /* letter-spacing: -0.011em !important; */
  color: #82786F !important;
  margin: 6px 4px !important;
  box-shadow: none !important;
  text-align: left;
  width: 70%;
}

.svgIcon-content {
  margin: 0 auto;
  vertical-align: bottom;
  line-height: normal;
  height: -webkit-fill-available;
  margin-top: 60%;
  display: inline !important;
  width: 24px;
}

.svgIcon-content-playlist {
  margin: 0 auto;
  vertical-align: bottom;
  line-height: normal;
  height: fit-content;
  margin-top: 80%;
  display: inline !important;
  width: 42px;
}

.sOuter {
  background: #fff !important;
  margin: 10px 6px 20px 0px !important;
  width: 95% !important;
}

.topTag {
  box-shadow: none !important;
  margin: 15px 0 15px 0;
  /* font-weight: 600; */
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.011em;
  color: #82786F !important;
  padding: 0px 0.6%;
}

.topTag span {
  font-size: 16px;
  color: #D52B1D;
}

.loadSvg {
  width: 25px;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.content-list-item-wrapper {
  padding-left: 10px;
}


@media (max-width:960px) {

  .desc-content {
    width: 100% !important;
  }

  .svgIcon-content {
    margin-top: -10% !important;
    width: 25px !important;
  }
}

@media (max-width:700px) {
  .svgIcon-content {
    margin-top: -10% !important;
    width: 25px !important;
  }
}
@media (max-width:600px) {

  .desc-content {
    width: 100% !important;
  }

  .svgIcon-content {
    position: relative;
    height: 40% !important;
    margin-top: 136% !important;
    display: inline !important;
    line-height: normal;
    margin: 80% auto 0;
    vertical-align: bottom;
    width: 25px !important;
  }

  .sOuter {
    width: 270px !important;
    margin: 0 !important;
    margin-right: 12px !important;
  }
}


@media (max-width:599px) {
  .content-list-item-wrapper {
    padding-left: 0px;
    margin: 10px 0 20px 0 !important;
  }
}

.LeftAlign {
  text-align: left !important;
}

.margintop {
  margin-top: 1% !important;
}

.cont-desc2 {
  height: 10% !important;
}

.margintop2 {
  margin-top: 0% !important;
}

@media (max-width: 325px) {
  .svgIcon-content {
    margin-top: 100% !important;
    width: 25px !important;
    top: -5% !important;
    position: relative !important;
  }
}