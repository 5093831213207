.root {
    margin-right: 20px;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
    margin-bottom: 20px
}

.pdftitle {
    color: #82786f;
    font-weight: 600 !important;
    font-size: 18px !important;
}

.pdftxt {
    color: #82786f;
    font-size: 14px !important;
}

.pdfbutton {
    background: #e41e34;
    border-radius: 4px !important;
    padding: 4px 10px 8px 10px;
    color: #fff;
    text-decoration: none;
}

.cardCont {
    border-top: 4px solid #e41e34;
    padding-bottom: 0px !important;
}

.pdfbuttonIn {
    position: relative;
    top: 3px !important;
}

.m_1_2 {
    margin: 1% 0  !important;
}

.selTxt {
    border-bottom: 1px solid #e41e34;
    margin-bottom: 20px !important;
    padding-bottom: 15px !important;
}

.quizbody {
    max-height: 270px !important;
    overflow-y: scroll !important;
}

.attemptquiz {
    top: 300px !important;
    border-radius: 4px;
}
.form-question {
    margin-top: 5px;
  border-bottom: 1px solid #ccc;
}
.modal-title2 {
    font-weight: 400;
    font-size: 16px;
}
.modal-title3 {
    background-color: #f9f9f9 !important;
  border-bottom: 1px solid #ccc;
  padding: 10px;

}
.quiz-title {
    color: #82786f !important;
    font-size: 18px !important;
    font-family: 'DINPro' !important;
    font-weight: 700 !important;
    background-color: #f9f9f9 !important;
    line-height: 24px !important;
    padding: 10px;
}
@media only screen and (max-device-width: 1024px) and (orientation:portrait) {
    .quizbody {
        max-height: 1000px !important;
        overflow-y: scroll !important;
    }
    .attemptquiz {
        top: 500px !important;
        border-radius: 4px;
    }
}
@media (max-width:960px) {
    .quizbody {
        max-height: 700px !important;
        overflow-y: scroll !important;
    }
    .attemptquiz {
        top: 500px !important;
        border-radius: 4px;
    }
}
@media only screen and (max-device-width: 414px) and (orientation:portrait) {
    .quizbody {
        max-height: 300px !important;
        overflow-y: scroll !important;
    }
    .attemptquiz {
        top: 300px !important;
        border-radius: 4px;
    }
}
@media all and (min-device-width: 375px) and (max-device-height: 736px) and (orientation:landscape) {
    .quizbody {
        max-height: 100px !important;
        overflow-y: scroll !important;
    }
    .attemptquiz {
        top: 150px !important;
        border-radius: 4px;
        height: calc(100% - 120px) !important;
        overflow: scroll;
    }
}