.speaker-modal .speaker_upload_btn {
    width: 150px;
    height: 150px;
    border-radius: 50% !important;
    font-size: 18px !important;
    text-align: center;
    color: #82786f !important;
    background-color: #f0f0f0 !important;
    font-weight: 700 !important;
    overflow: hidden;
    padding: 30px !important;
    white-space: normal;
    text-transform: capitalize !important;
    margin: 0 33% !important;
}
.speaker-modal .MuiDialog-paperWidthSm {
    max-width: 35% !important;
}
.speaker-modal .formControl .MuiFormLabel-root {
    font-weight: 700 !important;
}
.speaker-modal .modalBtn {
    width: 50% !important;
    margin: 0;
}
table tr td:last-child, table tr th:last-child {
    padding-right: 30px !important;
}
table tr td:first-child, table tr th:first-child {
    padding-left: 30px !important;
}
.MuiAlert-standardWarning .MuiAlert-icon {
    /* color: #ff9800; */
    display: none;
}
.modalstyle{
    /* width: 33% !important; */
}
.MuiAlert-message{
    word-break: break-word;
    font-family: dinpro;
}
.validationpopupFooter{
    margin-left:65% !important; 
}
.validationPopup{
    left: 46.1% !important;
}
.validationPopup .MuiAlert-root {
    margin: 10px 0;
}
.nonClickable{
    cursor: text !important;
}
.imageBorderRadius{
    border-radius: 30px !important;
}
@media (max-width: 663.95px) {
    .speaker-modal .MuiDialog-paperWidthSm {
        max-width: 100% !important;
    }
    .speaker-modal .speaker_upload_btn {
        margin: 0 23% !important;
    }
    .validationPopup{
        left: 40% !important;
    }
}