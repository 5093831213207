.action_buttons {
    margin-right: 5px !important;
}
.MuiButton-contained {
        background-color: #ffffff !important;
        box-shadow: none !important;
}
.selectClas {
    border: 1.5px solid #e1e1e1;
}
.createNewClass {
 float: right;
 width: 80%;
    /* max-width: 8.666667% !important; */
    /* margin-right: 50px !important; */
}
.MuiGrid-grid-xs-12 {
    /* max-width: 11.666667% !important; */
    /* flex-basis: 16.666667%; */
}
.lableTranslationEdit{
    
    padding: 0px;
}

.textAreaStyle {
    padding: 3px 1px 8px 164px;
}
.marginTop{
    margin-top: 2% !important;
}
a.modalBtn{
    text-decoration: none;
  }

.greyBox {
    border-bottom: 1px solid #ddd !important;
    /* padding: 10px 20px !important; */
    color: #82786f !important;
    font-size: 14px !important;
    font-family: 'DINPro' !important;
    font-weight: 500 !important;
    background-color: #f9f9f9 !important;
    line-height: 24px !important;
    padding-left: 35px !important;
    padding-right: 35px !important;
}
.greyBox .MuiGrid-grid-xs-5 {
    
    font-size: 14px !important;
    font-weight: 800;
    font-family: 'DINPro' !important;
}

.header {
    /* background: #f9f9f9; */
    
    background: #fff;
    border-bottom: none !important;
}
.quizDetailsField {
    background: #fff;
}

.addIconBtn {
  color: #1592E6 !important;
  cursor: pointer !important;
  /* margin: 20px 0px !important; */
  padding: 0 !important;
}
.addAnswerBtn {
    color: #1592E6 !important;
  cursor: pointer !important;
  margin: 0 !important;
  padding: 0 !important;
  text-transform: none !important;
  font-size: 16px !important;

}
.addAnswerBtn .MuiButton-label img {
    margin-right: 10px !important;
    margin-left: 40px;
  }
.quesForm {
    margin-top: 20px;
  }

.quizsection {
    margin: 0px 40px;
    font-weight: 700;
    color: #82786f;
    font-family: 'DINPro';
    /* CSS issue observed while adding the question and answers */
    word-break: break-all;
}
@media (max-width:767px) {
    .custom-quiz-btn{
        width: 85% !important;
    }
}