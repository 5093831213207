.searchBx .MuiAutocomplete-inputRoot[class*="MuiInput-root"][class*="MuiInput-marginDense"] .MuiAutocomplete-input {
    padding: 4px 20px 5px 5px;
}

.table {
    min-width: 750;
    margin-top: 10px;
}

.content-mgmt table tr td:first-child,
.content-mgmt table tr th:first-child {
    padding-left: 30px !important;
}

.actnBtn {
    color: #a59d95;
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
}
.handIcon{
    cursor: pointer;
}
.actnBtn:hover {
    color: #23527c;
}

.actnBtnDel {
    color: #a59d95;
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.actnBtnDel:hover {
    color: #d52b1e;
}

#tableSearchBox {
    font-size: 14px !important;
    color: #82786f !important;
    font-weight: 600 !important;
}

.mlr20 {
    margin: 0 20px !important;
}

.mp0 {
    margin: 0 !important;
    padding: 0 !important;
}

/* Switch CSS */
.switch-hd {
    text-align: right;
}

.MuiSwitch-track {
    background-color: #d52b1e !important;
    opacity: 1 !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: #fafafa !important
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: #139751 !important
}

@media (max-width:767px) {
    .switch-hd {
        text-align: left;
        margin: 10px 20px;
    }
}

a {
    text-decoration: none;
}

/* custom pagination css for content management page and ButtonPagination.jsx*/
.pagination-component {
    text-align: center;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.pagination-component .pages {
    display: inline-flex;
}

.pagination-component .pages span {
    display: inline-flex;
    align-items: center;
}

.pagination-component button {
    border-radius: 5px;
    background: none;
    margin: 5px;
    border: none;
    padding: 5px 10px;
}

.pagination-component button.current {
    border-radius: 5px;
    background: #F9FAFB;
}

.pagination-component button.next,
.pagination-component button.previous {
    border-radius: 5px;
    border: 1px solid #e0e0ff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: 1px 1px 5px 0px; */
}

.pagination-component button.next:hover,
.pagination-component button.previous:hover {
    cursor: pointer;
    box-shadow: inset 0px 0px 15px -9px;
}

.pagination-component button.previous img {
    margin: 0px 5px 0px 0px;
}

.pagination-component button.next img {
    margin: 0px 0px 0px 5px;
}

/* media 475px */
@media screen and (max-width: 425px) {
    .pagination-component {
        padding: 0rem;
    }

    .pagination-component button {
        padding: 2px 2px;
        margin: 2px 2px;
    }
}

/* custom pagination css for content management page and ButtonPagination.jsx ends*/

/* content status button for content management page */

.content-status .single-content {
    display: grid;
    grid-template-columns: 0.2fr 0.7fr;
    padding: 5px 5px;
    border-width: 2px;
    border-radius: 25px;
    border-style: solid;
    width: min-content;
}

.custom-tooltip .content-status .content-head div:nth-child(2) {
    font-size: 14px;
}

.content-status .multiple-content {
    border-radius: 25px;
    border: 2px solid #344054;
    display: grid;
    padding: 5px;
    grid-template-columns: 1fr 0.2fr;
    justify-content: center;
    align-items: center;
    width: max-content;
}

.content-status .multiple-content .text {
    text-align: center;
    margin: 0px 5px 0px 5px;
}

.content-status .multiple-content:hover {
    background-color: #e0e0ff;
}

.content-status .text {
    margin-right: 3px;
}

.content-status .dot {
    height: 10px;
    width: 10px;
    align-self: center;
    border-radius: 50px;
    margin: auto 5px;
    background-color: gray;
}

.content-status .Draft {
    border-color: #475467;
    color: #475467;
}

.content-status .Draft .dot {
    background-color: #475467;
}

.content-status .Pending {
    border-color: #0086C9;
    color: #0086C9;
}

.content-status .Pending .dot {
    background-color: #0086C9;
}

.content-status .Rejected {
    border-color: #D92D20;
    color: #D92D20;
}

.content-status .Rejected .dot {
    background-color: #D92D20;
}

.content-status .Published {
    border-color: #079455;
    color: #079455;
}

.content-status .Published .dot {
    background-color: #079455;
}

.content-status .Inactive {
    border-color: #DC6803;
    color: #DC6803;
}

.content-status .Inactive .dot {
    background-color: #DC6803;
}

/* content status button for content management page end*/

/* content managemnt page css for ContentStatus.jsx CustomTooltip*/
.custom-tooltip {
    padding: 3px;
    border-radius: 12px;
    border: 1px;
    color: #475467;
}

.custom-tooltip .tooltip-headers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    border-bottom: 1px solid #EAECF0;
    font-size: 12px;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
}

.custom-tooltip .tooltip-headers div {
    margin: 5px;
    padding: 2px 4px 2px 4px;
    font-size: 14px;
}

.custom-tooltip .tooltip-body div {
    margin: 5px;

}

.status-definition {
    padding: 4px 12px 4px 12px;
}

.custom-tooltip .tooltip-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    border-bottom: 1px solid #EAECF0;
    font-size: 12px;
}

.custom-tooltip .content-status .single-content {
    padding: 2px 2px;
    margin-right: 20px;
    height: max-content;
    width: unset;
}

/* content management page css for ContentStatus.jsx CustomTooltip page end*/

/* content management page aactions three dots */
.action-items {
    display: flex;
    justify-content: right;
}

.action-items .edit-options .actnBtn .blue_button::after {
    content: 'Edit'

}

.action-items div .action-icon {
    margin: 12px 2px;
}

.action-items .vertical-circles {
    width: min-content;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.action-items .action-icon {
    background-color: #D92D20;
    width: min-content;
    padding: 6px;
    margin: 12px 2px;
    border-radius: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.action-items .action-icon.no-bg {
    background-color: transparent;
}

/* table dots end */

/* content management headers styles */
.content-section .content-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}


.content-section .label-title .label-hd-title {
    color: #303030;
    font-size: 20px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
}

.content-section .label-title .content-count {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #8B847E;
}

.content-section .content-buttons button {
    width: 100%;
    border-radius: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    border-radius: 4px;
    font-family: 'DINPro';
    font-weight: 900;
    padding: 5px 10px;
}

.content-section .content-buttons button:hover {
    cursor: pointer;
}

.content-section .content-buttons .statusbutton {
    border: 1px solid transparent;
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
}

.content-section .content-buttons .statusbutton:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.content-section .content-buttons .downloadbutton {
    border: 1px solid #D0D5DD;
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
}

.content-section .content-buttons .uploadbutton {
    background-color: #D92D20;
    border: 1px solid #D92D20;
    color: white;
}

/* tablet and mobile styles for comtent management header */
@media screen and (max-width: 790px) {
    .content-section .content-buttons {
        grid-template-columns: 1fr;
    }

    .action-items div .action-icon {
        margin: 2px 2px;
    }
}


/* mobile styles for comtent management header */
@media screen and (max-width: 601px) {
    .content-section {
        padding: 0px !important;
    }

    .content-section .label-title {
        margin: 10px 0px !important;
    }

    .content-section .label-title .label-hd-title {
        font-size: 15px !important;
    }

    .content-section .label-title .content-count {
        font-size: 14px !important;
    }

    .custom-tooltip .content-status .single-content {
        min-width: 80px;
        margin-right: unset;
    }

    .action-items div .action-icon {
        margin: 2px 2px;
    }
}

.content-management-table-body.MuiTableRow-root.Mui-selected,
.content-management-table-body.MuiTableRow-root.Mui-selected:hover {
    background-color: transparent;
}

/* content management headers styles */

/* content modal */
.status-modal .custom-tooltip {
    border-radius: 5px;
    border: 1px solid #EAECF0;
    padding: 0rem;
}

.status-modal .custom-tooltip .tooltip-body {
    display: block;
}


.status-modal .custom-tooltip .tooltip-body>div {
    display: grid;
    grid-template-columns: 0.2fr 0.8fr;
    margin: 0px;
    border: 1px solid #EAECF0;
}

.status-modal .custom-tooltip .tooltip-headers {
    grid-template-columns: 0.2fr 0.8fr;
    font-size: 0.75rem;
}


.status-modal .modal-heading {
    display: grid;
    grid-template-columns: 0.1fr 0.8fr 0.1fr;
    justify-content: space-between;
    margin: 10px 10px 0px 10px;
}

.status-modal .modal-heading .modal-heading-text {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
}

.status-modal .modal-heading .modal-heading-text .modal-heading-subtext {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.status-modal .modal-heading .modal-heading-text .modal-heading-subtext-bold {
    color: #475467;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
}

.status-modal .info-modal {
    align-self: center;
    justify-self: center;
    border: 1px solid #EAECF0;
    border-radius: 5px;
    padding: 4px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.status-modal .close-modal {
    /* align-self: center; */
    justify-self: flex-end;
}

.status-modal .close-modal button {
    padding: 0px;
    margin: 5px 10px 0px 10px;
}

/* modal styling for publish */

.content-publish-modal .post-submission-header {
    text-align: center;
}

.content-publish-modal .post-submission-header .content-submitted-check {
    border-radius: 50%;
    width: min-content;
    background: #DCFAE6;
    margin: 10px auto;
    padding: 10px;
}

.content-publish-modal .post-submission-header .content-submitted-check .MuiSvgIcon-colorAction {
    color: #11993E;
}

.content-publish-modal .post-submission-header .content-submitted {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
    text-align: center;
}

.content-publish-modal .modal-content {
    height: 4.5rem;
    padding: 1rem 1.5rem;
}

.content-publish-modal .modal-content .grey-text {
    background-color: #EDECEA;
    padding: 1rem 1.5rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.content-publish-modal .modal-content .grey-text.centered {
    text-align: center;
}

.content-publish-modal .modal-actions button {
    color: white;
    background-color: #D92D20;
    border: 1px solid #D92D20;
    padding: 0.625rem 1.125rem;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin: 0px 5px;
}

.content-publish-modal .modal-actions .modal-edit {
    color: white;
    background-color: #D92D20;
    border: 1px solid #D92D20;
    padding: 0.625rem 1.125rem;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin: 0px 5px;
}

.content-publish-modal .modal-actions.post-submit-actions {
    width: 100%;
}

.content-publish-modal .modal-actions.post-submit-actions button {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.content-publish-modal .modal-actions.post-submit-actions button img {
    margin: 0px 5px 0px 0px;
}

.content-head {
    padding-top: 8px;
    padding-bottom: 8px;
}

.content-publish-modal .modal-actions button.modal-cancel {
    border: 1px solid #e0e0ff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #0000;
    color: #344054
}

.content-publish-modal .modal-actions button:hover {
    cursor: pointer;
}

.mgmt_header .search-section .search-section-subsection {
    display: grid;
    grid-template-columns: 0.8fr 0.1fr 0.1fr;
}

.mgmt_header .search-section .search-section-subsection .reset {
    margin: auto;
    height: 36px;
    width: 36px;
    margin-right: -5px;
}

.mgmt_header .search-section .search-section-subsection .switch {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
}

.mgmt_header .search-section .search-section-subsection .filter {
    display: flex;
    justify-content: flex-end;
    margin-right: -20px;
}

.mgmt_header .search-section {
    border-radius: 12px 12px 0px 0px;
}

.mgmt_header .filter-section {
    display: grid;
    background-color: #F9FAFB;
    position: relative;
    z-index: 10;
    border-radius: 0px 0px 12px 12px;
    padding: 12px 16px 12px 21px;
}

.mgmt_header .filter-section.open {
    animation-name: move-up;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.mgmt_header .filter-section.close {
    animation-name: move-down;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes move-up {
    0% {
        height: 0px;
        opacity: 0;
    }

    100% {
        min-height: 100px;
        opacity: 1;
    }
}

@keyframes move-down {

    0% {
        min-height: 100px;
        opacity: 1;
    }

    100% {
        height: 0px;
        opacity: 0;
    }

}

.mgmt_header .filter-section .filter-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 1rem;
    height: 30px;
}


.mgmt_header .filter-section .filter-grid .filter-dropdown .filter-arrow-icon {
    color: #475467;
    transform: rotate(-90deg);
}

.mgmt_header .filter-section .filter-grid .filter-dropdown .filter-arrow-icon.rotate-arrow {
    transform: rotate(0deg);
}

.mgmt_header .filter-section .filter-grid .filter-dropdown {
    position: relative;
}

.mgmt_header .filter-section .filter-grid .filter-dropdown .filter-clickarea {
    display: flex;
    align-items: center;
}

.mgmt_header .filter-section .filter-grid .filter-dropdown .filter-clickarea .filter-title {
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
}

.mgmt_header .filter-section .filter-grid .filter-dropdown .filter-dropdown-popout {
    position: absolute;
    background-color: #ffffff;
    border-radius: 0.75rem;
    padding: 1rem 1.5rem;
    top: 100%;
    z-index: 20;
}

.mgmt_header .filter-section .filter-grid .filter-dropdown:nth-last-child(1) .filter-dropdown-popout,
.mgmt_header .filter-section .filter-grid .filter-dropdown:nth-last-child(2) .filter-dropdown-popout {
    right: 0px;
}

.mgmt_header .filter-section .filter-grid .filter-dropdown .filter-dropdown-popout .filter-autocomplete {
    min-width: 300px;
}

.mgmt_header .filter-section .filter-grid .filter-dropdown:hover {
    cursor: pointer;
}

.mgmt_header .filter-section .filter-selected-grid {
    display: flex;
    z-index: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.mgmt_header .filter-section .filter-selected-grid .selected-filter {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 24px;
    margin: 2px;
    line-height: 22px;
    background-color: #fff;
    border: 1px solid rgb(232, 232, 232);
    border-radius: 2px;
    box-sizing: content-box;
    padding: 0px 4px 0px 10px;
    outline: 0px;
    overflow: hidden;
    width: min-content;
}

.mgmt_header .filter-section .filter-selected-grid .selected-filter span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.875rem;
}

.mgmt_header .filter-section .filter-selected-grid .selected-filter svg {
    font-size: 12px;
    cursor: pointer;
    color: #98A2B3;
    padding: 4px;
}

.mgmt_header .filter-section .filter-buttons {
    display: flex;
    flex-flow: row-reverse;
    align-items: flex-end;
}

.mgmt_header .filter-section .filter-buttons .pill.red {
    color: #E02F2B;
}

.mgmt_header .filter-section .filter-buttons .pill {
    background-color: transparent;
    color: #475467;
    border: none;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    max-height: 30px;
    margin: 0px 6px;
}

.mgmt_header .filter-section .filter-buttons .pill:hover {
    cursor: pointer;
    background-color: #f0f0f0;
    border-radius: 25px;
}

.mgmt_header .search-section .search-section-subsection .switch .MuiSwitch-track {
    background-color: #e5e6e6 !important;
    opacity: 1 !important;
}

.mgmt_header .search-section .search-section-subsection .switch .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: #D92D20 !important;
    opacity: 1 !important;
}


.mgmt_header .search-section .search-section-subsection .reset button {
    background-color: white;
    border-radius: 5px;
    border: 1px solid #D0D5DD;
    color: rgba(0, 0, 0, 0.87);
    padding: 5px 6px;
}

.mgmt_header .search-section .search-section-subsection .filter button {
    border-radius: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 5px 6px;
    text-wrap: nowrap;
    border: 1px solid #D0D5DD;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    color: #344054
}


.downloadMenu {
    margin-top: 48px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

/* tablet styles for content management header */
@media screen and (max-width: 1024px) {
    .mgmt_header .search-section .search-section-subsection .filter {
        margin: auto;
    }

    .downloadMenu {
        margin-top: 58px;
    }
}

@media screen and (max-width: 768px) {
    .mgmt_header .search-section .search-section-subsection .filter {
        margin: auto;
    }

    .mgmt_header .filter-section .filter-grid {
        overflow-x: scroll;
        height: fit-content;
        position: relative;
    }

    .mgmt_header .filter-section .filter-grid .filter-dropdown .filter-dropdown-popout {
        position: relative;
        top: unset;
    }

    .downloadMenu {
        margin-top: 78px;
        margin-left: 2%;
    }
}

/* mobile styles for content management header */
@media screen and (max-width: 601px) {
    .mgmt_header .search-section .search-section-subsection .filter {
        margin: auto;
    }

    .mgmt_header .filter-section .filter-grid {
        overflow-x: scroll;
        overflow-y: scroll;
        grid-template-columns: 1fr;
    }

    .mgmt_header .filter-section.open {
        animation-name: move-up;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    .mgmt_header .filter-section.close {
        animation-name: move-down;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    @keyframes move-up {
        0% {
            height: 0px;
            opacity: 0;
        }

        100% {
            height: 600px;
            opacity: 1;
        }
    }

    @keyframes move-down {

        0% {
            height: 600px;
            opacity: 1;
        }

        100% {
            height: 0px;
            opacity: 0;
        }
    }

    .downloadMenu {
        margin-top: 50px !important;
        margin-left: unset !important;
    }
}

.filterLabel {
    padding: 0px 0px 4px;
    line-height: 1.5;
    display: block;
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
}

.filterLabel.mandatory::after {
    color: #E02F2B;
    content: '*';
    padding: 5px;
    vertical-align: middle;
}

.filterInputWrapping {
    width: 300px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 4px;
    padding: 1px;
    display: flex;
    flex-wrap: wrap;
}

.filterInputWrapping.text-input {
    width: 300px;
    padding: 1px;
    display: flex;
    flex-wrap: wrap;
    border: 0;
    gap: 10px;
}

.filterInputWrapping:focused {
    width: 300px;
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.filterInputWrapping.text-input input {
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 4px;
}

.filterInputWrapping.text-input button {
    background-color: #D92D20;
    border: 1px solid #D92D20;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.filterInputWrapping input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
}

.filterInputWrapping.error {
    border: 1px solid #f44336;
}

.filterInputWrapping.error:focused {
    border-color: #f44336;
}

.filterUl {
    width: 300px;
    margin: 2px 0 0;
    padding: 0;
    position: absolute;
    list-style: none;
    background-color: #fff;
    overflow: auto;
    max-height: 250px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 10;
}

.filterUl li {
    padding: 5px 12px;
    display: flex;
}

.filterUl li span {
    padding: 5px 12px;
    display: flex;
}

.filterUl li svg {
    display: none;
}

.filterUl li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;
}

.filterUl li[aria-selected='true'] svg {
    color: #e6f7ff;
}

.filterUl li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;
}

.filterUl li[data-focus='true'] svg {
    color: #000;
}

.filterTag {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 24px;
    margin: 2px;
    line-height: 22px;
    background-color: rgb(250, 250, 250);
    border: 1px solid rgb(232, 232, 232);
    border-radius: 2px;
    box-sizing: content-box;
    padding: 0px 4px 0px 10px;
    outline: 0px;
    overflow: hidden;
    width: min-content;
}

.filterTag span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.filterTag svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
}


/* new report modal */
.content-report-modal .modal-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
}

.content-report-modal .modal-content .filter-dropdown-popout .some-filter {
    position: relative;
}

.content-report-modal .modal-content .filter-dropdown-popout:nth-child(even) {
    justify-self: flex-end;
}

.content-report-modal .modal-actions {
    display: flex;
}

.content-report-modal .modal-actions .modal-download {
    display: flex;
    align-items: center;
}

.content-report-modal .modal-actions button {
    color: white;
    background-color: #D92D20;
    border: 1px solid #D92D20;
    padding: 0.625rem 1.125rem;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin: 0px 5px;
}

.content-report-modal .modal-actions button:hover {
    cursor: pointer !important;
}

.content-report-modal .modal-actions .modal-edit {
    color: white;
    background-color: #D92D20;
    border: 1px solid #D92D20;
    padding: 0.625rem 1.125rem;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin: 0px 5px;
}

.content-report-modal .modal-actions button.modal-cancel {
    border: 1px solid #e0e0ff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #0000;
    color: #344054
}

.expiration-label {
    padding: 0px 0px 4px;
    line-height: 1.5;
    display: block;
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
}

.expiration-modal-button {
    width: 300px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 4px;
    padding: 1px;
    display: flex;
    flex-wrap: wrap;
    height: 2rem;
    align-items: center;
    font-family: 'DINPro';
}

.expiration-modal-button span {
    color: gray;
    font-size: 14px;
    font-family: sans-serif;
}

.expiration-modal-button svg {
    color: #475467;
    align-self: center;
    margin-right: 10px;
}

.expiration-dialog {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.expiration-dialog .calendar-container {
    border-bottom: 1px solid #F2F4F7;
}

.expiration-dialog .calendar-container:nth-child(1) {
    border-right: 1px solid #F2F4F7;
}

/* .expiration-dialog .calendar-container>header{
    border-right: 1px solid #F2F4F7;
} */
.expiration-dialog-actions {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
}

.expiration-dialog-actions .selected-dates {
    display: flex;
    margin-left: 16px;
    gap: 10px;
}

.expiration-dialog-actions .selected-dates div {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin: 0px 5px;
    border: 1px solid #e0e0ff;
    border-radius: 5px;
    padding: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #0000;
    color: #344054;
    min-width: 90px;
    min-height: 19px;
}

.expiration-dialog-actions .action-buttons {
    display: flex;
    justify-content: flex-end;
}

.expiration-dialog-actions button {
    color: white;
    background-color: #D92D20;
    border: 1px solid #D92D20;
    padding: 0.625rem 1.125rem;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin: 0px 5px;
}

.expiration-dialog-actions button.modal-cancel {
    border: 1px solid #e0e0ff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #0000;
    color: #344054
}

.expiration-dialog-actions button:hover {
    cursor: pointer;

}


/* tablet and mobile styles for content management report modal */
@media screen and (max-width: 790px) {}


/* mobile styles for content management report modal */
@media screen and (max-width: 601px) {
    .content-report-modal .modal-content {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16px;
    }

    .content-report-modal .modal-content .filter-dropdown-popout:nth-child(even) {
        justify-self: unset;
    }

    .content-report-modal .modal-content .filterInputWrapping {
        width: 100%;
    }

    .content-report-modal .modal-content .expiration-modal-button {
        width: 100%;
    }

    .expiration-dialog-actions {
        grid-template-columns: 1fr;
    }

    .expiration-dialog-actions .selected-dates {
        margin-bottom: 10px;
    }

    .filterUl {
        width: 250px;
    }
}

/* snackbar css - can i move this into global css? */

.snackbar {
    color: white !important;
    background-color: #303030 !important;
    display: flex;
    padding: 6px 16px;
    border-radius: 4px;
    font-size: 0.875rem;
    align-items: center;
}

.snackbar.error {
    background-color: #D92D20 !important;
}

.snackbar img {
    padding: 12px;
}

.snackbar.success {
    background-color: #67AE72 !important;
}

.snackbar .snakcbar-close:hover {
    cursor: pointer;
}