.MuiTreeItem-root.Mui-selected>.MuiTreeItem-content .MuiTreeItem-label, .MuiTreeItem-label:hover {
    background-color: transparent !important;
}

.treeview {
    flex-grow: 1;
    max-width: 400;
}

.outer {
    padding: 0;
    margin: 0;
    width: 100%;
}

.header {
    border-bottom: 1px solid #ddd;
    color: rgb(130, 120, 111);
}

.content {
    /* margin: 10px 20px !important; */
    color: rgb(130, 120, 111);
}

.iconSvg {
    font-size: 30px !important;
    color: rgb(130, 120, 111);
}

.category_tree {
    margin: 10px 40px !important;
}

.MuiTypography-subtitle1 {
    font-weight: 600 !important;
    font-family: 'DINPro' !important;
}

.synctime {
    font-size: 16px;
    font-weight: 400;
}

.content-grn-Btn {
    background-color: #fff !important;
    color: #36b488 !important;
    border: 1px solid #36b488 !important;
    font-size: 13px !important;
    margin-right: 4px !important;
    text-transform: capitalize !important;
    font-weight: 900 !important;
}

.content-grn-Btn:hover {
    background-color: #36b488 !important;
    color: #fff !important;
}