.root {
    margin-right: 20px;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
    margin-bottom: 20px
}

.pdftitle {
    color: #82786f;
    font-weight: 600 !important;
    font-size: 18px !important;
}

.pdftxt {
    color: #82786f;
    font-size: 14px !important;
}

.pdfbutton {
    background: #e41e34;
    border-radius: 4px !important;
    padding: 4px 10px 8px 10px;
    color: #fff;
    text-decoration: none;
}

.cardCont {
    border-top: 4px solid #e41e34;
    padding-bottom: 0px !important;
}

.pdfbuttonIn {
    position: relative;
    top: 3px !important;
}

.m_1_2 {
    margin: 1% 0  !important;
}

.selTxt {
    border-bottom: 1px solid #e41e34;
    margin-bottom: 20px !important;
    padding-bottom: 15px !important;
}