.profileContainer {
    width: 82% !important;
    margin: 0 auto;
    margin-bottom: 2.5% !important;
}
.profileContainer .profileHeader{
    color: #d52b1e !important;
    font-weight: 700px !important;
    margin: 2% 0;
    border-bottom: 1px solid #d52b1e;
}
.profileHeader h6 {
    margin-bottom: 2% !important;
}
.profileContainer .content {
    border: none !important;

}
.languageForm .formControl {
    margin-top: 0px !important
}
.languageForm .formControl .MuiFormLabel-root{
    padding-top: 0px !important
}
.userDialogTitle {
    padding: 0 2% 0 92% !important;
}
.user_upload_btn{
    width: 200px;
    height: 200px;
    cursor: auto !important;
    border-radius: 50% !important;
    font-size: 18px !important;
    text-align: center;
    color: #82786f !important;
    background-color: #f9f8f8 !important;
    font-weight: 700 !important;
    overflow: visible;
    padding: 20px !important;
    white-space: normal;
    text-transform: capitalize !important;
    margin: 2% 15% !important;
    border: 1px solid #82786f !important;
    box-shadow: none !important;
}
.txtCntrSm .labeIp-user input{
    border: 1px solid #ccc;
    border-radius: 4px;
}
.editable .MuiInputBase-input{
    cursor: pointer !important;
    background-color: #ffffff !important;
    padding: 8px 4px !important;
    color: #555 !important;
    font-size: 15px !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    
}
.editable .MuiInputLabel-formControl{
    margin-top: -8px !important;
}
.editProfileBtn {
    color: #d52b1e !important;
    font-family: 'DINPro' !important;
    text-transform: unset !important;
    border-color: #d52b1e !important;
    font-size: 16px !important;
    padding: 1% 25% !important;
    margin: 2% 3% !important;
}
.profileContainer .txtCntrSm .MuiGrid-item {
    padding: 0 8px;
}

.profileContainer .textfiledBx .MuiFormLabel-root {
    font-size: 18px !important;
}
.profileFooter {
    border-top: 1px solid #d52b1e;
    width: 82% !important;
    margin: 0 auto !important;
    padding: 2% 0;
}
.greySection {
    background-color: #f5f5f5;
}
.brandArea{
    width: 82% !important;
    margin: 0 auto !important;
}
.alignBtn{
    text-align: right !important;
}

.categoryDetails .MuiFormLabel-root{
    font-size: 18px !important;
    font-weight: 700;
    padding: 4% 0 0 0;
}
.noView {
    display: none !important;
}
.category_list {
    list-style: none !important;
    width: 100%;
    display: flex;
    padding-left: 4% !important;
}
.category_list ul {
    padding-left: 2% !important;
    margin-top: 2% !important;
}
ul li {
    list-style: none !important;
}
.category0 .category_list_inner {
    font-size: 16px !important;
    color: #82786f !important;
    font-weight: bold !important;
}
.category0 .category_list_inner div{
    word-wrap: break-word; 
}
.categoryData {
    margin-top: 2% !important;
}
.category1 {
    margin-left: 1% !important
}
.categoryItems {
    width: 33% !important;
    display: table-cell !important;
}
.mobileOnly {
    display: none !important;
}
.desktopOnly {
    display: block !important;
}
.category2 {
    margin-left: 4%;
}
.category_list_inner .MuiSvgIcon-root {
    width: 17px !important;
    height: 17px !important;
}
.category_list_inner .labelTxt-user .MuiFormControlLabel-label {
    font-size: 14px !important;
}
.category_list_inner .labelTxt-user .MuiFormControlLabel-label div{
    word-wrap: break-word;
}
.black-traingle-user {
    color: black;
}


@media (max-width:500px) {

    .user_upload_btn {
        margin: 2% 11% !important;
    }
    /* .editProfileBtn{
        margin-bottom: 4% !important;
    } */
    .cancelBtn  {
        width: 100% !important;
    }
    .submitBtn {
        width: 100% !important;
    }
    .mobileOnly {
        display: block !important;
    }
    .desktopOnly {
        display: none !important;
    }

}

@media (max-width:900px) and (min-width: 601px) {  
    .editProfileBtn{
        margin-bottom: 4% !important;
    }
}

@media (max-width:600px) {  
    .editProfileBtn{
        margin-bottom: 9% !important;
        margin-left: 0 !important;
        min-width: 270px !important;
    }
}

.MuiAutocomplete-popper {
    width: 400px !important;
}

.therapeutic-area-div{
    width: 100%;
}