.simg {
  width: 100%;
  /* height: 140px; */
}

.simg-lg {
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
}

.infinite-scroll-component .simg{
  height: auto;
}
.speakerModal .modal-top {
  padding: 0 !important;
}
.speakerModal .modal-title {
  min-height: auto !important;
}
.speakerModal .modal-bottom {
  border-top: 1px solid #ccc;
}

.upNameSpeaker {
    font-weight: 600 !important;
    /* font-size: 13px !important; */
    font-size: calc(13px + 0.2vw) !important;
    line-height: 20px !important;
    letter-spacing: -0.015em !important;
    color: #A59D95 !important;
    margin: 0px 10px 4px 0px !important;
    box-shadow: none !important;
    text-align: left;
    cursor: pointer;
}
.speakerTitle {
  font-weight: 600 !important;
    /* font-size: 13px !important; */
    font-size: calc(13px + 0.2vw) !important;
    line-height: 20px !important;
    letter-spacing: -0.015em !important;
    color: #A59D95 !important;
    margin: 0px 10px 20px 0px !important;
    box-shadow: none !important;
    text-align: left;
    cursor: pointer;
}

.border0 {
  border: 0 !important;
}
.podcastTag {
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  display: inline-block !important;
  align-items: center !important;
  letter-spacing: -0.011em !important;
  text-transform: uppercase !important;
  color: #8DC211 !important;
  border: 2px solid#8DC211 !important;
  padding: 1px 14px 2px 14px !important;
  margin: 15px 10px 0 0px !important;
  border-radius: 16px !important;
  float: left !important;
  box-shadow: none !important;

}

.playlistTag {
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  display: inline-block !important;
  align-items: center !important;
  letter-spacing: -0.011em !important;
  text-transform: uppercase !important;
  color: #5b544e !important;
  border: 2px solid#5b544e !important;
  padding: 1px 14px 2px 14px !important;
  margin: 15px 10px 0 0px !important;
  border-radius: 16px !important;
  float: left !important;
  box-shadow: none !important;

}

.liveTag {
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  display: inline-block !important;
  align-items: center !important;
  letter-spacing: -0.011em !important;
  text-transform: uppercase !important;
  color: #1138c2 !important;
  border: 2px solid#1138c2 !important;
  padding: 1px 14px 2px 14px !important;
  margin: 15px 10px 0 0px !important;
  border-radius: 16px !important;
  float: left;
  box-shadow: none !important;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.videoTag {
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  display: inline-block !important;
  align-items: center !important;
  letter-spacing: -0.011em !important;
  text-transform: uppercase !important;
  color: #d52b1d !important;
  border: 2px solid#d52b1d !important;
  padding: 1px 14px 2px 14px !important;
  margin: 15px 10px 0 0px !important;
  border-radius: 16px !important;
  float: left !important;
  box-shadow: none !important;

}

.cont {
  font-weight: 600 !important;
  /* font-size: 15px !important; */
  font-size: calc(12px + 0.2vw) !important;
  line-height: 20px !important;
  letter-spacing: -0.015em !important;
  color: #A59D95 !important;
  margin: 6px 10px 0 0px !important;
  box-shadow: none !important;
  text-align: left !important;
  /* display: block !important; */
  font-family: 'DINPro-Light' !important;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cont:hover, .upName:hover {
  cursor: pointer;
  text-decoration: underline;
}
.upNameSpeaker:hover {
  text-decoration: underline;
}

.contDate {
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: -0.015em !important;
  color: #A59D95 !important;
  margin: 0px 10px 6px 0px !important;
  box-shadow: none !important;
  text-align: left !important;
  display: block;
  font-family: 'DINPro-Light' !important;
}

.upNameOuter {
  box-shadow: none !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 6px;
}

.upName {
  font-weight: 600 !important;
  /* font-size: 13px !important; */
  font-size: calc(10px + 0.2vw) !important;
  line-height: 20px !important;
  letter-spacing: -0.015em !important;
  color: #A59D95 !important;
  margin: 0px 10px 4px 0px !important;
  box-shadow: none !important;
  text-align: left;
  /* width: fit-content; */
}

.black-triangle-home {
  color: black !important;
}

.svgIcon {
  margin: 0 auto;
  vertical-align: bottom;
  line-height: normal;
  height: -webkit-fill-available;
  display: inline !important;
  width: 24px;
  position: absolute;
  /* top: 60px; */
  top: 58%;
  left: 0px;
  right: 0px;
}

.svgIcon.playlist {
  height: auto !important;
  width: 70% !important;
  bottom: 8% !important;
  top: 64% !important
}

.playmdIcon {
  height: auto !important;
  width: 70% !important;
  bottom: 8% !important;
}

.sOuter {
  background: #fff !important;
  margin: 0 10px !important;
  width: 95% !important;
  /* cursor: pointer; */
}

.sOuterlg {
  width: 96% !important;
  /* height: 100% !important; */
  margin: 0 10px 0 8px;
  background: #fff;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  box-sizing: border-box;
  /* min-height: 100% !important; */
}

.navArrow {
  background: #ffffff;
  border: 2px solid #d52b1d !important;
  color: #d52b1d !important;
  border-radius: 50% !important;
}

.liveBtn {
  border: 1px solid #d52b1e !important;
  border-radius: 2px !important;
  background: transparent !important;
  color: #d52b1e;
  font-weight: 400;
  font-size: 13px !important;
  line-height: 22px !important;
  letter-spacing: -0.011em !important;
  margin-bottom: 8px !important;
  margin-right: 10px !important;
  cursor: pointer;
  padding-top: 1px;
}

.liveBtn:hover {
  background-color: #d52b1e !important;
  color: white;
  transition: 0.5s;
}

.eTxt {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: -0.015em !important;
  color: #82786e !important;
  margin-top: 10px !important;
  display: inline-block !important;
  padding: 0 !important;
  text-align: right;

}
.LeftAlign{
  text-align: left !important;
}

.cont-desc2{
  height: 10% !important;
}
.margintop2{
  margin-top: 0% !important;
}

.timerSec {
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  text-align: center !important;
  text-transform: uppercase !important;
  color: #82786e !important;
  border: 1px solid #82786e !important;
  height: 24px !important;
  display: inline-grid !important;
  padding: 4px !important;
  border-radius: 4px !important;
  padding-top: 4px !important;
  margin-top: 8px !important;
  min-width: 30px !important;
}

.timerVal {
  font-size: 6px !important;
  margin-top: 0px !important;
  color: #82786e !important;
  padding: 0 2px;
}

.dots {
  margin: 0 2px !important;
}

.BtnSec {
  text-align: right !important;

}

.timerArea {
  padding-right: 10px !important;
  flex-shrink: 0;
}

.textLeft {
  text-align: left !important;
}

.wid-24 {
  width: 24px !important;
}

.sOuterlg .podcastTag,
.sOuterlg .liveTag,
.sOuterlg .videoTag,
.sOuterlg .playlistTag,
.sOuterlg .upName,
.sOuterlg .cont,
.sOuterlg .contDate {
  margin-left: 15px !important;
}

.cont-startdate-home {
  font-size: small !important;
  box-shadow: none !important;
  margin-left: 15px !important;
  text-align: left !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.015em !important;
  color: #A59D95 !important;
  margin: 5px 15px 5px 15px !important;
  box-shadow: none !important;
  font-family: 'DINPro-Light' !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cont-startdate-home.hide-xs {
  display: block;
}

.cont-startdate-home.show-xs {
  display: none;
}

.cont-startdate {
  font-size: small !important;
  box-shadow: none !important;
  margin-left: 15px !important;
  text-align: left !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.015em !important;
  color: #A59D95 !important;
  margin: 5px 0 !important;
  box-shadow: none !important;
  font-family: 'DINPro-Light' !important;
}

.cont-startdate-home:hover, .cont-startdate:hover {
  cursor: text;
  text-decoration: none;
}

.most-recent-slider .slick-slide {
  text-align: center;
  height: inherit;
}

.most-recent-slider .slick-slide > div {
  height: 100%;
}

.most-recent-slider .slick-track {
  float: left !important;
  display: flex;
}

.most-recent-slider .sOuterlg {
  height: 100%;
}

.most-recent-slider .upName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width:1300px) {}

@media (max-width:1024px) {
  .timerSec {
    padding: 4px 0 !important;
  }
}

@media (max-width:960px) {

  .sOuter {
    width: 95% !important;
    margin: 0 !important;
  }

  .eTxt {
    padding-left: 10px !important;
  }

  .most-recent-slider .BtnSec {
    text-align: left !important;
    margin: 10px 10px 2px 10px !important;
  }

  .svgIcon.playlist {
    bottom: 10% !important;
  }

  .simg-lg {
    /* height: 200px !important; */
  }

  .simg {
    /* height: 140px !important; */
  }
}

@media (max-width:600px) {
  .svgIcon {
    /* bottom: -45% !important; */
    width: 30px !important;
  }

  .playmdIcon {
    height: auto !important;
    width: 70% !important;
    bottom: 14% !important;
  }

  .sOuterlg {
    width: 280px !important;
    margin: 0 !important;
    margin-right: 15px !important;
  }

  .svgIcon.playlist {
    bottom: 10% !important;
  }

  .customSvgIcon.playlist {
    top: 57% !important;
  }

}


@media (max-width: 2560px) and (min-width: 1960px) {

  .svgIcon {
    width: 40px !important;
    top: 120px !important;
  }
}


@media (max-width: 1960px) and (min-width: 1400px) {

  .svgIcon {
    width: 34px !important;
    /* top: 100px !important; */
    /* top: 80px !important; */
    /* top: 25% !important;  */
  }

}

@media (max-width: 1200px) and (min-width: 1100px) {
  .svgIcon {
    top: 25% !important;
  }
}

@media (max-width: 400px) and (min-width: 371px) {
  .svgIcon {
    top: 26% !important;
  }
  .samsung-brow-svgIcon{
    top: 20% !important;
  }
}

@media (max-width: 370px) and (min-width: 300px) {
  .svgIcon {
    top: 50% !important;
   }
   .samsung-brow-svgIcon{
    top: 25% !important;
  }
}