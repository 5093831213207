.table {
    min-width: 750;
    margin-top: 10px;
    z-index: 0;
    position: relative;
}
.content-mgmt table tr td:first-child, .content-mgmt table tr th:first-child {
    padding-left: 30px !important;
}
.actnBtn {
    color: #a59d95;
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
}
.actnBtn:hover {
    color: #23527c;
}
.ml4 {
    margin-left: 4px !important;
}
.actnBtnDel {
    color: #a59d95;
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
}
.actnBtnDel:hover {
    color: #d52b1e;
}
#tableSearchBox{
    font-size: 14px !important;
    color: #82786f !important;
    font-weight: 600 !important;
}
.mlr20 {
    margin: 0 20px !important;
}
.mp0 {
    margin: 0 !important;
    padding: 0 !important;
}

/* Switch CSS */
.switch-hd {
    text-align: right;
}
.MuiSwitch-track {
    background-color: #d52b1e !important;
    opacity: 1 !important;
}
.MuiSwitch-colorSecondary.Mui-checked {
    color: #fafafa !important
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #139751 !important
}
.newplaylist {
    margin-right: 10px !important;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 414px) and (orientation:portrait)   {
    .newplaylist {
        margin-bottom: 5px !important;
    }
}
@media (max-width:767px) {
    /* .switch-hd {
        text-align: left;
        margin: 10px 20px;
    } */
}
