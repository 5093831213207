.embedChip:hover {
  cursor: pointer;
  color: #d52b1e !important;
}

.loadingDiv {
  padding: 0px
}

.richeditor {
  height: 70px;
  min-height: 60px !important;
  overflow: auto;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  font-family: 'DINPro' !important;
  font-weight: 400;
  font-size: 1rem;
}

.disabled-editor {
  border: 1px solid #99938D;
  border-radius: 4px;
  padding: 7px 10px;
  height: 125px;
  color: #99938D;
  cursor: not-allowed;
  overflow-x: hidden;
  scroll-behavior: smooth;
  overflow-x: hidden;
  background-color: #E2E5EA;
}

.richeditor.error {
  border: 1px solid #F44336;
}

.richeditor.medium-editor-placeholder:after {
  content: 'Enter a description...' !important;
}

.richeditor:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}

.richeditor p {
  margin: 0px !important;
}

.done_container {
  text-align: center;
  padding: 4%;
}

.done_container div {
  padding: 15px;
}

.done_container .done_icon img {
  width: auto !important;
}

.done_container .done_title {
  font-family: 'DINPro';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #9A9A9A;
}

.done_container .done_btn button {
  font-size: 14px !important;
  text-transform: uppercase !important;
  padding: 10px !important;
  background: #ffffff;
  border-radius: 3px !important;
  cursor: pointer !important;
}

.stepper_steps .MuiPaper-root {
  /* background-color: #f9f9f9 !important; */
}

.content-update-page .grey_row {
  background-color: white !important;
}

.content-update-page .grey_row .inter-page-navgation {
  border-radius: 3px;
  margin: 0px auto;
  display: flex;
  background-color: #F9F9F9;
}

.content-update-page .grey_row .inter-page-navgation button {
  padding: 2px 10px;
  border-radius: 5px;
  border: none;
  display: flex;
  margin: 5px;
  color: #475467;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

.content-update-page .grey_row .inter-page-navgation button.disabled {
  background-color: #cccccc;
}

.content-update-page .grey_row .inter-page-navgation button.disabled:hover {
  cursor: not-allowed;
}

.content-update-page .grey_row .inter-page-navgation button svg {
  padding: 2px;
}

.content-update-page .grey_row .inter-page-navgation button:hover {
  cursor: pointer;
  box-shadow: inset 0px 0px 15px -9px;
}

.content-update-page .grey_row .inter-page-navgation button.red {
  background-color: #D52B1E;
  color: white
}

.content-update-page .grey_row .inter-page-navgation button.red.disabled {
  background-color: #9e6a66;
}

.content-update-page .grey_row .inter-page-navgation button.red.disabled:hover {
  cursor: not-allowed;
}

.content-update-page .grey_row .inter-page-navgation .align-right {
  margin-left: auto;
  display: flex;
}

.white_row {
  background-color: white !important;
}

.shiftedfield .MuiFormLabel-root,
.shiftedfield .MuiFormGroup-root {
  padding: 8px !important;
}

.shiftedfield .MuiFormLabel-root {
  background: #f9f9f9 !important;
  border-bottom: 1px solid #ddd !important;
  padding: 14px 8px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.bold {
  font-weight: 600 !important;
}

.contentTreeview .MuiFormControlLabel-root {
  margin: 0 !important;
}

.contentTreeview .MuiFormControlLabel-root .MuiCheckbox-root {
  padding: 1px !important;
}

.contentTreeview .MuiCollapse-wrapper .MuiTreeItem-iconContainer {
  display: none !important;
}

.textArea {
  font-family: dinpro;
  color: #a59d95;
  font-size: 14px;
  font-weight: normal;
  padding: 3% 6% !important;
  height: 35px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.row_inner_box {
  padding: 30px 0 !important;
  margin: 0 30px !important;
}

.white_row .row_inner_box {
  border-bottom: 1px solid #ddd !important;
}

.ds_title {
  font-size: 22px !important;
  color: #82786F !important;
}

.ds_upload_pregress {
  font-size: 18px !important;
}

.ds_upload_pregress .uploadStatus {
  color: #8DC211 !important;
}

.ds_upload_pregress .uploadPerc {
  color: #8DC211 !important;
  margin-left: 10px;
}

.ds_kalturaid {
  font-size: 14px !important;
  text-align: right !important;
  padding-top: 10px !important;
}

.ds_header {
  border-bottom: 1px solid #EAEAEA !important;
  padding-bottom: 10px !important;
  margin: 0 20px !important;
}

.ds_form .MuiFormGroup-root {
  flex-direction: row !important;
}

.ds_form .MuiFormLabel-root {
  font-family: 'DINPro' !important;
  font-style: normal !important;
  font-size: 16px !important;
  color: #82786F;
}

.ds_form .MuiFormControl-root {
  width: 100% !important;
  margin: 5px 0 !important;
}

.ds_form .inlineFields .field {
  display: inline !important;
}

.ds_form .inlineFields .MuiFormControl-root {
  width: 100% !important;
}

.MuiFormHelperText-contained {
  margin-left: 0 !important;
}

.chipInput .MuiOutlinedInput-adornedStart {
  padding-top: 0 !important;
}

.selectSpeciality {
  width: 80% !important;
}

.chipInput .MuiOutlinedInput-input {
  padding: 0 !important;
}

.chipInput .MuiChip-clickable {
  margin: 0 5px 0 0 !important;
  background-color: #cde69c;
  border-radius: 2px;
  border: 1px solid #a5d24a;
  color: #638421;
}

.field_tbl {
  border-collapse: collapse !important;
}

.field_tbl th,
.field_tbl td {
  padding: 8px !important;
  border-bottom: 1px solid #ddd;
}

.field_tbl thead {
  height: 40px !important;
  background-color: #f9f9f9 !important;
  text-align: left !important;
  font-size: 14px;
  color: #a59d95;
}

.field_tbl .MuiFormControl-root {
  margin: 0 !important;
}

.borderBottom {
  border-bottom: 1px solid #ddd !important;
}

.addIconBtn,
.removeIconBtn,
.editIconBtn {
  color: #1592E6 !important;
  cursor: pointer !important;
  margin: 0 !important;
  padding: 0 !important;
  justify-content: left !important;
}

.addIconBtn .MuiButton-label,
.removeIconBtn .MuiButton-label,
.editIconBtn .MuiButton-label {
  font-size: 16px !important;
  text-transform: none !important;
  justify-content: left !important;
  text-align: left !important;
}

.addIconBtn .MuiButton-label img,
.removeIconBtn .MuiButton-label img,
.editIconBtn .MuiButton-label img {
  margin-right: 10px !important;
}

.country_list {
  list-style: none !important;
  padding: 0 !important;
}

.country_list .hubs {
  padding: 0 10px !important;
}

.country_list .affiliates {
  padding: 0 20px !important;
}

.country_list .country_list_inner {
  width: 24%;
  display: inline-block;
}

.content_alignCenter {
  justify-content: center !important;
  align-items: center !important;
}

.stepper_container {
  margin: 0 auto !important;
  background: #F9F9F9 !important;
  border: 1px solid #FFFFFF !important;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25) !important;
  border-radius: 2px !important;
  margin-top: 1% !important;
  margin-bottom: 10% !important;
  max-width: 90vw;
}

.content_form1 .MuiStepper-root {
  background: #F9F9F9 !important;
}

.content_labelColor .MuiStepLabel-label {
  color: #82786f !important;
  font-family: 'DINPro' !important;
  font-size: 14px !important;
}

.content_labelColor .MuiStepLabel-label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 600;
}

.content_labelColor .MuiStepLabel-label.MuiStepLabel-active {
  color: #d52b1e !important;
  font-weight: 600;
}

.content_labelColor .MuiStepLabel-label.MuiStepLabel-completed {
  color: #d52b1e !important;
  font-weight: 600;
}

.content_dZone {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding: 3% 0;
  background-color: white !important;
  color: #82786F;
  font-size: 18px !important;
}

.content_font16 {
  font-size: 16px;
}

.content_uploadSectionF {
  padding: 5% 9%;
  background-color: white;
}

.content_upload_section {
  padding: 0% 9% 5% 9%;
  background-color: white;
}

.content_uploadSectionU {
  padding-right: 5%;
  padding-left: 9%;
}

.content_uploadSection {
  display: inline-flex !important;
  vertical-align: middle !important;
  width: 100%;
}

.content_disclaimer {
  color: #A59D95;
  font-size: 14px;
  line-height: 20px;
  margin-top: 3%;
  margin-right: 20px;
}

.content_kalturaLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.011em;
  color: #82786F;
  padding: 0.7% 0;
  line-height: 17px;

}

.content_textfield {
  width: 100%;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 41px !important;
  letter-spacing: -0.011em !important;
  color: #9B9B9B !important;
  background: #FFFFFF !important;
  border: 1px solid #E4E4E4 !important;
  border-radius: 3px !important;
}

.content_textfield .MuiFormLabel-root {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #9B9B9B !important;
  line-height: 1px;
}

.content_uploadBtnSec {
  margin-top: 5px !important;
}

.content_uploadBtnSec button {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.23);
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.87);
}

.content_uploadBtnSec button:hover {
  background-color: #d52b1e;
  color: #ffffff !important;
  font-size: 16px !important;
}

.content_uploadBtnSec button .uplaod-button {
  display: flex;
  align-items: center;
  gap: 5px;
}

.content_uploadBtnSec button:hover .uplaod-button svg path {
  stroke: white;
}

.MuiLinearProgress-bar {
  background-color: #d52b1e !important;
}

.content_input {
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 41px;
  letter-spacing: -0.011em;
  color: #9B9B9B;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  border-radius: 3px;
}

.content_inputSec {
  margin: 0 20px !important;
}

.brkWrd {
  word-break: break-word;
  background-color: #DADADA !important;
}

.embedModalFooter {
  text-align: right !important;
  border-top: 1px solid #ccc !important;
  padding: 14px !important;
  position: absolute;
  width: 46%;
  /* Revert for kaltura - width: 30%; */
  height: auto;
  bottom: 0;
}

.posRel {
  position: relative;
}

.posAbs {
  position: absolute;
}

.widthAuto {
  width: auto !important;
}

.codeCopy {
  color: #82786f !important;
  font-weight: bold;
  text-decoration: none !important;
  cursor: pointer !important;
}

.codeCopyActive {
  color: #000 !important;
  font-weight: bold;
  text-decoration: none !important;
}

.embedModal {
  margin: 0;
  line-height: 1.42857143;
  font-size: 1.0rem;
  font-weight: 700;
  color: #82786f;
  border-bottom: 1px solid #ccc;
  padding: 14px !important;
  min-height: 30px;
}

.content_embed {
  background-color: #09CA49;
  margin: 0 auto;
  width: 37%;
  padding: 3%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content_loader {
  height: 26px;
}

.editImage {
  cursor: pointer;
}

.dialogTitle {
  padding: 3% !important;
  text-align: right !important;
}

.dialogContent {
  color: #8a6d3b;
  background-color: #fcf8e3;
  margin: 0 3% !important;
  font-family: Din Pro !important;
  border: 1px solid #faebcc;
  border-radius: 4px;
}

.dialogContent p {
  font-size: 14px !important;
  line-height: 20px !important;
  font-family: 'DINPro' !important;
}

.dialogCloseBtn {
  color: #333 !important;
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  font-size: 16px !important;
  text-transform: none !important;
  font-family: 'DINPro' !important;
  margin: 3% !important;
  padding: 0.8% 2% !important;
}

.dialogActions {
  border-top: 1px solid #e5e5e5;
  margin-top: 7% !important;
  padding: 0 !important;
}

.content_updateStepperCss .MuiStepConnector-alternativeLabel {
  top: 22px !important;
}

.content_updateStepperCss .MuiStepConnector-active .MuiStepConnector-line {
  background-color: #d52b1e !important;
}

.content_updateStepperCss .MuiStepConnector-completed .MuiStepConnector-line {
  background-color: #d52b1e !important;
}

.content_updateStepperCss .MuiStepConnector-line {
  height: 2px !important;
  border: 0 !important;
  background-color: #eaeaf0 !important;
  border-radius: 1px !important;
}


.alignContent {
  display: flex;
  margin-bottom: 3% !important;
  word-wrap: break-word;
}

.alignContent .MuiGrid-root {
  font-family: 'DINPro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.011em;
  color: #82786F;
}

.preview_container {
  width: 332px !important;
  height: auto !important;
  max-height: 149px !important;
  border: 1px solid #E9E9E9;
  position: relative;
}

.preview_content {
  width: 150px !important;
}

.loadingDiv {
  text-align: center;
}

.loadingDiv img {
  vertical-align: middle;
}

.loadingDiv span {
  color: #d52b1e;
  margin-left: 10px;
}

.close_preview {
  position: absolute !important;
  top: 0;
  right: 0;
  background: #E9E9E9 !important;
  height: 38px !important;
  width: 38px !important;
  min-width: 38px !important;
  border-radius: 0 !important;
}

.vvpmTbl {
  border-collapse: collapse !important;
  margin-bottom: 2.5% !important;
  width: 100% !important;
}


.alignCenter .formControl {
  text-align: center !important;
}


.quizTextfield .textfiledBxModal .MuiInputBase-input {
  background-color: #eee !important;
  color: #555 !important;
}

.closeBtn {
  width: 100% !important;
}

.vvpmTbl td {
  margin: 0 auto !important;
  padding: 0 !important;
  font-family: dinpro;
  color: #a59d95;
  font-size: 14px;
  font-weight: normal;
}

.contentLibrary .ul_list {
  margin: 0 !important;
}

.libraryCountrySelected {
  line-height: 18px !important;
}

/* table tr td:first-child,
table tr th:first-child {
  padding-left: 0px !important;
  padding-right: 0px !important;
} */

.vvpmTbl table td {
  color: #383739 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  text-align: center !important;
  padding: 0.7% 0 !important;
}

.vvpmTbl table tr:nth-child(even) {
  background: #EEEDED !important;
}

.cancelApproval {
  width: 17% !important;
  font-size: 15px !important;
}

.vvpmTbl .textfiledBx {

  width: 80% !important;
}

.vvpmTbl table {
  margin-top: 2% !important;
  width: 100% !important;
  border-spacing: 0 !important;
}

.vvpmTbl table thead th {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #82786F;
  background: #F2F0EF !important;
  border-radius: 4px 4px 0px 0px !important;
  padding: 0.4% !important;
}

.vvpmTbl thead {
  background: #F2F0EF;
  border: 2px solid #D3D3D3;
}

.contentPublish .vvpmTbl thead {
  text-align: left !important;
}

.approvedIcon {
  margin-top: 50%;
}

.approvedIconContent {
  margin-top: 50%;
  margin-right: 20%;
  cursor: pointer;
}

.disabledapprovedIconContent {
  margin-top: 50%;
  margin-right: 20%;
  pointer-events: none;
}

.selectedActionToPublish {
  text-align: center !important;
  padding-right: 2% !important;
}

table tr td:last-child,
table tr th:last-child {
  padding-right: 10px !important;
}

#kaltura_video_player_model {

  width: 100%;

  height: 242px;
}

#kaltura_video_player {

  width: 100%;

  height: 242px;

}


.searchVVPMCodeTxt {
  color: #1592E6;
  font-weight: 700;
  cursor: pointer;
}

.vvpmDialogTitle h2 {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #1692E6;
  font-family: 'DINPro' !important;
}

.vvpmDetailsDialogTitle h2 {
  font-weight: 700 !important;
  font-size: 22px !important;
  color: #3F5580;
  font-family: 'DINPro' !important;
  border-bottom: 1px solid #DCD9D6;
  padding-bottom: 1.5% !important;
}

.searchVVPMItem {
  width: 49%;
  margin-left: 2%;
}

.VVPMDetailsGrid table {
  margin-top: 0 !important;
}

.VVPMDetailsGrid {
  width: 90%;
  margin-top: 10px !important;
  /* margin: 30px auto; */
  background-color: #fff;
  position: relative;
  /* -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=10, Direction=0, Color=#A59D95)"; */

  border: 2px solid #D3D3D3;
  border-radius: 4px 11px 11px 4px;
  max-height: 350px !important;
}


.VVPMDetailsGrid thead {
  background: #F2F0EF !important;
}

.searchLabel {
  font-size: 20px !important;
  color: #333333 !important;
  font-weight: 700;
  line-height: 33px;
}

.VVPMDetailsGrid .MuiTableCell-alignLeft {

  font-size: 16px !important;
  text-align: center !important;
}

.VVPMDetailsGrid .MuiTableHead-root {
  border-top: none !important;
  border-radius: 4px 11px 11px 4px;

}

/* .VVPMDetailsGrid table tr td:first-child,
table tr th:first-child {
  padding-left: 2% !important;
} */

.textfiledBxModal {
  width: 95% !important;
  margin: 0 !important;

}

.disabledText input {
  background-color: #eee !important;
  padding: 8px 4px !important;
  color: #555 !important;
  font-size: 15px !important;
  cursor: not-allowed;
}

.selectVVPMdetails input {
  font-size: 14px !important;
}

.selectQuizdetails input {
  font-size: 14px !important;
}

.selectVVPMdetails .MuiFormControl-fullWidth {
  width: 95% !important;
}

.textfiledBxModal input {
  font-size: 14px !important;
}

.vvpmDialogContent {
  margin-bottom: 3% !important;
}

.margin5 {
  margin-left: 5% !important;
}


.searchVVPMCodeDisabled {
  background-color: #919191 !important;
  border-radius: 5px;
  width: 95% !important;
  color: #FFFFFF !important;
  margin-top: 10% !important;
  margin-left: 5% !important;
  text-transform: none !important;
}

.selectedText {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #000000 !important;
}

.taxonomyDataDialogContent .formControl .MuiFormLabel-root {
  font-weight: 700 !important;
}

.brandDialogTitle {
  border-bottom: 1px solid #ccc;
}

.brandDialogTitle h2 {
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #1692E6;
  font-family: 'DINPro' !important;
}

.brandModal .modalBtn {
  width: 50% !important;
  margin: 0;
}

.searchVVPMCode {
  background-color: #D52B1E !important;
  border-radius: 5px;
  width: 95% !important;
  color: #FFFFFF !important;
  margin-left: 5% !important;
  margin-top: 10% !important;
  border: none !important;
  text-transform: none !important;
}

.justifyCenter {
  justify-content: center !important;
}

.selectVVPMCode {
  background-color: #D52B1E !important;
  border-radius: 5px;
  color: #FFFFFF !important;
  border: none !important;
  text-transform: none !important;
}

.deleteVVPMDetail {
  width: 16% !important;
}

.productChar .MuiInput-underline.Mui-error:after {
  border-bottom: 1px solid #ccc !important;
}

.canclBtn {
  background-color: #F2F0EF !important;
  text-transform: none !important;
}

.normalFont {
  font-weight: normal !important;
}

.ds_form .reducePadding {
  margin: 0 !important;
}

.tableHeight {
  /* max-height: 290px;
  min-height: 289px; */
  height: 290px;
}

.searchInputField {
  margin-left: 5px !important;
}

.vvpmId-list {
  max-height: 150px;
  overflow-y: auto;
}

.vvpm-fields-margin {
  margin-bottom: 13px !important;
}

@media (max-width:500px) {
  .content_uploadSection {
    display: block !important;
  }

  .content_kalturaLabel {
    text-align: center !important;
    padding: 0 !important;
  }

  .content_inputSec {
    margin: 4% 0 !important;
  }

  .content_uploadBtn {
    margin: 0 auto !important;
    display: block !important;
    width: 100%;
  }

  .selectedAction {
    text-align: center !important;
  }

  .approvedIcon {
    margin-top: 8% !important;
    margin-bottom: 0 !important;
  }

  .alignCenter .MuiButtonBase-root {
    justify-content: left !important;
  }


  .alignCenter .formControl {
    text-align: left !important;
  }


  .quizSection .ml10 {
    margin-left: 0 !important;
  }

  .vvpmTbl .textfiledBx {
    width: 90% !important;
  }
}


@media only screen and (max-width:767px) {
  .ds_form .inlineFields .MuiFormControl-root {
    width: 100% !important;
  }

  .ds_form .inlineFields .field {
    margin: 0 !important;
  }

  .country_list .country_list_inner {
    width: 50%;
  }
}

.multilingual-margin-top {
  margin-top: 5px;
}

.tag_title {
  color: var(--Dark-Gray-3, #8B847E);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}



/*Adjustmanets for content update 2881*/
.content-update-step-image .content_uploadIcon {
  border-radius: 5px;
  margin: auto;
  max-width: 22px;
  padding: 8px;
  background-color: #C0C0C0;
}

.content-update-step-image .content_uploadIcon.active {
  background-color: #D52B1E;
}

.upload_icon {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  margin-bottom: 20px;
  border: 3px solid var(--Red-1, #D52B1E);
}

.upload-text {
  font-weight: 600;
}

.content_horizon {
  border: 1px dashed #C0C0C0;
}

/* CSS for new Preview and Type */
.content-update-page .preview-section {
  position: relative;
}

.content-update-page .preview-section.spacingissue {
  margin: unset;
  width: unset;
}

.content-update-page .preview-section .thumbnail-videodetails {
  background-color: #F9F9F9;
  border-radius: 5px;
  margin: 0rem 0rem 0rem 2.5rem;
}

.content-update-page .preview-section .thumbnail-videodetails .videos-header {
  display: grid;
  grid-template-columns: 0.5fr 0.2fr 0.3fr;
  border-bottom: 1px solid #F4F4F3;
  padding: 10px 5px;
}

.content-update-page .preview-section .thumbnail-videodetails .videos-header .video-progress {
  height: 10px;
  position: relative;
  background: #EAECF0;
  border-radius: 25px;
  margin: 5px;
}

.content-update-page .preview-section .thumbnail-videodetails .videos-header .video-info {
  color: #99938D;
  font-size: 14px;
  text-align: end;
}

.content-update-page .preview-section .thumbnail-videodetails .videos-header .video-progress>span {
  display: block;
  height: 100%;
  border-radius: 25px;
  background-color: #D52B1E;
}

.content-update-page .preview-section .thumbnail-videodetails .upload-section {
  display: flex;
  padding: 15px 5px;
}

.content-update-page .preview-section .thumbnail-videodetails .upload-section .upload-thumbnail {
  position: relative;
  display: flex;
  margin: 0px 0px 0px 20px;
}

.content-update-page .preview-section .thumbnail-videodetails .upload-section .upload-thumbnail .upload-thubmail-file {
  align-self: center;
  vertical-align: middle;
}

.content-update-page .preview-section .thumbnail-videodetails .upload-section .upload-thumbnail .upload-thubmail-file .file-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  font-size: 14px;
  color: #99938D;
  margin-top: 1rem;
}


.content-update-page .preview-section .thumbnail-videodetails .upload-section .upload-thumbnail .upload-thubmail-file .file-details span:hover {
  cursor: default;
}

/* .content-update-page .preview-section .thumbnail-videodetails .upload-section .upload-thumbnail .upload-thubmail-file:hover {
  cursor: pointer;
} */

/* .content-update-page .preview-section .thumbnail-videodetails .upload-section .upload-thumbnail .upload-thubmail-file:hover {
  cursor: pointer;
} */

/* .content-update-page .preview-section .thumbnail-videodetails .upload-section .upload-thumbnail.disabled .upload-thubmail-file:hover {
  cursor: not-allowed;
} */

.content-update-page .preview-section .thumbnail-videodetails .upload-section .upload-thumbnail .upload-thubmail-file input {
  display: none;
}

.content-update-page .preview-section .thumbnail-videodetails .upload-section .upload-thumbnail .upload-thubmail-file input:hover {
  cursor: pointer;
}


.content-update-page .preview-section .thumbnail-videodetails .upload-section .upload-thumbnail.disabled .upload-thubmail-file input:hover,
.content-update-page .preview-section .thumbnail-videodetails .upload-section .upload-thumbnail.disabled .upload-thubmail-file label:hover {
  cursor: not-allowed;
}

.content-update-page .preview-section .thumbnail-videodetails .upload-section .upload-thumbnail .upload-thubmail-file label:hover {
  cursor: pointer;
}

.content-update-page .preview-section .thumbnail-videodetails .upload-section .upload-thumbnail .upload-thubmail-file label .text {
  color: #B42318;
  vertical-align: middle;
  font-weight: 600;
}

.content-update-page .preview-section .thumbnail-videodetails .upload-section .upload-thumbnail.disabled .upload-thubmail-file label .text {
  color: #99938D;
}

.content-update-page .preview-section .thumbnail-videodetails .upload-section .upload-thumbnail.disabled .upload-thubmail-file label .uploadimage {
  stroke: #99938D;
}

.content-update-page .preview-section .thumbnail-videodetails .upload-section .upload-thumbnail .upload-thubmail-file label .uploadimage {
  stroke: #B42318;
  vertical-align: middle;
  padding: 5px;
}

.content-update-page .preview-section .thumbnail-videodetails .upload-section .upload-thumbnail .upload-thubmail-file label .infoicon {
  height: 1rem;
  fill: #99938D;
}


.content-update-page .preview-section .thumbnail-videodetails .upload-section {
  margin-left: 10px;
}

.content-update-page .preview-section .thumbnail-videodetails .upload-section .kaltura_video {
  max-width: 150px;
}

.content-update-page .preview-section .thumbnail-videodetails .upload-section #kaltura_video_player {
  max-height: 110px;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
}

.content-update-page .preview-section .thumbnail-videodetails .upload-section #kaltura_video_player .loadingDiv {
  padding: 0px;
}

.content-update-page .preview-section .infosection {
  display: grid;
  height: 100%;
  grid-template-rows: 0.2fr 1fr;
  padding: 0rem 2.5rem 0rem 0rem;
}

.content-update-page .preview-section .infosection .updateandlinks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* margin: 10px 5px 0px 5px; */
  gap: 10px;
}

.content-update-page .preview-section .infosection .updateandlinks div {
  text-align: center;
}

.content-update-page .preview-section .infosection .updateandlinks .updatevideo {
  border: 1px solid #E2E5EA;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
}

.content-update-page .preview-section .infosection .updateandlinks .updatevideo.disabled,
.content-update-page .preview-section .infosection .updateandlinks .updatevideo:hover.disabled {
  cursor: none;
  color: #99938D;
  background-color: #E2E5EA;
}

.content-update-page .preview-section .infosection .updateandlinks .updatevideo.disabled svg {
  stroke: #99938D;
}

.content-update-page .preview-section .infosection .updateandlinks .updatevideo svg {
  margin-right: 4px;
}

.content-update-page .preview-section .infosection .updateandlinks .embedvideo {
  padding: 5px;
  position: relative;
  border-radius: 5px;
}

.content-update-page .preview-section .infosection .updateandlinks .embedvideo.disabled,
.content-update-page .preview-section .infosection .updateandlinks .embedvideo:hover.disabled {
  cursor: none;
  color: #99938D;
  background-color: #E2E5EA;
}

.content-update-page .preview-section .infosection .updateandlinks .embedvideo.disabled span,
.content-update-page .preview-section .infosection .updateandlinks .embedvideo.disabled .buttonsection svg {
  stroke: #99938D;
  color: #99938D;
  font-weight: normal;
}

.content-update-page .preview-section .infosection .updateandlinks .updatevideo:hover,
.content-update-page .preview-section .infosection .updateandlinks .embedvideo:hover {
  background-color: #F9FAFB;
  border-radius: 5px;
  cursor: pointer;
}

.content-update-page .preview-section .infosection .updateandlinks .updatevideo span,
.content-update-page .preview-section .infosection .updateandlinks .embedvideo span {
  vertical-align: middle;
  padding-left: 8px;
  color: #344054;
  font-weight: 600;
  font-size: 14px;
}

.content-update-page .preview-section .infosection .updateandlinks .updatevideo svg,
.content-update-page .preview-section .infosection .updateandlinks .embedvideo .buttonsection svg {
  stroke: #344054;
  vertical-align: middle;
  height: 20px;
  width: 20px;
}

.content-update-page .preview-section .infosection .updateandlinks .embedvideo .dropdownsection {
  position: absolute;
  padding: 0;
  margin: 10px 0px;
  box-shadow: #C3C3C3 0px 0px 2px 1px;
  border-radius: 5px;
  width: 100%;
  background-color: #ffffff;
}

.content-update-page .preview-section .infosection .updateandlinks .embedvideo .dropdownsection li {
  color: #344054;
  font-size: 14px;
  padding: 5px;
  display: flex;
  vertical-align: middle;
  justify-content: center;
}

.content-update-page .preview-section .infosection .updateandlinks .embedvideo .dropdownsection li:hover {
  cursor: pointer;
  background-color: #F9FAFB;
}

.content-update-page .preview-section .infosection .updateandlinks .embedvideo .dropdownsection li svg {
  stroke: #344054;
  vertical-align: bottom;
  height: 20px;
  width: 20px;
}

.content-update-page .preview-section .infosection .moreinfo {
  /* margin: 10px 5px; */
  display: grid;
  align-content: space-around;
}

.content-update-page .preview-section .infosection .moreinfo div {
  background-color: #F8F8F8;
  padding: 10px;
  border-radius: 10px;
  color: #344054;
  font-weight: 600;
  font-size: 0.875rem;
}

.content-update-page .preview-section .infosection .moreinfo div:hover {
  cursor: default;
}

.content-update-page .content-input {
  border-top: 1px solid #ddd;
  background: white;
  padding: 0rem 2.5rem;
}

.content-update-page .content-input .content-field {
  padding: 12px 6px;
}

.content-update-page .content-input .content-field .helper-text {
  color: #8B847E;
  font-size: 14px;
  justify-content: space-between;
  flex-direction: row-reverse;
  display: flex;
}

.content-update-page .content-input .content-field .helper-text .error {
  color: #E02F2B;
}

.content-update-page .content-input .content-field .input-label {
  color: #344054;
  font-weight: 600;
  padding: 0px 0px 4px;
  line-height: 1.5;
  display: block;
}

.content-update-page .content-input .content-field.radio .input-label {
  padding: 0;
}

.content-update-page .content-input .content-field .mandatory::after {
  color: #E02F2B;
  content: '*';
  padding: 5px;
  vertical-align: middle;
}

.content-update-page .content-input .content-field.radio fieldset {
  margin: 0;
}

.content-update-page .content-input .content-field .radio-input .MuiRadio-colorSecondary.Mui-checked {
  color: #E02F2B !important;
}

.content-update-page .content-input .content-field .radio-input .radio-options {
  max-height: 30px;
}

.content-update-page .content-input .content-field .radio-input .radio-options.Mui-disabled:hover {
  cursor: not-allowed;

}

.content-update-page .content-input .content-field .radio-input .radio-options.Mui-disabled .MuiRadio-colorSecondary.Mui-disabled .MuiIconButton-label {
  background-color: #E2E5EA;
  color: #99938D;
  cursor: not-allowed;
  border-radius: 50%;
}

.content-update-page .content-input .content-field .radio-input .radio-options .MuiTypography-body1 {
  color: #344054 !important;
  font-weight: 600;
}

.content-update-page .content-input .content-field .chipset {
  margin: 0px !important;
}

.content-update-page .content-input .content-field .chipset .MuiOutlinedInput-root {
  border: 1px solid #d9d9d9 !important;
  background-color: #fff;
  border-radius: 4px;
  padding: 4px 4px 6px 4px;
  display: flex;
  margin: 1px 0px 4px 0px;
  flex-wrap: wrap;
  position: relative;
}

.content-update-page .content-input .content-field .chipset .MuiOutlinedInput-root.Mui-disabled {
  background-color: #E2E5EA;
  color: #99938D;
}

.content-update-page .content-input .content-field .chipset .MuiOutlinedInput-root.Mui-disabled:hover {
  cursor: not-allowed;
}

.content-update-page .content-input .content-field .chipset .MuiOutlinedInput-root .MuiChip-root {
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(232, 232, 232);
  border-radius: 2px;
  box-sizing: content-box;
  outline: 0px;
  overflow: hidden;
  width: min-content;
}

.content-update-page .content-input .content-field .chipset .MuiOutlinedInput-root .MuiChip-root span {
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  text-overflow: ellipsis;
  font-weight: 600;
  color: rgb(130, 120, 111);
}

.content-update-page .content-input .content-field .chipset input {
  padding: 4px 10px;
  margin: 0;
}

.content-update-page .content-input .content-field .chipset div:first-child {
  min-height: unset !important;
}

.content-update-page .content-input .content-field .chipset fieldset.MuiOutlinedInput-notchedOutline {
  border: none !important;
  padding: 0;
}

.content-update-page .content-input .content-field .filter-dropdown-popout .some-filter .filterLabel {
  color: #344054;
  font-weight: 600;
  font-size: 1rem;
}

.content-update-page .content-input .content-field .filter-dropdown-popout .some-filter .mandatory::after {
  color: #E02F2B;
  content: '*';
  padding: 5px;
  vertical-align: middle;
}

.content-update-page .content-input .content-field .filter-dropdown-popout .some-filter .filterInputWrapping {
  width: 100%;
}

.content-update-page .content-input .content-field .filter-dropdown-popout .some-filter .filterInputWrapping.disabled {
  background-color: #E2E5EA;
  color: #99938D;
}

.content-update-page .content-input .content-field .filter-dropdown-popout .some-filter .filterInputWrapping.disabled:hover,
.content-update-page .content-input .content-field .filter-dropdown-popout .some-filter .filterInputWrapping.disabled svg:hover {
  cursor: not-allowed;
}

.content-update-page .content-input .content-field .filter-dropdown-popout .filterLabel {
  color: #344054;
  font-weight: 600;
  font-size: 1rem;
}

.content-update-page .content-input .content-field .filter-dropdown-popout .mandatory::after {
  color: #E02F2B;
  content: '*';
  padding: 5px;
  vertical-align: middle;
}

.content-update-page .content-input .content-field .filter-dropdown-popout .filterInputWrapping {
  width: 100%;
}

.content-update-page .content-input .content-field .filter-dropdown-popout .filterInputWrapping.disabled {
  background-color: #E2E5EA;
  color: #99938D;
}

.content-update-page .content-input .content-field .filter-dropdown-popout .filterInputWrapping.disabled:hover,
.content-update-page .content-input .content-field .filter-dropdown-popout .filterInputWrapping.disabled:hover input {
  cursor: not-allowed;
}

.content-update-page .content-input .content-field .text-input {
  margin: 0 !important;
}

.content-update-page .content-input .content-field .text-input .MuiOutlinedInput-root input {
  padding: 7px 10px;
}

.content-update-page .content-input .content-field .text-input fieldset {
  padding: 0;
}

.content-update-page .content-accordian {
  border-top: 1px solid #ddd;
  background: white;
  padding: 0rem 2.5rem;
}

.content-update-page .content-accordian .content-field {
  padding: 12px 6px;
}

.content-update-page .content-accordian .content-field.header_taxanomy {
  padding: 12px 6px 0px 6px;
}

.content-update-page .content-accordian .content-field .input-label {
  color: #344054;
  font-weight: 600;
  padding: 0px 0px 4px;
  line-height: 1.5;
  display: block;
}

.content-update-page .content-accordian .content-field .input-label.mandatory::after,
.content-update-page .content-accordian .content-field .mandatory::after {
  color: #E02F2B;
  content: '*';
  padding: 5px;
  vertical-align: middle;
}

.content-update-page .content-accordian .content-field .filter-dropdown-popout .some-filter .filterLabel {
  color: #344054;
  font-weight: 600;
  font-size: 1rem;
}

.content-update-page .content-accordian .content-field .filter-dropdown-popout .some-filter .mandatory::after {
  color: #E02F2B;
  content: '*';
  padding: 5px;
  vertical-align: middle;
}

.content-update-page .content-accordian .content-field .filter-dropdown-popout .some-filter .filterInputWrapping {
  width: 100%;
}

.content-update-page .content-accordian .content-field .filter-dropdown-popout .some-filter .filterInputWrapping.disabled {
  background-color: #E2E5EA;
  color: #99938D;
}

.content-update-page .content-accordian .content-field .filter-dropdown-popout .some-filter .filterInputWrapping.disabled:hover,
.content-update-page .content-accordian .content-field .filter-dropdown-popout .some-filter .filterInputWrapping.disabled .filterTag svg:hover,
.content-update-page .content-accordian .content-field .filter-dropdown-popout .some-filter .filterInputWrapping.disabled input:hover {
  cursor: not-allowed;
}

.content-update-page .content-accordian .content-field .filter-dropdown-popout .filterLabel {
  color: #344054;
  font-weight: 600;
  font-size: 1rem;
}

.content-update-page .content-accordian .content-field .filter-dropdown-popout .mandatory::after {
  color: #E02F2B;
  content: '*';
  padding: 5px;
  vertical-align: middle;
}

.content-update-page .content-accordian .content-field .filter-dropdown-popout .filterInputWrapping {
  width: 100%;
}

.content-update-page .content-accordian .content-field .helper-text {
  color: #8B847E;
  font-size: 14px;
}

.content-update-page .content-accordian .accordian-content .MuiFormControlLabel-root .MuiTypography-root {
  color: #344054 !important;
  font-size: 0.875rem;
}


.content-update-page .vvpm-section {
  border-top: 1px solid #ddd;
  background: white;
  padding: 0rem 2.5rem;
}

.content-update-page .vvpm-section .content-field .input-label {
  color: #344054;
  font-weight: 600;
  padding: 0px 0px 4px;
  line-height: 1.5;
  display: block;
}

.content-update-page .vvpm-section .content-field .helper-text {
  color: #8B847E;
  font-size: 14px;
}

.content-update-page .vvpm-section .vvpm-box {
  border-radius: 0.75rem;
  border: 1px solid #EAECF0;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-bottom: 20px;
}

.content-update-page .vvpm-section .vvpm-box .vvpm-box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem 1rem 1rem;
}

.content-update-page .vvpm-section .vvpm-box .vvpm-box-header label {
  color: #101828;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}

.content-update-page .vvpm-section .vvpm-box .vvpm-box-header .vvpm-date-input {
  display: inline-flex;
  align-items: center;
}

.content-update-page .vvpm-section .vvpm-box .vvpm-box-header .vvpm-date-input input {
  padding: 10px 4px;
  border: 1px solid #A4A2A2;
  border-radius: 7px;
  margin: 0px 10px;
  color: #A4A2A2;
}


.content-update-page .vvpm-section .vvpm-box .vvpm-box-content {
  border-top: 1px solid #EAECF0;
}

.content-update-page .vvpm-section .vvpm-box .vvpm-box-content .selected-content .table-header {
  background-color: #F9FAFB;
  color: #475467;
  font-size: 0.85rem;
  padding: 0.75rem 1.5rem 0.75rem 1rem;
  font-weight: 600;
  line-height: 1.125rem;
  text-align: center;
}

.content-update-page .vvpm-section .vvpm-box .vvpm-box-content .selected-content .table-body {
  color: #475467;
  text-align: center;
  padding: 0.75rem 1.5rem 0.75rem 1rem;
}

.content-update-page .vvpm-section .vvpm-box .vvpm-box-content .selected-content .table-header,
.content-update-page .vvpm-section .vvpm-box .vvpm-box-content .selected-content .table-body {
  display: grid;
  font-size: 12px;
  grid-template-columns: 1fr 4fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.2fr;
}

.content-update-page .vvpm-section .vvpm-box .vvpm-box-content .selected-content .table-body div svg:hover {
  cursor: pointer;
}

.content-update-page .vvpm-section .vvpm-box .vvpm-box-content .search-code {
  color: #475467;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.75rem 1.5rem 1rem 1.5rem;
}

.content-update-page .vvpm-section .vvpm-box .vvpm-box-content .search-code svg path {
  stroke: #344054
}

.content-update-page .vvpm-section .vvpm-box .vvpm-box-content .search-code svg {
  width: 1.25rem;
  height: 1.25rem;
}

.content-update-page .vvpm-section .vvpm-box .vvpm-box-content .search-code:hover {
  cursor: pointer;
  width: fit-content;
}



/* .content-update-page .search-vvpm-dialog .custom-tooltip .tooltip-body {
  display: block;
}


.content-update-page .search-vvpm-dialog .custom-tooltip .tooltip-body>div {
  display: grid;
  grid-template-columns: 0.2fr 0.8fr;
  margin: 0px;
  border: 1px solid #EAECF0;
}

.content-update-page .search-vvpm-dialog .custom-tooltip .tooltip-headers {
  grid-template-columns: 0.2fr 0.8fr;
  font-size: 0.75rem;
} */


.search-vvpm-dialog .modal-heading {
  display: grid;
  grid-template-columns: 0.1fr 0.8fr 0.1fr;
  justify-content: space-between;
  margin: 10px 10px 5px 10px;
}

.search-vvpm-dialog .model-body-container {
  border-top: 1px solid #EDECEA;
  border-bottom: 1px solid #EDECEA;
}

.search-vvpm-dialog .modal-heading .info-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-vvpm-dialog .modal-heading .modal-heading-text {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
}

.search-vvpm-dialog .modal-heading .modal-heading-text .modal-heading-subtext {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.search-vvpm-dialog .modal-heading .modal-heading-text .modal-heading-subtext-bold {
  color: #475467;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.search-vvpm-dialog .model-body-container .search-vvpm-dialog .info-modal {
  align-self: center;
  justify-self: center;
  border: 1px solid #EAECF0;
  border-radius: 5px;
  padding: 4px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.search-vvpm-dialog .close-modal {
  justify-self: flex-end;
}

.search-vvpm-dialog .modal-body {
  border-left: 0px;
  border-right: 0px;
}

.search-vvpm-dialog .modal-body .content-input .content-field .line {
  content: '';
  height: 100%;
  background: linear-gradient(#A4A2A2, #A4A2A2) no-repeat center / 2px 100%;
}

.search-vvpm-dialog .modal-body .content-input .content-field {
  padding: 12px 6px;
}

.search-vvpm-dialog .modal-body .content-input .content-field fieldset.MuiFormControl-root {
  width: 100%;
}

.search-vvpm-dialog .modal-body .content-input .content-field .helper-text {
  color: #8B847E;
  font-size: 14px;
}

.search-vvpm-dialog .modal-body .content-input .content-field .input-label {
  color: #344054;
  font-weight: 600;
  padding: 0px 0px 4px;
  line-height: 1.5;
  display: block;
}

.search-vvpm-dialog .modal-body .content-input .content-field fieldset .text-input .MuiOutlinedInput-inputMarginDense {
  padding: 4px 6px;
  height: 30px;
}

.search-vvpm-dialog .modal-body .content-input .content-field fieldset .filter-dropdown-popout .filterInputWrapping {
  width: 100%;
}

.search-vvpm-dialog .modal-actions button {
  color: white;
  background-color: #D92D20;
  border: 1px solid #D92D20;
  padding: 0.625rem 1.125rem;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin: 0px 5px;
}

.search-vvpm-dialog .modal-actions button:hover {
  cursor: pointer;
}

.search-vvpm-dialog .modal-actions button.modal-cancel {
  border: 1px solid #e0e0ff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #0000;
  color: #344054
}

.search-vvpm-secondary-dialog .modal-heading {
  display: grid;
  grid-template-columns: 50px 0.8fr 0.2fr;
  justify-content: space-between;
  margin: 1rem 1rem 0.5rem;
}

.search-vvpm-secondary-dialog .modal-heading .modal-heading-text {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
}

.search-vvpm-secondary-dialog .modal-heading .modal-heading-text .modal-heading-subtext {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.search-vvpm-secondary-dialog .modal-heading .modal-heading-text .modal-heading-subtext-bold {
  color: #475467;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.search-vvpm-secondary-dialog .info-modal {
  align-self: center;
  justify-self: center;
  border: 1px solid #EAECF0;
  border-radius: 5px;
  padding: 4px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.search-vvpm-secondary-dialog .close-modal {
  justify-self: flex-end;
}

.search-vvpm-secondary-dialog .MuiDialogContent-root {
  padding: 0px 0px;
}

.search-vvpm-secondary-dialog .modal-body {
  border-top: 1px solid #EAECF0;
  border-bottom: 1px solid #EAECF0;
}

.search-vvpm-secondary-dialog .modal-body .content-input {
  display: grid;
  grid-template-columns: 0.2fr 0.8fr;
}

.search-vvpm-secondary-dialog .modal-body .content-input .vvpm-search-section {
  color: #344054;
  min-width: 150px;
  border-right: 1px solid #EDECEA;
  display: flex;
  flex-flow: column;
  /* margin: 0.625rem; */
}

.search-vvpm-secondary-dialog .modal-body .content-input .vvpm-search-section .search-selection {
  border-bottom: 1px solid #EDECEA;
  padding: 10px 15px;
}

.search-vvpm-secondary-dialog .modal-body .content-input .vvpm-search-section .search-selection.select-docs {
  border-bottom: none;
}

.search-vvpm-secondary-dialog .modal-body .content-input .vvpm-search-section .new-search {
  padding: 5px;
  margin-top: auto;
}

.search-vvpm-secondary-dialog .modal-body .content-input .vvpm-search-section .new-search button {
  border: 1px solid #e0e0ff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #0000;
  color: #344054;
  padding: 0.625rem 1.125rem;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
}

.search-vvpm-secondary-dialog .modal-body .content-input .vvpm-search-section .search-selection .helper-text {
  color: #A4A2A2;
  font-size: 12px;
  font-weight: 300;
}

.search-vvpm-secondary-dialog .modal-body .content-input .vvpm-search-section .search-selection label {
  color: #344054;
  font-size: 0.875rem;
  font-style: normal;
  line-height: normal;
}

.search-vvpm-secondary-dialog .modal-body .content-input .vvpm-search-section .search-selection div {
  color: #333;
  font-size: 0.875rem;
  font-style: normal;
  line-height: normal;
}

.search-vvpm-secondary-dialog .modal-body .content-input .vvpm-search-section .search-selection div fieldset .selected-document .MuiTypography-body1 {
  color: #344054 !important;
  font-size: 0.875rem;
  font-style: normal;
  line-height: normal;
}

.search-vvpm-secondary-dialog .modal-body .content-input .table-section {
  padding: 0px 0.625rem;
}

.search-vvpm-secondary-dialog .modal-body .content-input .table-section .MuiTableContainer-root {
  border-radius: 0.75rem;
  border: 1px solid #EAECF0;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
}

.search-vvpm-secondary-dialog .modal-body .content-input .table-section .MuiTableContainer-root .table-head {
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem;
}

.search-vvpm-secondary-dialog .modal-body .content-input .table-section .MuiTableContainer-root .table-head .table-row {
  display: grid;
  grid-template-columns: 50px 120px 250px repeat(7, min(130px));
  background-color: #F9FAFB;
  font-weight: 600;
  /* padding: 0.75rem 1.5rem 0.75rem 1rem; */
}

.search-vvpm-secondary-dialog .modal-body .content-input .table-section .MuiTableContainer-root .table-head .table-row .table-cell {
  /* padding-left: 0px !important; */
}

.search-vvpm-secondary-dialog .modal-body .content-input .table-section .MuiTableContainer-root .table-head .table-row .table-cell span {
  color: #475467 !important;
  width: min-content;
  font-size: 0.75rem;
}

.search-vvpm-secondary-dialog .modal-body .content-input .table-section .MuiTableContainer-root .table-head .table-row .table-cell svg path {
  fill: #475467;
}

.search-vvpm-secondary-dialog .modal-body .content-input .table-section .MuiTableContainer-root .table-body .table-row {
  display: grid;
  grid-template-columns: 50px 120px 250px repeat(7, min(130px));
  border-bottom: 1px solid #EAECF0;
  padding: 0.2rem 0rem;
  word-break: break-all;
}

.search-vvpm-secondary-dialog .modal-body .content-input .table-section .MuiTableContainer-root .table-body .table-row.loader {
  display: grid;
  grid-template-columns: 1fr;
}

.search-vvpm-secondary-dialog .modal-body .content-input .table-section .MuiTableContainer-root .table-body .table-row.loader div {
  padding: 12px;
  border-bottom: 1px solid #EDECEA;
}

.search-vvpm-secondary-dialog .modal-body .content-input .table-section .MuiTableContainer-root .table-body .table-row .table-cell {
  font-style: normal;
  /* font-weight: 500; */
  /* line-height: 1.25rem; */
  /* display: flex; */
  font-size: 12px;
  color: #101828;
  /* padding-left: 0px !important; */
  align-self: center;
  text-overflow: ellipsis;
}

.search-vvpm-secondary-dialog .modal-body .content-input .table-section .MuiTableContainer-root .table-body .table-row .table-cell .labelTxt-user {
  margin: 2px 2px;
}

.search-vvpm-secondary-dialog .modal-body .content-input .table-section .MuiTableContainer-root .table-body .table-row .table-cell .labelTxt-user .MuiRadio-colorSecondary.Mui-checked {
  color: #D92D20 !important;
}

.search-vvpm-secondary-dialog .modal-actions {
  display: flex;
  width: 100%;
  /* padding: 14px; */
}

.search-vvpm-secondary-dialog .modal-actions .modal-search {
  display: flex;
}

.search-vvpm-secondary-dialog .modal-actions .modal-search .pagination {
  color: #303030;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  margin: 0px 12px;
}

.search-vvpm-secondary-dialog .modal-actions .modal-search .pagination div {
  padding: 2px
}

.search-vvpm-secondary-dialog .modal-actions .modal-search .pagination div.navigation-btn {
  color: #303030;
}

.search-vvpm-secondary-dialog .modal-actions .modal-search .pagination div.navigation-btn.disabled {
  color: #8B847E;
}

.search-vvpm-secondary-dialog .modal-actions .modal-search .pagination div.navigation-btn svg {
  padding-top: 5px;
  width: 15px;
}

.search-vvpm-secondary-dialog .modal-actions .modal-buttons {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.search-vvpm-secondary-dialog .modal-actions .modal-buttons button {
  color: white;
  background-color: #D92D20;
  border: 1px solid #D92D20;
  padding: 0.625rem 1.125rem;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin: 0px 5px;
}

.search-vvpm-secondary-dialog .modal-actions .modal-buttons button:hover {
  cursor: pointer;
}

.search-vvpm-secondary-dialog .modal-actions .modal-buttons button.modal-cancel {
  border: 1px solid #e0e0ff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #0000;
  color: #344054
}

.search-vvpm-secondary-dialog .modal-actions .modal-download.disabled {
  color: white;
  background-color: #FECDCA;
  border: 1px solid #FECDCA;
  padding: 0.625rem 1.125rem;
}

.content-update-page .additional-content-section {
  border-top: 1px solid #ddd;
  background: white;
  padding: 0rem 2.5rem;
}

.content-update-page .additional-content-section .content-field .input-label {
  color: #344054;
  font-weight: 600;
  padding: 0px 0px 4px;
  line-height: 1.5;
  display: block;
}

.content-update-page .additional-content-section .content-field {
  padding: 12px 6px;
}

.content-update-page .additional-content-section .content-field .product-characterstics-section,
.content-update-page .additional-content-section .content-field .add-resouces-section,
.content-update-page .additional-content-section .subtitles-section {
  border-radius: 0.75rem;
  border: 1px solid #EAECF0;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.content-update-page .additional-content-section .content-field .product-characterstics-section .table-head,
.content-update-page .additional-content-section .content-field .product-characterstics-section .table-body .table-row {
  display: grid;
  grid-template-columns: 1fr 1fr 0.3fr 0.3fr;
}

.content-update-page .additional-content-section .content-field .product-characterstics-section .table-head {
  background-color: #EAECF0;
  color: #475467;
  font-size: 0.75rem;
  line-height: 1.125rem;
  border-radius: 0.75rem 0.75rem 0rem 0rem;
}

.content-update-page .additional-content-section .content-field .product-characterstics-section .table-head div {
  padding: 0.75rem 1.5rem 0.75rem 1rem;
}

.content-update-page .additional-content-section .content-field .product-characterstics-section .table-body .table-row div {
  padding: 0.75rem 1.5rem 0.75rem 1rem;
  font-size: 0.875rem;
  word-break: break-word;
}

.content-update-page .additional-content-section .content-field .product-characterstics-section .table-body .table-row div svg:hover {
  cursor: pointer;
}

.content-update-page .additional-content-section .content-field .product-characterstics-section .table-footer {
  padding: 0.75rem 1.5rem 1rem 1.5rem;
  border-top: 1px solid #EAECF0;
}

.content-update-page .additional-content-section .content-field .product-characterstics-section .table-footer .more-lines {
  align-items: center;
  width: fit-content;
  font-weight: 600;
  font-size: 0.875rem;
  display: flex;
  gap: 5px;
  color: #475467;
}

.content-update-page .additional-content-section .content-field .product-characterstics-section .table-footer .more-lines.disabled,
.content-update-page .additional-content-section .content-field .product-characterstics-section .table-footer .more-lines.disabled svg path {
  color: #99938D;
  stroke: #99938D;
}

.content-update-page .additional-content-section .content-field .product-characterstics-section .table-footer .more-lines.disabled:hover {
  cursor: not-allowed;
}

.content-update-page .additional-content-section .content-field .product-characterstics-section .table-footer .more-lines:hover {
  cursor: pointer;
}

.content-update-page .additional-content-section {
  border-top: 1px solid #EAECF0;
  border-bottom: 1px solid #EAECF0;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.content-update-page .additional-content-section .table-head,
.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row {
  display: grid;
  grid-template-columns: 150px 1fr 0.3fr 0.3fr;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row .upload-thumbnail .upload-label .infoicon {
  height: 16px;
  color: #475467;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row .upload-thumbnail .upload-label .infoicon:hover {
  cursor: pointer;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row>div {
  padding: 0.75rem 1.5rem 0.75rem 1rem;
  word-wrap: break-word;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row>div svg:hover,
.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row>div label:hover {
  cursor: pointer;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-head {
  background-color: #EAECF0;
  color: #475467;
  font-size: 0.75rem;
  line-height: 1.125rem;
  border-radius: 0.75rem 0.75rem 0rem 0rem;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-head div {
  padding: 0.75rem 1.5rem 0.75rem 1rem;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row {
  font-size: 0.875rem;
  align-items: center;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-footer {
  padding: 0.75rem 1.5rem 1rem 1.5rem;
  border-top: 1px solid #EAECF0;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-footer .more-lines {
  display: flex;
  gap: 2rem;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-footer .more-lines>div {
  align-items: center;
  width: fit-content;
  font-weight: 600;
  font-size: 0.875rem;
  display: flex;
  color: #475467;
  position: relative;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-footer .more-lines.disabled>div,
.content-update-page .additional-content-section .content-field .add-resouces-section .table-footer .more-lines.disabled>div svg path {
  color: #99938D;
  font-weight: normal;
  stroke: #99938D;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-footer .more-lines>div label {
  align-items: center;
  display: flex;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-footer .more-lines.disabled>div label:hover,
.content-update-page .additional-content-section .subtitles-section .table-footer .more-lines.disabled label:hover {
  cursor: not-allowed;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-footer .more-lines>div label:hover {
  cursor: pointer;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row .upload-thumbnail {
  display: flex;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row .upload-thumbnail .upload-label {
  display: flex;
  align-items: center;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row .upload-thumbnail .uploaded-image {
  display: flex;
  gap: 10px;
  align-items: center;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row .upload-thumbnail .uploaded-image .upload-image {
  max-height: 50px;
  border-radius: 4px;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row .upload-thumbnail .upload-btn {
  display: none;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row>div.upload-thumbnail .upload-withfile.disabled svg path {
  stroke: #99938D;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row>div.upload-thumbnail .upload-withfile.disabled:hover,
.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row>div.upload-thumbnail .upload-withfile.disabled svg:hover {
  cursor: not-allowed;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row .upload-thumbnail .upload-label label {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #475467
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row .upload-thumbnail .upload-label.disabled label,
.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row .upload-thumbnail .upload-label.disabled label svg path {
  color: #99938D;
  font-weight: normal;
  stroke: #99938D;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row .upload-thumbnail:hover .upload-label.disabled label,
.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row .upload-thumbnail:hover .upload-label.disabled label svg path {
  cursor: not-allowed;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row .upload-thumbnail.disabled .upload-label label:hover {
  cursor: not-allowed;
}

.content-update-page .additional-content-section .content-field .add-resouces-section .table-body .table-row .upload-thumbnail .upload-label label:hover {
  cursor: pointer;
}

.content-update-page .additional-content-section .subtitles-section .table-head,
.content-update-page .additional-content-section .subtitles-section .table-body .table-row {
  display: grid;
  grid-template-columns: 1fr 1fr 0.3fr;
}

.content-update-page .additional-content-section .subtitles-section .table-head {
  background-color: #EAECF0;
  color: #475467;
  font-size: 0.75rem;
  line-height: 1.125rem;
  border-radius: 0.75rem 0.75rem 0rem 0rem;
}

.content-update-page .additional-content-section .subtitles-section .table-head div {
  padding: 0.75rem 1.5rem 0.75rem;
}

.content-update-page .additional-content-section .subtitles-section .table-body .table-row>div {
  font-size: 0.875rem;
  display: flex;
}

.content-update-page .additional-content-section .subtitles-section .table-body .table-row>div:hover {
  cursor: pointer;
}

/* .content-update-page .additional-content-section .subtitles-section .table-body .table-row div .MuiInputBase-root .MuiSelect-root {
  width: 85%;
  font-size: 0.875rem;
} */
.content-update-page .additional-content-section .subtitles-section .table-body .table-row div .MuiInputBase-root {
  width: 100%;
  font-size: 0.875rem;
}

.content-update-page .additional-content-section .subtitles-section .table-body .table-row div .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: none
}

.content-update-page .additional-content-section .subtitles-section .table-body .table-row div .MuiInputBase-root .MuiSelect-select:focus {
  background-color: #ffffff;
}

.content-update-page .additional-content-section .subtitles-section .table-body .table-row div.table-cell {
  padding: 0.75rem 1.5rem 0.75rem;
}

.content-update-page .additional-content-section .subtitles-section .table-body .table-row div.table-cell a {
  vertical-align: sub;
}

.content-update-page .additional-content-section .subtitles-section .table-footer {
  padding: 0.75rem 1.5rem 1rem 1.5rem;
  border-top: 1px solid #EAECF0;
}

.content-update-page .additional-content-section .subtitles-section .table-footer .more-lines {
  align-items: center;
  width: fit-content;
  font-weight: 600;
  font-size: 0.875rem;
  display: flex;
  gap: 5px;
  color: #475467;
}

.content-update-page .additional-content-section .subtitles-section .table-footer .more-lines.disabled,
.content-update-page .additional-content-section .subtitles-section .table-footer .more-lines.disabled svg path {
  color: #99938D;
  font-weight: normal;
  stroke: #99938D;
}

.content-update-page .additional-content-section .subtitles-section .table-footer .more-lines label {
  align-items: center;
  display: flex;
  gap: 5px;
}

.content-update-page .additional-content-section .subtitles-section .table-footer .more-lines:disabled label,
.content-update-page .additional-content-section .subtitles-section .table-footer .more-lines:disabled label svg path {
  color: #99938D;
  stroke: #99938D;
}

.content-update-page .additional-content-section .subtitles-section .table-footer .more-lines label:hover {
  cursor: pointer;
}

.content-update-page .additional-content-section .subtitles-section .table-body .table-row .table-cell .upload-label label {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #475467
}

.content-update-page .additional-content-section .subtitles-section .table-body .table-row .table-cell .upload-label label:hover {
  cursor: pointer;
}

.content-update-page .additional-content-section .subtitles-section .table-body .table-row .table-cell .upload-label.disabled label,
.content-update-page .additional-content-section .subtitles-section .table-body .table-row .table-cell .upload-label.disabled label svg path {
  color: #99938D;
  font-weight: normal;
  stroke: #99938D;
}

.content-update-page .additional-content-section .subtitles-section .table-body .table-row .table-cell .upload-label input {
  display: none;
}

.product-charastestics-dialog .modal-heading {
  display: grid;
  grid-template-columns: 0.1fr 0.8fr 0.1fr;
  justify-content: space-between;
  margin: 1rem 1rem 0.5rem 1rem;
}

.product-charastestics-dialog .MuiDialogContent-root {
  border-top: 1px solid #eaecf0;
  border-bottom: 1px solid #eaecf0;
}

.product-charastestics-dialog .modal-heading .modal-heading-text {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
}

.product-charastestics-dialog .modal-heading .modal-heading-text .modal-heading-subtext {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.product-charastestics-dialog .modal-heading .modal-heading-text .modal-heading-subtext-bold {
  color: #475467;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.product-charastestics-dialog .info-modal {
  align-self: center;
  justify-self: center;
  border: 1px solid #EAECF0;
  border-radius: 5px;
  padding: 4px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.product-charastestics-dialog .close-modal {
  justify-self: flex-end;
}

.product-charastestics-dialog .modal-actions button {
  color: white;
  background-color: #D92D20;
  border: 1px solid #D92D20;
  padding: 0.625rem 1.125rem;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin: 0px 5px;
}

.product-charastestics-dialog .modal-actions button:hover {
  cursor: pointer;
}

.product-charastestics-dialog .modal-actions button.modal-cancel {
  border: 1px solid #e0e0ff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #0000;
  color: #344054
}

.product-charastestics-dialog .modal-body .content-field {
  padding: 10px;
}

.product-charastestics-dialog .modal-body .content-field .line {
  content: '';
  height: 100%;
  background: linear-gradient(#A4A2A2, #A4A2A2) no-repeat center / 2px 100%;
}

.product-charastestics-dialog .modal-body .content-field .MuiFormControl-root {
  width: 100%;
}

.product-charastestics-dialog .modal-body .content-field .input-label {
  color: #344054;
  font-weight: 600;
  padding: 0px 0px 4px;
  line-height: 1.5;
  display: block;
}


.content-update-page .restriction-section {
  border-top: 1px solid #ddd;
  background: white;
  padding: 0rem 2.5rem 2.5rem;
}


.content-update-page .restriction-section .box-body .MuiFormControlLabel-root:hover {
  cursor: default;
}

.content-update-page .restriction-section .content-field {
  padding: 12px 6px;
}

.content-update-page .restriction-section .content-field .input-label {
  color: #344054;
  font-weight: 600;
  padding: 0px 0px 4px;
  line-height: 1.5;
  display: block;
}

.content-update-page .restriction-section .box-section {
  border-radius: 0.75rem;
  border: 1px solid #EAECF0;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-bottom: 20px;
}

.content-update-page .restriction-section .box-section .box-header {
  padding: 0.75rem 1.5rem 0.75rem 1rem;
  align-items: center;
  background-color: #F9FAFB;
  border-bottom: 1px solid #EAECF0;
  color: #475467;
  font-size: 0.75rem;
  font-weight: 600;
}

.content-update-page .restriction-section .box-section .box-body {
  padding: 0.75rem 1.5rem 0.75rem 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.content-update-page .restriction-section .box-section .box-body .MuiFormControlLabel-root .MuiTypography-root {
  color: #344054 !important;
  font-weight: 600;
  font-size: 0.875rem;
}

.content-update-page .restriction-section .selection-section {
  border-radius: 0.75rem;
  border: 1px solid #EAECF0;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-bottom: 20px;
  padding: 1rem 1.5rem 1rem 1rem;
}


.content-update-page .restriction-section .selection-section .filter-dropdown-popout .some-filter>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-update-page .restriction-section .selection-section .filter-dropdown-popout .some-filter,
.content-update-page .restriction-section .selection-section .filter-dropdown-popout .some-filter div:first-child {
  position: relative;
}

.content-update-page .restriction-section .selection-section .filter-dropdown-popout .some-filter .filterInputWrapping {
  width: 100%;
  max-width: 850px;
}

.content-update-page .restriction-section .selection-section .filter-dropdown-popout .some-filter .filterInputWrapping.disabled,
.content-update-page .restriction-section .selection-section .filter-dropdown-popout .some-filter .filterInputWrapping.disabled input {
  background-color: #E2E5EA;
  color: #99938D;
  cursor: not-allowed;
}

.content-update-page .restriction-section .selection-section .filter-dropdown-popout .some-filter .filterUl {
  width: 95%;
  max-width: 850px;
  position: absolute;
  right: 0;
}

.content-update-page .quiz-section {
  border-top: 1px solid #ddd;
  background: white;
  padding: 0rem 2.5rem 2.5rem;
}

.content-update-page .quiz-section .quiz-header {
  display: flex;
  align-items: center;
}

.content-update-page .quiz-section .content-field .filter-dropdown-popout .filterLabel {
  color: #344054;
  font-weight: 600;
  font-size: 1rem;
}

.content-update-page .quiz-section .content-field .filter-dropdown-popout .mandatory::after {
  color: #E02F2B;
  content: '*';
  padding: 5px;
  vertical-align: middle;
}

.content-update-page .quiz-section .content-field .filter-dropdown-popout .filterInputWrapping {
  width: 100%;
}

.content-update-page .quiz-section .content-field .filter-dropdown-popout .filterInputWrapping.disabled {
  background-color: #E2E5EA;
  color: #99938D;
  cursor: not-allowed;
}

.content-update-page .quiz-section .content-field .input-label {
  color: #344054;
  font-weight: 600;
  padding: 0px 0px 4px;
  line-height: 1.5;
  display: block;
}

.content-update-page .quiz-section .content-field {
  padding: 10px;
}

.content-update-page .quiz-section .content-field .text-input {
  margin: 0 !important;
}

.content-update-page .quiz-section .content-field .text-input .MuiOutlinedInput-root input {
  padding: 7px 10px;
}

.content-update-page .quiz-section .content-field .text-input .MuiOutlinedInput-root.Mui-disabled input {
  background-color: #E2E5EA;
  color: #99938D;
  cursor: not-allowed;
}

.content-update-page .quiz-section .content-field .text-input fieldset {
  padding-left: 8px;
}

.content-update-page .quiz-section .switch-section {
  margin-left: auto;
  padding: 3px;
  display: flex;
  align-items: center;
}

.content-update-page .quiz-section .switch-section .MuiFormControlLabel-root .MuiTypography-body1 {
  color: #344054 !important;
  font-weight: 600;
  font-size: 0.875rem;
}

.content-update-page .content-share-section {
  border-top: 1px solid #ddd;
  background: white;
  padding: 0rem 2.5rem;
}

.content-update-page .content-share-section .content-field .input-label {
  color: #344054;
  font-weight: 600;
  padding: 0px 0px 4px;
  line-height: 1.5;
  display: block;
}

.content-update-page .content-share-section .content-field {
  padding: 12px 6px;
}

.content-update-page .content-share-section .content-field .helper-text {
  color: #8B847E;
  font-size: 14px;
  padding: 8px 0px;
}

.content-update-page .content-share-section .content-field.radio .input-label {
  padding: 0;
}

.content-update-page .content-share-section .content-field.radio fieldset {
  margin: 0;
}

.content-update-page .content-share-section .content-field .radio-input .MuiRadio-colorSecondary.Mui-checked {
  color: #E02F2B !important;
}

.content-update-page .content-share-section .content-field .radio-input .radio-options {
  max-height: 30px;
}

.content-update-page .content-share-section .content-field .radio-input .radio-options.Mui-disabled .MuiRadio-colorSecondary.Mui-disabled .MuiIconButton-label {
  background-color: #E2E5EA;
  color: #99938D;
  cursor: not-allowed;
  border-radius: 50%;
}

.content-update-page .content-share-section .content-field .radio-input .radio-options .MuiTypography-body1 {
  color: #344054 !important;
  font-weight: 600;
}

.content-update-page .country-to-publish-section {
  border-top: 1px solid #ddd;
  background: white;
  padding: 0rem 2.5rem 2.5rem;
}

.content-update-page .country-to-publish-section .content-field .input-label {
  color: #344054;
  font-weight: 600;
  padding: 0px 0px 4px;
  line-height: 1.5;
  display: block;
}

.content-update-page .country-to-publish-section .content-field {
  padding: 10px;
}

.content-update-page .country-to-publish-section .content-field .helper-text {
  color: #8B847E;
  font-size: 14px;
  padding: 8px 0px;
}

.content-update-page .country-to-publish-section .publish-status-box {
  border-radius: 0.75rem;
  border: 1px solid #EAECF0;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-bottom: 20px;
}

.content-update-page .country-to-publish-section .publish-status-box .publish-status-box-header,
.content-update-page .country-to-publish-section .publish-status-box .publish-status-box-body .table-row {
  align-items: center;
  display: grid;
  grid-template-columns: 3fr 1fr 0.5fr;
}

.content-update-page .country-to-publish-section .publish-status-box .publish-status-box-header .invisible span {
  opacity: 0;
}

.content-update-page .country-to-publish-section .publish-status-box .publish-status-box-body .table-row {
  border-bottom: 1px solid #EAECF0;
}

.content-update-page .country-to-publish-section .publish-status-box .publish-status-box-header div,
.content-update-page .country-to-publish-section .publish-status-box .publish-status-box-body .table-row .table-cell {
  padding: 1rem 1.5rem 1rem 1rem;
}

.content-update-page .country-to-publish-section .publish-status-box .publish-status-box-header div {
  color: #475467;
  font-size: 0.75rem;
  border-bottom: 1px solid #EAECF0;
}

.content-update-page .country-to-publish-section .action-items {
  display: flex;
  justify-content: center;
  padding: 0rem 0rem 0rem 1rem;
}

.content-update-page .country-to-publish-section .action-items div .action-icon {
  margin: 12px 2px;
}

.content-update-page .country-to-publish-section .action-items div .action-icon:hover {
  cursor: pointer;
}

.content-update-page .country-to-publish-section .action-items .vertical-circles {
  width: min-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.content-update-page .country-to-publish-section .action-items .action-icon {
  background-color: #D92D20;
  width: min-content;
  padding: 6px;
  margin: 12px 2px;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.content-update-page .country-to-publish-section .action-items .action-icon.no-bg {
  background-color: transparent;
}

.content-update-page .content-status .single-content {
  font-size: 0.75rem;
}

.final-step {
  background: white;
  width: 100%;
  padding: 0rem 0.05rem 4rem 0.05rem;
  text-align: center;
}

.final-step .content-message {
  color: #303030;
  font-size: 1.375rem;
  font-weight: 700;
}

.final-step .action-items {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding: 0px 18rem;
  gap: 20px;
}

.final-step .action-items .action-buttons {
  border: 1px solid #D0D5DD;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 0.5rem 0.875rem;
  display: flex;
  gap: 4px;
  font-size: 0.875rem;
  font-weight: 600;
}

.final-step .action-items .action-buttons.red {
  border: 1px solid #D92D20;
  background-color: #D92D20;
  color: white;
}

.final-step .action-items .action-buttons.red.disabled {
  background-color: #9e6a66;
  border: 1px solid #9e6a66;
}

.final-step .action-items .action-buttons.red.disabled:hover {
  cursor: not-allowed;
}

.final-step .action-items .action-buttons:hover,
.final-step .action-items .action-buttons:hover label:hover {
  cursor: pointer;
}

.snackbar.error {
  color: black !important;
  background-color: #fff300 !important;
  border-radius: 0.625rem;
}

.snackbar.error svg {
  fill: black;
}


.snackbar.success {
  color: white;
  background-color: #67AE72 !important;
  border-radius: 0.625rem;
}

.snackbar.success svg {
  fill: white;
}