.cont-piinfo-home {
  box-shadow: none !important;
  margin: 5px 0 5px 15px !important;
  text-align: left !important;
}
.cont-piinfo-home .pi_tooltip span {
  font-size: 12px !important;
}
.cont-piinfo {
  margin: 5px 0 !important;
  text-align: left !important;
}
.cont-piinfo .pi_tooltip span {
  font-size: 12px !important;
}
.textHd {
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  letter-spacing: -0.011em;
  color: #82786F !important;
  margin-left: 1%;
  box-shadow: none !important;
}
.notDataFound a {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}

.textHdSm {
  font-weight: 400;
  /* font-size: 18px; */
  font-size: calc(15px + 0.2vw) !important;
  line-height: 35px;
  letter-spacing: -0.011em;
  color: #82786F !important;
  margin-left: 1%;
  box-shadow: none !important;
}

.pdLft {
  text-align: right;
  padding-right: 1%;
}

.showBtn {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.011em;
  color: #1592E6 !important;
  margin-top: 10px !important;
  display: inline-block;
  cursor: pointer;
}

.showBtn:hover {
  text-decoration: underline;
}

.home_sec_header_extra {
  margin: 20px 0 5px 0;
}


.home_sec_header {
  margin: 10px 0 5px 0;
}

.carousel {
  border-bottom: 2px solid #f7f6f7;
  padding-bottom: 10px;
}

.homeOuter {
  width: 90%;
  margin: 0 auto;
}

.contentHd {
  font-weight: 600;
  font-size: 17px;
  line-height: 35px;
  letter-spacing: -0.011em;
  color: #82786F !important;
  box-shadow: none !important;
  padding-left: 0px;
  margin: -5px 0 10px 0.5%;
}
.labelClass{
  margin-left: 26px!important;
}
#root{
  pointer-events: all !important;
}

@media (max-width:960px) {
  .textHd {
    /* margin-left: 5% !important; */
  }

  .pdLft {
    /* padding-right: 6% !important; */
  }

  .homeOuter {
    width: 100%;
  }
}

.searchSort {
  margin-left: 10px;
  position: relative;
  bottom: -4px;
}

.searchClose {
  height: 15px;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  top: -2px;
}

.searchSortSec {
  font-size: 18px;
  margin-top: 20px;
  color: #82786F !important;
}

.searchSortTxt {
  position: relative;
  top: -2px;
  margin-left: 5px;
}

.live_event-modal .MuiPaper-root {
  min-width: 50%;
  max-width: 50%;
}

.live_event-modal .modal-top {
  padding: 0;
}

.live_event-modal .modal-top .closeModal {
  margin: 10px 10px 0 0;
}

.live_event-modal .modal-middle {
  text-align: center;
  overflow-y: unset;
}

.live_event-modal .event_details .event_title {
  margin: 0 0 30px 0;
  ;
}

.live_event-modal .event_details .event_date, .live_event-modal .event_details .event_duration {
  font-weight: 400;
  margin: 0 0 10px 0;
  ;
}

.live_event-modal .event_details .event_info_head {
  text-align: center;
  color: #d03c3a;
  font-weight: 600;
  font-size: 17px;
}

.live_event-modal .event_details .event_speakers {
  list-style: none;
  text-align: left;
  padding: 0;
  margin-left: 10px;
}

.live_event-modal .event_details .event_speakers li {
  margin: 0 0 10px 0;
}

.live_event-modal .event_details .event_desc {
  text-align: left;
  margin-right: 10px;
  font-size: 14px;
}

.live_event-modal .event_details .event_speakers .speaker_icon,
.live_event-modal .event_details .event_speakers .speaker_name {
  display: inline;
  vertical-align: middle;
}

.live_event-modal .event_details .event_speakers .speaker_icon {
  width: 100%;
  margin-right: 10px;
}

.live_event-modal .event_details .event_speakers .speaker_name {
  color: #d03c3a;
  font-weight: 600;
  font-size: 14px;
}

.live_event-modal .modal-bottom {
  justify-content: center;
}

.live_event-modal .modal-bottom .modalBtn {
  width: unset;
}
.libraryTitle {
  text-align: center;
  color: #d03c3a !important;
}
.libModal {
  border-radius: 4px !important;
  width: 45% !important;
  left: 45% !important;
}

@media only screen and (max-width: 600px) {

  .live_event-modal .event_details .event_desc,
  .live_event-modal .event_details .event_speakers {
    text-align: center;
  }

  .live_event-modal .event_details .event_speakers .speaker_icon,
  .live_event-modal .event_details .event_speakers .speaker_name {
    display: inline-block;
  }
}
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
  .slick-arrow.slick-prev {
    display: block !important;
  }
  .slick-arrow.slick-next {
    display: block !important;
  }
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
  .slick-arrow.slick-next {
    display: block !important;
  }
  .slick-arrow.slick-prev {
    display: block !important;
  }
}
/* @media (min-width: 600px) and (max-width: 767px) {
  .carousel .slickLgSec .slick-list{
    height: 500px;
  }
} */