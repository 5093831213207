.breadcrumb {
  padding: 0 6%;
  background: #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  margin: 0 auto;
  height: 44px;
}

.bTxt {
  font-weight: 400;
  font-size: 13px;
  line-height: 48px;
  letter-spacing: -0.011em;
  color: #d52b1e !important;
  cursor: pointer;
}

.cookieBanner {
  padding: 5px 65px;
  z-index: 100000;
  background: #fff;
  height: fit-content;
  color: #525c5f;
  /* position: absolute !important; */
}
.cookieBtn {
  padding: 8px 5px;
  border-radius: 4px;
  margin: 10px;
  cursor: pointer;
  border: 2px solid #d52b1e;
  color: #d52b1e;
  background-color: transparent;
  border-color: #d52b1e;
  box-shadow: none;
  font-weight: 700;
  font-size: 17px;
  width: 45%;
}
.cookieBtn.be {
  width: 65%;
}
@media (max-width: 600px) {
  .cookieBanner {
    padding: 5px 15px !important;
  }
  .cookieBtn {
    margin: 5px;
  }
}

.list {
  width: 250 !important;
}

.fullList {
  width: auto !important;
}

.hcp {
  top: 116px !important;
  /* width: 240px !important; */
  padding: 20px 30px !important;
  height: calc(100% - 116px) !important;
}
.nonhcp {
  top: 116px !important;

}

.nested {
  padding-left: 2%;
}

.menuBtn {
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 26px !important;
  text-align: center;
  letter-spacing: -0.011em;
  color: #434343;
  margin: 0 0 6px 0;
  background: #fff;
  padding: 0 12px;
  border: 1px solid #4343432b;
  display: block;
  border-radius: 4px;
  cursor: pointer;
}

.tharpHd {
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  letter-spacing: -0.011em !important;
  color: #000000;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 5px; /*hamburger menu width changes*/
}

.userArea {
  padding-left: 0 !important;

}

.userArea :hover {
  background: transparent;
}

.thatTxt {
  font-weight: 600 !important;
  font-size: 16px !important;
  /* line-height: 44px !important; */
  letter-spacing: -0.011em !important;
  color: #000000;
  line-height: 25px !important; /*hamburger menu width changes*/
}

.leftArrow {
  color: #d52b1d;
  margin: 0 20px 0 10px;
  width: 8px;
}

.rightArrow {
  color: #d52b1d;
  margin: 0 20px 0 10px;
  width: 6px;
}

.showMenu {
  padding-right: 0 !important;
}

.showMenu:hover {
  background-color: transparent !important;
}

.avatar {
  width: 70px !important;
  height: 70px !important;
  margin-top: 15px;
}

.uname {
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 29px !important;
  letter-spacing: -0.011em !important;
  color: #000000;
  margin-bottom: 5px !important;
  display: inline-block;
}

.searchRootIcn {
  width: 1em !important;
  height: 1em !important;
  margin-top: 3px;
}

.logout {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 50px !important;
  letter-spacing: -0.011em;
  color: #000000;
  background: transparent;
  border: none;
  cursor: pointer;
}

.ulist {
  padding: 0 !important;
  cursor: pointer;
}

.flex-div{
  display: flex !important;
  /*margin-top: 5px;*/ /*hamburger menu width changes*/
  /*margin-bottom: 6px;*/ /*hamburger menu width changes*/
}

.leftArrow-icon{
  width: 20% !important;
}

.padding-zero{
  padding: 0px !important;
}

.therapeutic-list:hover{
  background-color: rgba(0, 0, 0, 0.04);
}

.ulistSec {
  padding: 0 !important;
}

.userList {
  border-top: 2px solid #7d7d7d;
  border-bottom: 2px solid #7d7d7d;
  margin-top: 10px !important;
  padding: 0 !important;
}

.listGutter {
  padding-left: 0 !important;
}

.subMenu {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 43px !important;
  letter-spacing: -0.011em !important;
  color: #898989;
}

.safeSec {
  border-top: 3px solid #e9e6af !important;
}

.safehd {
  color: #000000bd !important;
  font-size: 16px !important;
  box-shadow: 1px 2px 3px rgb(220 221 222) !important;
  font-weight: 600;
  margin: 15px 0 2px 0 !important;
  text-transform: uppercase !important;
  padding-left: 20px !important;
  padding-bottom: 15px !important;
  letter-spacing: -0.022em;
}

.texthd {
  background-color: transparent !important;
  box-shadow: none !important;
  text-transform: uppercase !important;
  color: #b3ada8 !important;
  font-weight: 600;
  font-size: 20px !important;
  margin-bottom: 10px !important;
  text-align: left;
}

.textsubhd {
  background: #fff;
  border: 2px solid #000000a6;
  border-radius: 1px !important;
  padding: 10px 10px !important;
  box-shadow: 0 1px 4px #909090 !important;
  font-size: 16px !important;
  color: #000000 !important;
  line-height: 24px;
  text-align: left;
  font-weight: 400;
}

.fTxtSec {
  background: #a59c95 !important;
  padding: 20px 0 10px 0 !important;
}

.fTxt {
  background: transparent !important;
  padding: 2px 20px !important;
  box-shadow: none !important;
  font-size: 12px !important;
  color: #fff !important;
  font-weight: 400 !important;
  line-height: 14px !important;
}

.safeInner {
  background: transparent;
  width: 62% !important;
  margin: 0 auto !important;

}

.bar {
  padding-left: 4px !important;
}

.flistOuter {
  list-style: none !important;
  padding-left: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.flist {
  display: inline !important;
}

.flist a {
  color: #fff;
  text-decoration: none;
}

.changeColor {
  color: #6ac5ec !important;
}

.flistSec {
  text-align: right !important;
  /* margin-top: 4% !important; */
}

.root {
  flex-grow: 1;
}

.title {
  flex-grow: 1;
}

.mobilesearchIcon {
  padding: 0 !important;
  display: none !important;
}

.searchTxt .MuiInputBase-input {
  letter-spacing: -0.062em !important;
  font-weight: 600 !important;
  color: #000 !important;
}

.inputRoot {
  color: gray;

}

.navigation {
  background: #d52b1e;
  padding: 0 12px !important;

}

.tagCarousel {
  width: 100% !important;
  max-width: 480px;
  float: left !important;
  overflow-y: auto;
}

.searchDesktopIcn {
  padding-right: 10px !important;
}

.listSec {
  /* width: 92% !important; */
  margin: 0 auto !important;
  overflow: hidden;
}

.hdOuter {
  width: 92% !important;
  margin: 0 auto;
  background: #d52b1e !important;
}

.searchTxt {
  margin: 20px !important;
  background: #fff !important;
  width: 90% !important;
  border-radius: 6px !important;
}

#mobile_searchbar {
  width: 95% !important;
}
.mobSrch {
  color: #7d7d7d !important;
  padding-right: 10px !important;
}

.mobSrchArw {
  color: #7d7d7d !important;
  font-size: 30px !important;
}

.slideSec {
  background: #d52b1e !important;
  margin-top: 68px !important;
  padding: 0 6% 0 2% !important;
}

.userBtn {
  color: #fff !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
  border-radius: 0 !important;
  line-height: 12px !important;
  margin-top: 12px !important;
  margin-right: 8px !important;
}

.userBtn:hover {
  border-bottom: 1px solid #fff !important;
}

.menuItem {
  border-bottom: 1x solid rgb (206 206 206) !important;
  text-align: center !important;
  padding: 0 !important;
}

.mangDropdownSec {
  position: relative !important;
  display: inline-block;

}

.mangDropdown {
  z-index: 100000;
  padding: 0 !important;
  border: 1px solid #bababa !important;
  border-radius: 0 0 10px 10px !important;
  background: #fff !important;
  width: 200px !important;
  margin-top: 5px;
  position: absolute !important;
  top: auto !important;
  right: -20px !important;
  left: unset !important;
}

.menulistItem {
  display: inline-block !important;
  width: 100% !important;
  font-weight: 400;
  font-size: 13px !important;
  line-height: 34px !important;
  letter-spacing: -0.011em;
  color: #887e76 !important;
  border-bottom: 1px solid #887e7647;
}

.menulistItem:hover {
  color: #d52b1e !important;
  font-weight: 600 !important;
}

.header_searchIcon {
  width: 3% !important;
  position: relative !important;
  top: 3px;
  right: 1%;
}

.header_searchIconM {
  width: 100%;
  position: relative !important;
  right: 4%;
}

.chip_selected {
  background: #fff !important;
  color: #d52b1e !important;
}

.nonhcp {
  /* width: 240px !important; */
  padding: 20px 30px !important;
  height: calc(100% - 116px) !important;

}


.pdL-10 {
  padding-left: 10px !important;
}

.FS-50 {
  font-size: 50px !important;
}

.footerBck {
  background: #F9FAFC !important;
  padding: 20px 0 !important;
}

.whtBtnCookie {
  background: #d52b1e !important;
  color: #fff !important;
}

.cookie-consent-btn-container {
  text-align: right !important;
}

.appbarBck {
  background-color: #d52b1e !important;
  box-shadow: none !important;
  z-index: 998 !important;
}

.disLine {
  display: inline;
  margin-bottom: 5px;
}

.textRight {
  text-align: right;
}
.resolution-150{
  display: none;
}
.other-resolution{
  display: contents;
}

.fix-width-list{
  max-width: 230px;
}

.custom-line-height{
  line-height: 27px !important;
}

.tag-list::-webkit-scrollbar {
  display: none;
}

/* .fix-width-hamberger-menu{
  width: 205px;
} */

@media (max-width:1300px) {

  .listSec {
    /* width: 96% !important; */
  }

  .userBtn {
    margin-right: 4% !important;
  }


}
@media (max-width:960px) {

  .hcp {
    top: 168px !important;
    height: calc(100% - 168px) !important;
    padding: 20px 30px 0px !important;

  }

  .nonhcp {
    top: 152px !important;
    padding: 20px 30px 0px !important;
    height: calc(100% - 152px) !important;

  }

  .textsubhd {
    font-size: 15px !important;
    /* font-weight: bold !important; */
    line-height: 30px !important;
  }

  .safeInner {
    width: 90% !important;
  }

  .bar {
    display: none !important;
  }

  .flist {
    display: inline-block !important;
    width: 90% !important;
    padding-bottom: 10px !important;
  }
  
  .fcpyrghtSec {
    text-align: center !important;

  }

  .flistSec {
    text-align: center !important;

  }

  .fTxt {
    padding: 0 20px !important;
    font-size: 15px !important;
    line-height: 16px !important;
  }

  .mobilesearchIcon {
    display: block !important;
  }

  .navigation {
    /* margin-top: 68px; */
    padding: 0 !important;

  }

  .listSec {
    width: 100% !important;
    overflow: hidden !important;
    /* margin-top: 80px !important; */
    height: 50px !important;
  }
  .mangDropdownSec {
    position: relative !important;
    height: 38px !important;
  }

  .hdOuter {
    width: 100% !important;
    margin: 0 auto;
  }

  .containerAlign {
    padding: 0 4% !important;

  }

  .slideSec {
    margin-top: 0 !important;
    padding: 0 0 0 4% !important;
  }


}
@media (max-width:600px) {

  .breadcrumb {
    padding-left: 5% !important;
    height: auto !important;
  }

  .hcp {
    top: 154px !important;
    height: calc(100% - 154px) !important;
    padding: 20px 30px 0px !important;


  }
  .nonhcp {
    top: 120px !important;
    padding: 20px 30px 0px !important;
    height: calc(100% - 120px) !important;
  
  }
  .listSec {
    width: 100% !important;
    overflow-x: scroll !important;
    height: 40px;
    overflow-y: hidden !important;
    margin-top: 0px !important;
  }
  .appbarBck.scroll {
    height: 80px !important;
  }
  .mangDropdownSec {
    position: relative !important;
    height: 36px !important;
  }

  .userBtn {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .ulistOuter {
    max-height: 224px !important;
    height: 100% !important;
    max-width: 240px; /*hamburger menu width changes*/

  }

  /* .mangDropdown {
    right: 5px !important;
    top: 23% !important;
  } */
}
@media (min-width: 320px) and (max-height: 896px) and (orientation: portrait) {
  .ulist {
    padding: 21px 0px !important;
    cursor: pointer;
    height: 31px !important;
  }
}
@media (min-width: 600px) {
  .ulist {
    margin-bottom: 14px;
  }
}
@media (max-width: 600px) {
  .thatTxt-sec-cat {
    line-height: 20px !important;
  }
  .sec-cat-list{
    margin-bottom: 20px;
  }
}
@media (max-width: 920px) {
  .mobile-only {
    display: contents;
  }
}
@media (max-width: 600px) {
  .flex-div{
    min-height: 43px;
  }
}
@media (min-width: 600px) {
  .desktop-only {
    display: contents;
  }
}
@media (resolution:1.5dppx){
  .resolution-150{
    display: contents;
  }
  .custom-btn-font-size{
    font-size: 11px !important;
  }
  .custom-margin-top-div{
    margin-top: 10% !important;
  }
  .other-resolution{
    display: none;
  }
  .hcp {
    top: 130px !important;
    padding: 5px 20px !important;
  }
  .nonhcp {
    top: 130px !important;
  
  }
}
@media (max-width:576px) {
  .fix-width-hamberger-menu {
    width: 227px;
  }
  .thatTxt {
    line-height: 20px !important;
}
}
@media (min-width: 600px) {
  .search-textfield input{
    max-width: 85%;
  }
}

/* -- Below CSS for UK Footer-- */

#footer-content-uk {
  position: fixed;
  bottom: 0%;
  width: 100%;
  right: 0;
  left: 0;
  background-color: #f9fafc;
  z-index: 99;
}

#footer-content-uk .collapsible {
background-color: #fff;
color: #212b36;
cursor: pointer;
padding: 18px;
width: 100%;
border: none;
text-align: left;
outline: none;
font-size: 20px;
border-top-color: #C5BC1F;
border-top: 0.125rem solid #C5BC1F;
box-shadow: 0 2px 4px 0 rgba(224, 230, 237, 0.5);
perspective: 9999px;
font-weight: 500 !important;
line-height: 1.7rem;
}

#footer-content-uk button.collapsible:after {
  content: '\02C4';
  color: #212b36;
  /* font-weight: bold; */
  float: right;
  margin-left: 5px;
}

#footer-content-uk button.collapsible.active:after {
  content: "\02C5";
}

#footer-content-uk button.collapsible:hover {
background-color: #fff;
}

#footer-content-uk button.active {
background-color: #fff;
}

#footer-content-uk .content-uk-ft {
padding: 0 18px;
display: none;
overflow: hidden;
}

#cookieBannerSec {
  display: flex;
  background: white;
  border-top: 4px solid #d52b1e;
}
.custom_container-height {
  margin-top: 215px !important;
}
@media (max-width: 576px) {
  .custom_container-height{
    margin-top: 510px !important;
  }
}
@media (min-width: 576px) and (max-width: 770px) {
  .custom_container-height{
    margin-top:370px !important;
  }
}
@media (min-width: 770px) and (max-width: 1200px) {
  .custom_container-height{
    margin-top: 345px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .custom_container-height{
    margin-top: 240px !important;
  }
}
@media (min-width: 1301px) and (max-width: 1400px) {
  .custom_container-height{
    margin-top: 215px !important;
  }
}