/* .container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
} */

/* .box {
  border: 3px solid #666;
  background-color: #ddd;
  border-radius: .5em;
  padding: 10px;
  cursor: move;
}
.box.over {
  border: 3px dotted #666;
} */
.piInfo {
  line-height: 18px;
  font-size: 12px;
  padding: 0 0 0 20px;
}
.piInfo span{
  margin-right: 5px;
  word-break: break-word;
}
.outer {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}

.hideDesktop {
  display: none !important;
}

.hideMobile {
  display: block !important;
}

.upload_box {
  border: 1px solid rgb(206 206 206);
  text-align: center;
}

.upload_box .MuiButton-containedPrimary {
  display: block;
}

.upload_box .image_control img {
  max-width: 100%;
}

.upload_box .link_control .link_url,
.upload_box .link_control .link_target {
  text-align: center;
  padding: 0;
}

.upload_box .link_control .MuiFormGroup-root {
  background-color: #e0e0e0;
}

.upload_box .link_control .link_url {
  background: #fff;
}

.upload_box .link_control .link_target {
  margin: 7px 0 0 0;
  display: block;
}

.upload_box .link_control .link_target .MuiIconButton-label {
  margin: 5px 0;
}

.upload_box .link_control {
  padding: 10px;
  text-align: left;
}

.upload_box .link_control .MuiFormControl-root {
  width: 100%
}

.upload_box .link_control input {
  background: #fff;
  padding: 8px;
}

.tabindicator {
  margin: 5px;
  padding: 3px 12px;
  position: absolute;
  top: 31px;
}

/* .MuiSvgIcon-root {
  width: 0.8em !important;
  height: 0.8em !important;
} */

.upload_box .upload_control input {
  display: none;
}

.upload_box .upload_control .MuiButton-containedPrimary {
  background-color: #1692e6 !important;
  padding: 0px 16px;
}

.upload_box .upload_control .MuiButton-containedSecondary {
  background-color: #d52b1e !important;
  padding: 0px 16px;
  display: block;
}

.upload_box .upload_control .MuiButton-label {
  display: inline-flex !important;
  margin: 5px 0;
}

.upload_box .upload_control .MuiButton-label .MuiTypography-caption {
  font-size: 15px;
  text-transform: capitalize;
}

.MuiTabs-scroller {
  margin: 10px 0 !important;
}

.MuiTab-root {
  padding: 1px 12px !important;
  color: #d52b1e;
  
  background: #fff ;
  border: 2px solid #d52b1e ;
  border-radius: 30px !important;
  min-width: unset !important;
  min-height: unset !important;
  margin-right: 10px !important;
  font-weight: bold !important;
}

.banner_chip_non_selected {
 
  
  border: 2px solid #D52B1E !important;
  color: #D52B1E !important;
  opacity: 1 !important;
    border: '2px solid #D52B1E';
   
    /* padding: '0px 4px 3px 4px';
    cursor: 'pointer'; */
    /* alignItems: 'center';
   
    fontSize: 'calc(10px + 0.2vw)';
    borderRadius: '40px';
    textTransform: 'capitalize'; */
}
.banner_chip_non_selected :hover{
  color: 'white !important';
  background-color: '#D52B1E !important';
  border: '2px solid #D52B1E !important';
}
.banner_chip_selected {
 
  background: #D52B1E !important;
  border: 2px solid #D52B1E !important;
  opacity: 1 !important;
 
    color: white !important;
    /* border: '2px solid #D52B1E';
   
    padding: '0px 4px 3px 4px';
    cursor: 'pointer'; */
    /* alignItems: 'center';
   
    fontSize: 'calc(10px + 0.2vw)';
    borderRadius: '40px';
    textTransform: 'capitalize'; */
}
.banner_chip_selected :hover {
  color: 'white !important';
  background-color: '#D52B1E !important';
  border: '2px solid #D52B1E !important';
}
.banner_chip_selected:selected {
  color: 'white';
  background-color: '#D52B1E !important';
  border: '2px solid #D52B1E !important';
}



.MuiTabs-indicator {
  background: none !important;
}

.MuiTab-textColorInherit.Mui-selected {
  background: #d52b1e !important;
  color: #fff !important;
}

.MuiTab-wrapper {
  text-transform: capitalize !important;
  white-space: nowrap !important;
}

.active {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.011em;
  color: #24D35E;
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

.notactive {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.011em;
  color: #82786F;
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

.link_url .MuiOutlinedInput-root {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.iconImg {
  margin: 15px 5px;
  width: 20px;
  height: 20px;
  border: none;
  display: inline-block;
  background: none;
}

.iconImg:hover {
  cursor: pointer;
}

.iconImg.dragdropIcon {
  background-image: url('../../../public/img/drag_and_drop.svg');
}

.iconImg.removeIcon {
  background-image: url('../../../public/img/CloseGrey@3x.png');
}

.iconImg.removeIcon:hover {
  background-image: url('../../../public/img/RedClose@3x.png');
}

.deleteOutlineIcon {
  font-size: 30px !important;
  margin-top: 6px;
  color: #82786f;
}

.closebtnImg {
  background-image: url('../../../public/img/CloseGrey@3x.png');
  width: 20px;
  height: 20px;
  border: none;
  display: inline-block;
  border-radius: 50%;
  margin-top: 10px;
  margin-left: 5px;
}

.pending-libraries .MuiSwitch-switchBase {
  left: -10px !important;
}

.labelFont {
  margin-left: 10px !important;
}

.labelFont .MuiFormControlLabel-label {
  font-weight: 400 !important;
  color: #82786f !important;
  font-size: 14px !important;
}

.labelFont .MuiCheckbox-colorPrimary.Mui-checked {
  color: #1592e6 !important;
}

.addIcon, .editIcon {
  /* font-size: 30px !important;
  color: #82786f;
  border: none; */
  cursor: pointer;
  display: block;
  margin: 5px 0;
}

.labelFontTxt {
  font-weight: 700 !important;
  color: #82786f !important;
  font-size: 14px !important;
}

.itemAdd {
  color: #1592e6 !important;
  position: relative;
  cursor: pointer;
  margin: 10px;
}

.itemAdd img {
  position: absolute;
  top: 2px;
  left: 0px;
}

.itemAdd span {
  margin-left: 24px;
  font-weight: 700;
  font-size: 14px;
}

.audArea {
  border: 1px solid #ececec;
  margin: 10px !important;
  box-sizing: border-box;
  /* width: calc(20% - 20px); */
  display: table-cell;
}

.audAreaOuter {
  display: table !important;
  border-spacing: 10px;
}

.audTxt {
  box-shadow: none !important;
  font-size: 14px;
  text-align: center;
  margin: 10px;
  font-weight: 700;
  color: #82786f !important;
}

.libSmTxt {
  width: 100%;
  color: rgb(213, 43, 30) !important;
  font-size: 12px;
  font-weight: 700;
  box-shadow: none !important;
  margin-top: 15px;
}

.uploadLib {
  /* margin: 0px 30px 20px 25px; */
  margin-left: auto;
  margin-right: auto;
  width: 140px;
  height: 140px;
  border: 1px solid #82786f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 10px;
  color: #82786f;
  font-size: 18px;
  font-weight: 700;
  background: #f9f8f8;
  cursor: pointer;
}

.libIncSec {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
}

.libIncSpan {
  color: #82786f;
  font-size: 14px;
  margin: 10px 15px 0 15px;
}

.updLibTxt {
  /* width: 300px !important; */
  margin-top: 20px !important;
}

.hideTxt {
  box-shadow: none !important;
  font-size: 14px;
  color: rgb(130, 120, 111);
  font-weight: 600;
  margin-top: 10px;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-10 .MuiInputLabel-root {
  font-weight: 500 !important;
}

.ptb-10 {
  margin: 10px 0px !important;
}

.grid_row {
  width: 100%;
}

.appbarSec {
  background-color: transparent !important;
  box-shadow: none !important;
  border-bottom: 2px solid #d52b1e;
  height: 30px !important;
  z-index: 10 !important;
}

.appTab {
  height: 30px;
  border :2px solid  #d52b1e !important;
  color: #D52B1E !important;
  border-radius: 0px !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.appbarSec .MuiTabs-scroller {
  margin: 0 !important;
}

.p-10-0 {
  padding: 10px 0 !important;
}

.p-0-30 {
  padding: 0 30px !important;
}

.p-10-30 {
  padding: 10px 30px !important;
}


.uploadLibOuter {
  position: relative;

}

.uploadLibInner {
  position: absolute;
  background: #fff;
  top: 20px;
  left: 20%;
  width: 600px;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
}

.uploadLiblist {
  list-style: none;
  padding-left: 0px;
  width: 100%;
  display: inline;
}

.uploadLibInnerRight {
  position: absolute;
  background: #fff;
  top: 20px;
  right: 20%;
  width: 600px;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
}

.flex-div{
  display: flex !important;
}

.pdl-0 {
  padding-left: 0px;
}

.uploadLiblistInner {
  display: inline;
  width: 100%;
  height: 100%;
}

.uploadLiblistInner img {
  width: 80%;
  margin: 15px;
}

.uploadHdSec {
  border-bottom: 1px solid #82786f;
  padding: 10px;
}

.uploadBtn {
  background-color: #fff;
  color: #1592e6;
  border: 1px solid #1592e6;
  padding: 8px 10px;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 20px;
}

.br-left {
  border-left: 1px solid #82786f;
}

.library-div-border {
  border-left: 1px solid #82786f;
  margin-top: 2% !important;
}

.empty-img {
  width: 120px;
  display: block;
  margin: 0 auto;
}

.triangle-left {
  width: 0;
  height: 0;
  border-top: 12px solid transparent !important;
  border-right: 25px solid #fff !important;
  border-bottom: 12px solid transparent !important;
  position: absolute !important;
  left: 18%;
  border-right-color: rgba(0, 0, 0, .25) !important;
  border-left-color: rgba(0, 0, 0, .25) !important;
  background: #fff;
  top: 35px;
}

.attenSec {
  text-align: center;
  background: #EEEEEE;
  border-radius: 13px;
  /* width: 60%; */
  padding: 5%;
  margin: 0 auto;
  margin-top: 15%;
}

.attenTxt {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.011em;
  color: #82786F;
}

.attenIcn {
  font-size: 30px;
  margin-right: 10px;
  margin-bottom: -5px;
}

.attenIcnSec {
  color: #D52B1E;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.011em;
}

.cacheBtn {
  background: #fff;
  border: 1px solid #ccc;
  height: 34px;
  padding: 6px 4px 4px 4px;
  width: 40px;
  border-radius: 4px;
  margin-left: 15px;
}

.fixedMenuTbl {
  width: 30% !important;
}
.fixedMenuTbl tr td:first-child, table tr th:first-child {
  padding-left: 0px !important;
}
.approvedIcon {
  margin-top: 26%;
}
.selectBx {
  width: 94% !important;
  border: 1px solid #ccc;
  height: 34px;
  border-radius: 4px;
  padding-top: 2px;
  /* padding-left: 4px; */
  font-size: 12px;
  /* background: #cccccc3d; */
}

.selectBxMin {
  width: 180px;
  border: 1px solid #ccc;
  height: 34px;
  border-radius: 4px;
  padding-top: 2px;
  padding-left: 4px;
  font-size: 12px;
  background: #cccccc3d;
  margin: 10px 0;
}

/* .box {
  color: #8a6d3b;
  width: 100%;
  border: 1px solid transparent;
  margin: 10px auto;
  padding: 10px;
  font-size: 13px;
  border-color: #faebcc;
  border-radius: 4px;
  background-color: #fcf8e3;
} */

.tab-root {
  flex-grow: 1;
  background-color: #fff;
}

/* .content .upload_btn {
  color: #fff !important;
  width: 100%;
  height: 100px;
  background-color: #d52b1e !important;
} */

.content .upload_btn {
  color: #fff !important;
  width: 100%;
  padding: 0px;
  min-height: 100px;
  background-color: #d52b1e !important;
}

.text-left {
  text-align: left;
}

.libOffStch .MuiSwitch-track {
  background-color: #d52b1e !important;
  opacity: 1 !important;
}

.pending-libraries .MuiSwitch-track {
  background-color: #d5861e !important;
  opacity: 1 !important;
}

.pending-libraries .MuiSwitch-thumb {
  background: #fff !important;
}


.hideTbaChk .MuiSwitch-track {
  background-color: #d52b1e !important;
  opacity: 1 !important;
}

.textNormal {
  font-weight: 500 !important;
}

.libInactChk .MuiSwitch-track {
  background-color: #d52b1e !important;
  opacity: 1 !important;
}

.grey_row .Mui-disabled {
  background-color: #eee !important;
}

.footer {
  display: inline-flex;
  padding: 2% 0;
}

.chipText {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: 'DINPro' !important;
}

.hideScroller .MuiTabs-scrollButtons {
  display: none !important;
}

.white_row .MuiInputLabel-root {
  font-weight: 500 !important;
}

.uploadLibInner {
  z-index: 11111;
}

.imageStyle {
  height: 100%;
  width: 55%;
  margin-top: 5% !important;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.tabStaticWidth{
  width: 100% !important;
}
.addImg{
  height:27px;
  width: 25px;
}
.selectdropdown{
  display:inline-block !important;
}
.libraries-ON.MuiSwitch-track {
  background-color: #139751 !important;
  opacity: 1 !important;
}

.pending-libraries .MuiSwitch-thumb {
  background: #fff !important;
  margin-left: 20px;
}
.activeclass{
  border:1px solid #664b4b
}
.attenSecvalidationImage{
 
    width: 75% !important;
    
    margin-top: 8% !important;
    margin-bottom: 8% !important;
}
.addImg2{
  height: 25px !important;
  margin-top: 1px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .attenSec {
    width: 80%;
  }

  .footer {
    display: block !important;
  }

  .uploadLibInner {
    position: unset !important;
    width: 100% !important;
  }

  .uploadLiblistInner img {
    margin: 8px !important;
  }

  .triangle-left {
    display: none;
    margin: 0 auto;
    position: unset !important;
    border-left: 12px solid rgba(0, 0, 0, .25) !important;
    border-right: 12px solid #fff !important;
    border-bottom: 25px solid rgba(0, 0, 0, .25) !important;
    border-right-color: transparent !important;
    border-left-color: transparent !important;
  }

  .uploadLib,
  .uploadLibOuter {
    margin: 20px auto;
  }

  .cancelBtn,
  .submitBtn {
    width: 70%;
    margin: 10px 0;
  }
}

@media (max-width:1200px) {
  .library-div-border {
   border-top: 1px solid #82786f;
   border-left: none;
 }
}
@media only screen and (max-width:767px) {
 
 .uploadLibInner {
    left: 33% !important;
    width: 440px !important;
    z-index: 9999;
  }

  .triangle-left {
    left: 29% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .audAreaOuter {
    display: flex !important;
  }

}


.main-wrp .content .inner_bx {
  padding: 0 30px 0 30px;
}
.tabPanelWidth{
  width: 33%;
}
.fixed-menu-div{
  min-width: 50px;
}
.lib-img-div{
  min-height: 150px;
}