.MuiAutocomplete-inputRoot {
    padding-right: 0 !important;
}
.MuiInputBase-adornedEnd {
    /* width: 100% !important; */
    width: -webkit-fill-available !important;
}
.listhd 
{
    display: inline-block;
    max-width: 100%;
    font-weight: 700;
    margin-top: 15px;
    margin-right: 3px;
    font-size: 16px;
    color: #82786f;
}
.breakingbed { word-break: break-all; }
.textfiledBx {
    margin-top: 0px !important;
    width: 80%;
    border-bottom: 1px solid #adadad;
}
@media (max-width:767px) {
    .listhd { margin-left: 10%; }
    .txtCntrSm { text-align: center }
}
@media (max-width:960px) {
    .listhd { margin-left: 10%; }
    .txtCntrSm { text-align: center }
}
.uservalidationPopup{
    left: 49% !important;
}

.header-style{
    background: none !important;
}

.width{
    width: 100% !important;
    margin: 0% !important;
}
.box-width{
    border-bottom: 1px solid #ddd;
    /* padding: 10px 20px !important; */
    color: rgb(130, 120, 111);
    font-size: 14px !important;
    font-weight: 700;
    line-height: 24px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

@media (max-width: 663.95px) {
    .speaker-modal .MuiDialog-paperWidthSm {
        max-width: 100% !important;
    }
    .speaker-modal .speaker_upload_btn {
        margin: 0 23% !important;
    }
    .validationPopup{
        left: 40% !important;
    }
}