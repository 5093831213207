/* @font-face {
  font-family: 'DIN_Medium';
  src: url('app/public/font/din-medium.ttf') format('truetype');
} */
@import url('./responsive.css');

@font-face {
  src: url('app/public/font/DINPro.ttf') format('truetype');
  font-family: 'DINPro';
  font-style: normal;
  font-weight: normal;
}

@font-face {
  src: url('app/public/font/DINPro-Bold.ttf') format('truetype');
  font-family: 'DINPro-Bold';
  font-style: normal;
  font-weight: normal;
}

@font-face {
  src: url('app/public/font/DINPro-Light.ttf') format('truetype');
  font-family: 'DINPro-Light';
  font-style: normal;
  font-weight: normal;
}

@font-face {
  src: url('app/public/font/DINPro-Medium.ttf') format('truetype');
  font-family: 'DINPro-Medium';
  font-style: normal;
  font-weight: normal;
}

body {
  /* font-family: 'DINPro-Light' !important; */
  /* font-family: 'DINPro-Medium' !important; */
  font-family: 'DINPro' !important;
  font-style: normal;
  font-weight: normal;
  margin: 0px;
  padding: 0px;
  border: 0px;
  font-size: 100%;
}

.MuiChip-label,
.MuiButton-label,
.MuiInputBase-input,
.menulistItem {
  font-family: 'DINPro' !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.outer-wrapper {
  width: 89%;
  margin: 0 auto;
  margin-bottom: 50px;
}

.MuiFormLabel-root,
.MuiTypography-body1 {
  font-family: 'DINPro' !important;
  color: #82786F !important;
}

.main-wrp {
  width: 90%;
  margin: 30px auto;
  border-top: 1px solid #d52b1e;
  background-color: #fff;
  position: relative;
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=10, Direction=0, Color=#A59D95)";
  box-shadow: 0 0 5px 0 #a59d95;
}

.forbiddenText {
  padding-top: 1%;
  text-align: center;
  color: #82786f;
}

.mgmt_header {
  margin: 20px;
}

.mgmt_header .row {
  margin-top: 20px;
}

.filter_form {
  margin: 20px;
}

.formControl {
  width: 100%;
  margin: 10px 0 !important;
}

.searchBx {
  padding: 3px 12px;
  background: #fff;
  border: 1px solid #adadad;
  border-radius: 5px;
  float: right;
  width: -webkit-fill-available;
  margin-left: 5px;
}

.singleBtn {
  display: inline;
  background: #fff;
  border: 1px solid #adadad;
  padding: 4px 10px;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.singleBtn:hover {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.content-section {
  padding: 12px 16px 12px 16px;
}

.search-section {
  background: #F9FAFB;
  padding: 12px 16px 12px 16px;
  border-radius: 12px;
}

.createBtn {
  border: 1px solid #d52b1e !important;
  color: #d52b1e !important;
  text-transform: unset !important;
  font-family: 'DINPro' !important;
  font-weight: 900 !important;
  padding: 5px 10px !important;
}

.createBtn:hover {
  background: #d52b1e !important;
  color: #fff !important;
}

.createUploadBtn {
  border: 1px solid #d52b1e !important;
  background: #d52b1e !important;
  color: #fff !important;
  text-transform: unset !important;
  font-family: 'DINPro' !important;
  font-weight: 900 !important;
  padding: 5px 10px !important;
}

.createDownloadBtn {
  border: 1px solid #D0D5DD !important;
  color: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #D0D5DD, #D0D5DD);
  text-transform: unset !important;
  font-family: 'DINPro' !important;
  font-weight: 900 !important;
  padding: 5px 10px !important;
}

.createStatusBtn {
  border: 1px solid transparent !important;
  color: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #D0D5DD, #D0D5DD);
  text-transform: unset !important;
  font-family: 'DINPro' !important;
  font-weight: 900 !important;
  padding: 5px 10px !important;
}

.reportBtn {
  color: #000000 !important;
}

/* Commented by shreya style is specific to content management page */
/* .downloadMenu {
  margin-top: 48px;
  margin-left: 110px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
} */

/* This is causing issues in Home page Please Remove - By Shreya */
/* .MuiPaper-root {
  border-radius: 4px !important;
} */


.label-hd {
  display: inline-block;
  max-width: 100%;
  font-weight: 700;
  font-size: 1.4rem;
  color: #82786f;
}

.label-hd-title {
  color: #303030;
  font-size: 20px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

.content-count {
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #8B847E;
}

sup {
  color: #d52b1e;
}

.Mui-required .MuiFormLabel-asterisk {
  color: #f44336;
}

.MuiInput-underline:before {
  display: none !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #1592E6 !important;
}

.MuiFormLabel-root {
  margin-bottom: 5px;
  color: #82786f !important;
  display: inline-block;
  font-size: 0.9rem !important;
}

.MuiTableHead-root {
  background-color: rgb(249, 249, 249);
  border-top: 1px solid #adadad;
  border-bottom: 1px solid #adadad;
}

.MuiTableCell-root {
  font-family: 'DINPro' !important;
}

.MuiTableCell-head {
  font-weight: 900 !important;
}

.MuiTableSortLabel-root {
  font-weight: 600;
  color: #a59d95 !important;
}

.MuiTableCell-alignCenter,
.MuiTableCell-alignLeft {
  padding: 8px !important;
  font-size: 14px !important;
}

.main-wrp .header h6 {
  margin: 0 20px;
  padding: 10px 0;
  font-weight: 700;
  color: #82786f;
  font-family: 'DINPro';
}

.main-wrp .footer {
  border-top: 1px solid #a59d95;
  padding: 20px;
  text-align: center;
}

.main-wrp .content .grey_row {
  background: #f9f8f8;
  padding: 30px;
}

.main-wrp .content .white_row {
  background: #ffffff;
  padding: 30px;
}

.hidden {
  display: none !important;
}

.flex {
  display: flex !important;
}

.font14 {
  font-size: 14px !important;
}

.slick-slide {
  text-align: center;
}

.header_lillylogo {
  cursor: pointer;
  height: auto !important;
  margin-top: 15px;
  padding-bottom: 10px;
}

.iconChild {
  margin-bottom: -6px;
  margin-left: 8px;
  cursor: pointer;
}

.childDrop:hover .childDropInnr {
  display: inline-block;
}

.childDropInnr {
  display: none;
}

.subchildDrop:hover .subchildDropInnr {
  display: inline-block;
}

.subchildDropInnr {
  display: none;
}

.ulistOuter {
  max-height: 234px;
  overflow-y: auto;
  width: inherit;
  max-width: 240px;
  /*hamburger menu width changes*/
  -webkit-overflow-scrolling: touch;
}

.ulistOuter::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.ulistOuter::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
.ulistOuter::-webkit-scrollbar-thumb {
  background: #d52b1e;
  border-radius: 10px;
}

/* Handle on hover */
.ulistOuter::-webkit-scrollbar-thumb:hover {
  background: #d52b1e;
}

.ulistOuter.scrollbar {
  scrollbar-width: 5px !important;
  -ms-overflow-style: inherit;
}

/* .ulistOuter {
  scrollbar-width: none;
  -ms-overflow-style: none;
} */

.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
}

.user {
  color: #d52b1e;
  font-size: 14px;
  margin-left: 10px;
  padding-left: 15px;
  border-left: 1.5px solid #82786f;
  border-radius: 0;
  display: inline-block;
}

/* Modal CSS */
.modalstyle {
  top: 40%;
  left: 49%;
  transform: translate(-40%, -49%);
}

.paper {
  width: 400px;
  position: absolute;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
  background-color: #fff;
  outline: none !important;
}



.modalLabel {
  font-size: 1rem;
  color: #82786f;
  display: block;
  max-width: 100%;
  font-weight: 700;
  padding: 10px 15px 0px 20px;
}

.modalTxt {
  margin: 10px auto !important;
  text-align: center;
  font-size: 1.2rem;
  height: auto;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  /* box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
}

.modalTxt .MuiOutlinedInput-input {
  padding: 6px 14px !important;
}

.modalTxt .MuiOutlinedInput-root {
  margin: 0 auto;
  width: 90%;
}



.modalBtn {
  width: 30%;
  font-size: 14px;
  -webkit-appearance: none;
  color: #d52b1e;
  background-color: #fff;
  border: 1px solid #d52b1e;
  border-radius: 4px;
  padding: 8px 10px;
  margin: 0 0 0 10px;
  cursor: pointer;
  font-family: 'DINPro' !important;
  overflow: hidden;
  white-space: nowrap;
}

.btn-ok {
  margin-left: auto !important;
  margin-right: auto !important;
}

.modalBtn:hover {
  background-color: #d52b1e !important;
  color: #e6e6e6;
}

.closeBtn {
  width: 24% !important;
  font-size: 15px !important;
  justify-content: right !important;
}

.closeBtn svg {
  font-size: 26px !important;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
  font-size: 1.2rem;
  font-weight: 700;
  color: #82786f;
  border-bottom: 1px solid #ccc;
  padding: 14px !important;
  min-height: 50px;
}

.modal-title h2 {
  font-size: 18px;
  font-weight: 700;
  color: #82786f;
  font-family: 'DINPro';
}

.modal-title-2 {
  margin: 0;
  line-height: 1.42857143;
  font-size: 1.2rem;
  font-weight: 700;
  color: #82786f;
  border-bottom: 1px solid #ccc;
  padding: 12px !important;
}

.modal-title-2 h2 {
  font-size: 18px;
  font-weight: 700;
  color: #82786f;
  font-family: 'DINPro';
}

.modal-body {
  padding: 14px !important;
}

.modal-footer {
  text-align: center !important;
  border-top: 1px solid #ccc !important;
  padding: 14px !important;
}

.ulistInner .MuiCheckbox-root {
  padding-right: 2px !important;
}

.ulistInner .MuiFormControlLabel-label {
  font-size: 13px;
}

.slick-disabled {
  display: none !important;
}

.closeModal {
  width: 15px;
  height: 15px;
  background-image: url('./app/public/img/CrossGrey@3x.png');
  background-repeat: no-repeat;
  background-size: auto 100%;
  border: none;
  cursor: pointer;
  float: right;
  height: 15px;
  margin-top: 5px;
  opacity: 1;
  position: relative;
  width: 15px;
  z-index: 999;
  background-position: right top;
  background-color: transparent;
}

/* End Modal Css */

.levelDropdown {
  padding: 0px 4px 5px 4px;
  height: 30px;

}

.levelDropdown select {
  padding: 0px !important;
  background: transparent;
}


.MuiCheckbox-colorSecondary.Mui-checked {
  color: #1592e6 !important;
}

.hubs .labelTxt-user .MuiFormControlLabel-label {
  color: #a59d95 !important;
  font-weight: 600 !important;
}

.labelTxt-user .MuiFormControlLabel-label {
  color: #a59d95 !important;
  /* font-weight: 600 !important; */
}

.labeIp-user .MuiInputBase-input {
  background-color: #eee !important;
  padding: 8px 4px !important;
  color: #555 !important;
  font-size: 15px !important;
  cursor: not-allowed;
}

.labeIp-user .MuiInputLabel-formControl {
  font-size: 18px !important;
  margin-top: -8px !important;
}

.notDataFound {
  padding-left: 5px;
  font-size: 16px;
  color: #a59d95;
  text-align: left;
}

.rotate {
  animation: rotation 2s infinite linear;
}

.topBarContainer.open {
  display: none !important;
}

.cancelBtn {
  background: #fff;
  padding: 10px;
  width: 50%;
  border: 1px solid #d52b1e;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #d52b1e;
  cursor: pointer !important;
}

.submitBtn {
  background: #d52b1e;
  padding: 10px;
  width: 50%;
  border: 1px solid #d52b1e;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  cursor: pointer !important;
}

.m20 {
  margin: 20px;
}

.ul_list {
  list-style: none !important;
  padding: 0 !important;
}

.ul_list .ul_list_inner {
  width: 30%;
  display: inline-block;
}

.content .MuiFormLabel-root {
  /* padding: 4px 0px !important; */
  font-size: 14px !important;
  font-weight: 700;
  /* line-height: 20px; */
}

.content .MuiFormControl-root {
  width: 100%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@media (max-width:767px) {
  .modalBtn {
    width: 34%;
  }

  .paper {
    width: 90% !important;
  }

  .user {
    margin-left: 0px;
    padding-left: 0px;
    border-left: none;
  }

  .searchBx {
    float: none;
    display: inline-block;
  }

  .search_bar,
  .filter_icons,
  .action_buttons {
    margin: 10px 20px;
  }

  .singleBtn {
    margin-left: 0;
    margin-right: 5px;
  }

  .createBtn {
    width: 100% !important;
  }

  .createUploadBtn {
    width: 100% !important;
  }

  .createDownloadBtn {
    width: 100% !important;
  }

  .ul_list .ul_list_inner {
    width: 50%;
  }

  .modalstyle {
    top: 40%;
    left: 40% !important;
    transform: translate(-40%, -40%) !important;
  }

}

.slick-dots li button:before {
  font-size: 10px !important;
  color: #82786F !important;
}

.MuiDialog-paper {
  min-width: 30% !important;
}

.red {
  color: #d52b1e;
}

.modalBtn:disabled {
  background: lightgrey;
  cursor: not-allowed;
}

.slick-track {
  float: left !important;
}

@media (resolution:1.5dppx) {
  .custom-modalstyle {
    top: 51% !important;
  }
}

.staticPage {
  color: #82786f;
  font-size: 1rem;
  margin-bottom: 0;
  margin-top: 3px;
}

.shortWidth3 {
  width: 90%;
  text-align: justify;
}

.contentsArea {
  background-color: #fff;
  font-family: dinpro;
  min-height: 500px;
  padding: 20px 0 0 125px;
}

.staticLink {
  color: #337ab7;
}

.staticLink:hover {
  text-decoration: underline;
}

span.black-triangle,
span.black-triangle-showmore,
span.black-triangle-title,
span.black-triangle-home,
span.black-traingle-user {
  color: black !important;
}

.ssoBtn {
  color: rgb(213, 43, 30);
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid rgb(213, 43, 30);
  padding: 10px 14px 10px 14px;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
}

.red_button {
  color: rgb(213, 43, 30);
}

.grey_button {
  color: #82786f;
}

.blue_button {
  color: #337ab7;
}

.subtitle_btn {
  cursor: pointer;
  background-color: white;
  border: none;
  color: blue;
}

.font-weight-bold {
  font-weight: 900;
}

.font-weight-bold.MuiFormLabel-root {
  font-weight: 900;
}

/* Kaltura V7 mobile Styling changes */
/* if these styles cause an issue move them to content-watch.css */
/* @media (max-width: 768px) and (min-width: 300px) {
  .kaltura-player-container {
    height: 80%
  }

  .playkit-player.playkit-casting:not(.playkit-overlay-active) .playkit-bottom-bar, .playkit-player.playkit-metadata-loaded.playkit-hover:not(.playkit-overlay-active) .playkit-bottom-bar, .playkit-player.playkit-state-paused:not(.playkit-overlay-active) .playkit-bottom-bar, .playkit-player.playkit-state-idle:not(.playkit-overlay-active) .playkit-bottom-bar, .playkit-player.playkit-ad-break:not(.playkit-overlay-active) .playkit-bottom-bar, .playkit-player.playkit-menu-active:not(.playkit-overlay-active) .playkit-bottom-bar
   {
    padding: 5px 0px;
  }
} */
/* Kaltura V7 mobile Styling changes end */