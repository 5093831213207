.thumbnail-wrapper {
  position: relative;
  cursor: pointer;
  width: 100%;
  aspect-ratio: 16 / 9;
}

.thumbnail-wrapper .thumbnail-img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
}

.thumbnail-wrapper .time {
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: #000;
  color: #fff;
  font-size: 10px;
  padding: 3px 6px 5px 6px;
}
