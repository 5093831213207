.videoplayer {
    position: fixed;
    bottom: 8%;
    height: auto;
    width: 490px;
    z-index: 1100;
    background: #000;
    margin-left: auto;
    right: 40px;
}

.podcastplayer {
    position: fixed;
    bottom: 10%;
    height: auto;
    width: 490px;
    z-index: 1100;
    background: #000;
    margin-left: auto;
    right: 40px;
}

.canvas {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
}

.canvasinner {
    width: 100% !important;
    height: 100% !important;
    background: #000;

}

.minutreMob {
    width: 100px;
    height: 50px;
    overflow: hidden;
    margin-top: 4px;
}

.videoplayerMob {
    position: fixed;
    bottom: 0%;
    right: 0%;
    height: 66px;
    width: auto;
    /* z-index: 10000; */
    z-index: 1100;
    background: #000000cc;
    color: #fff;
    padding: 0px 0px;
    left: 0;
}

.textcenter {
    text-align: center;
}

.loaderGif {
    width: 25px;
    margin-top: 10px;
    margin-right: 20px;
}

.mobVideoName {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    padding-top: 10px;
    text-overflow: ellipsis;
    height: 32px;
    width: 164px;
    overflow: hidden;
    white-space: nowrap;
    margin: 0px;
    padding-left: 4px;
}

.customMobVideoName {
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    padding-top: 10px;
    text-overflow: ellipsis;
    height: 32px;
    width: 164px;
    overflow: hidden;
    white-space: nowrap;
    margin: 0px;
    padding-left: 4px;
}

.videoplayPlayMob {
    width: 12px;
    margin: 20px 20px 0 0;
}

.mobAuthName {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    padding-bottom: 0px;
    text-overflow: ellipsis;
    height: 21px;
    width: 164px;
    overflow: hidden;
    white-space: nowrap;
    margin: 0px;
    padding-left: 4px;
}

.videoplayresizeMob {
    margin: 20px 20px 0 0;
}

.videoplaycloseMob {
    margin: 20px 0px 0 0;
}

.customVideoplaycloseMob {
    margin-top: 20px;
    margin-left: 49%;
}

.videoplayerinner {
    position: relative;
    text-align: right;
    /* height: 100%; */
}

.videoplayerhover {
    height: 100%;
}

.videoplayerhover:hover {
    background: #00000096;
}

.seekbar {
    position: absolute;
    bottom: 2px;
}

.videoTime {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: #000;
    color: #fff;
    font-size: 10px;
    padding: 3px 6px 5px 6px;
}

.videoplaypause {
    position: absolute;
    text-align: center;
}

.seekbarContainer {
    height: 16%;
    background: #878684;
}

.videoprevious {
    position: absolute;
    /* left : 68%; */
    max-width: 100%;
    max-height: 24%;
    /* top: 38%; */
    /* text-align: center; */
    width: 8%;
    /* margin-left: 1%; */
    left: 1%;
}

.videonext {
    position: absolute;
    right: 0;
    /* left : 32%; */
    max-width: 100%;
    max-height: 24%;
    /* top: 38%; */
    /* text-align: center; */
    width: 8%;
    margin-right: 1%;
}

.videoplayclose {
    cursor: pointer;
    right: 5px;
    top: 5px;
    width: 21px;
    padding: 5px;
    position: absolute;
    z-index: 1100;
}

.videoplayendTime {
    position: absolute;
    right: 1%;
    /* width: 21px; */
    /* bottom: 5px; */
    /* cursor: pointer; */

}

.videoplaystartTime {
    position: absolute;
    left: 10%;
    /* width: 18px; */
    /* bottom: 5px; */
    /* cursor: pointer; */
}

.videoplayresize {
    cursor: pointer;
    left: 5px;
    position: absolute;
    top: 5px;
    width: 18px;
    padding: 5px;
    z-index: 1100;
}

.videoplaywave {
    position: absolute;
    right: 0px;
    left: 10%;
    width: 80%;
    bottom: 20px;
    cursor: pointer;

}

.videoSeekBar {
    width: 80% !important;
    right: -4%;
}

.videoplayplay {
    position: absolute;
    right: 0px;
    left: 42%;
    width: 30px;
    top: 38%;
    cursor: pointer;

}

.videoplaypause {
    position: absolute;
    right: 0px;
    left: 44%;
    width: 30px;
    top: 38%;
    cursor: pointer;

}

.customVideoplaypause {
    left: 83%;
    top: 27%;
    width: 18px;
    position: absolute;
    right: 0px;
    cursor: pointer;
    text-align: center;

}

#miniutreplayer {
    margin-top: 12%;
}

.videoplayerDesktop {
    display: block;
}

.videoplayerMobile {
    display: none
}

/* .videoplayWaveSec {
    background-image: url('../../public/img/videoplayer/Waves.png');
    background-size: cover;
    background-repeat: no-repeat;
} */

.videoplayWaveSec {
    position: relative;
    overflow: hidden;
    width: fit-content;
}

.videoplayermute {
    max-width: 100%;
    max-height: 40%;
}

.videoplayWave {
    position: absolute;
    top: 16px;
    overflow: hidden;
}

.mobCustomVideoPlayer {
    width: 100%;
    height: 210px;
}

@media (max-width:767px) {
    .mobCustomVideoPlayer {
        height: 200px !important;
    }

    .videoSeekBar {
        width: 82% !important;
        right: -5%;
        top: -16%
    }

    .seekbarContainer {
        height: 24%;
    }

    .videoContentContainer {
        /* height : 32%; */
        height: 31px;
        background: #878684;
    }

    .videoSeekbarContainer {
        height: 25%;
        background: black;
        color: white;
        font-size: 12px;
        min-height: 40px;
    }

    .videoplayer {
        width: 100%;
        right: 0px;
        bottom: 0px;
    }

    .podcastplayer {
        width: 100%;
        right: 0px;
        bottom: 0px;
    }

    .videoplayerMobile {
        display: none;
    }

    /* .videoplayerDesktop {
        display: none;
    } */

    .videoprevious {
        width: 7%;
        height: 17%;
        margin-top: 1px;
    }

    .videonext {
        width: 7%;
        height: 17%;
        margin-top: 1px;
    }

    .contentDiv {
        text-align: center;
        font-weight: 600;
        font-size: 13px !important;
    }
}

@media (min-width:768px) {
    .contentDiv {
        text-align: center;
        font-weight: 600;
        font-size: 14px !important;
    }

    .videoSeekbarContainer {
        color: white;
        font-size: 13px;
        min-height: 26px;
    }

    .videoContentContainer {
        /* height : 25%; */
        height: 30px;
        background: #878684;
    }
}

