.icon-wrapper {
  position: absolute !important;
  right: 0;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.56);
  width: 25%;
}
.icon-wrapper .icon-innder-wrapper {
  padding-bottom: 20%;
}
.icon-wrapper .icon {
  width: 100%;
}
