.country-root {
    width: 100%;
}
.country-table {
    min-width: 750;
    margin-top: 10px;
}
  .table-cell {
    padding-left: 20px !important;
  }
  .table-cell span.logo{
    background: rgb(213, 43, 30) !important;
    display: inline-block !important;
  }

  .upload_your_own{
    padding: 8px !important;
    min-width: 100px !important;
    font-size: 16px !important;
    background-color: #fff !important;
    color: #1592e6 !important;
    border: 1px solid #1592e6 !important;
    border-radius: 5px !important;
    margin: 0 auto !important;
    display: block !important;
    max-width: 68% !important;
  }

   @media (max-width:1200px) {
    .upload_your_own{
    max-width: 169px !important;
   }
  }