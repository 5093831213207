.embedChip:hover {
  cursor: pointer;
  color: #d52b1e !important;
}

.grey_row {
  background-color: #f9f9f9 !important;
}

.white_row {
  background-color: white !important;
}

.shiftedfield .MuiFormLabel-root,
.shiftedfield .MuiFormGroup-root {
  padding: 8px !important;
}

.shiftedfield .MuiFormLabel-root {
  background: #f9f9f9 !important;
  border-bottom: 1px solid #ddd !important;
  padding: 14px 8px !important;
}

.padRight2 {
  padding-right: 2% !important;
}
.plistField {
  background-color: #fff !important;
}
.ml10 {
  margin-left: 10px !important;
}
.editImage{
  cursor:pointer !important
}
.ml20 {
  margin-left: 20px !important;
}

.bold {
  font-weight: 600 !important;
}

.contentTreeview .MuiFormControlLabel-root {
  margin: 0 !important;
}

.contentTreeview .MuiFormControlLabel-root .MuiCheckbox-root {
  padding: 1px !important;
}

.contentTreeview .MuiCollapse-wrapper .MuiTreeItem-iconContainer {
  display: none !important;
}

.textArea {
  font-family: dinpro;
  color: #a59d95;
  font-size: 14px;
  font-weight: normal;
  padding: 3% 6% !important;
  height: 35px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.row_inner_box {
  padding: 30px 0 !important;
  margin: 0 30px !important;
}

.white_row .row_inner_box {
  border-bottom: 1px solid #ddd !important;
}

.ds_title {
  font-size: 22px !important;
  color: #82786F !important;
}

.ds_upload_pregress {
  font-size: 18px !important;
}

.ds_upload_pregress .uploadStatus {
  color: #8DC211 !important;
}

.ds_upload_pregress .uploadPerc {
  color: #8DC211 !important;
  margin-left: 10px;
}

.ds_kalturaid {
  font-size: 14px !important;
  text-align: right !important;
  padding-top: 10px !important;
}

.ds_header {
  border-bottom: 1px solid #EAEAEA !important;
  padding-bottom: 10px !important;
  margin: 0 20px !important;
}

.ds_form .MuiFormGroup-root {
  flex-direction: row !important;
}

.ds_form .MuiFormLabel-root {
  font-family: 'DINPro' !important;
  font-style: normal !important;
  font-size: 16px !important;
  color: #82786F;
}

.ds_form .MuiFormControl-root {
  width: 100% !important;
  margin: 5px 0 !important;
}

.ds_form .inlineFields .field {
  display: inline !important;
}

.ds_form .inlineFields .MuiFormControl-root {
  width: 100% !important;
}

.MuiFormHelperText-contained {
  margin-left: 0 !important;
}

.chipInput .MuiOutlinedInput-adornedStart {
  padding-top: 0 !important;
}

.chipInput .MuiOutlinedInput-input {
  padding: 0 !important;
}

.chipInput .MuiChip-clickable {
  margin: 0 5px 0 0 !important;
  background-color: #cde69c;
  border-radius: 2px;
  border: 1px solid #a5d24a;
  color: #638421;
}

.field_tbl {
  border-collapse: collapse !important;
}
.field_tbl tr td:first-child, .field_tbl tr th:last-child {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.field_tbl th,
.field_tbl td {
  padding: 8px !important;
  border-bottom: 1px solid #ddd;
}

.field_tbl thead {
  height: 40px !important;
  background-color: #f9f9f9 !important;
  text-align: left !important;
  font-size: 14px;
  color: #a59d95;
}

.field_tbl .MuiFormControl-root {
  margin: 0 !important;
}

.borderBottom {
  border-bottom: 1px solid #ddd !important;
}

.addIconBtn,
.removeIconBtn,
.editIconBtn {
  color: #1592E6 !important;
  cursor: pointer !important;
  margin: 0 !important;
  padding: 0 !important;
  justify-content: left !important;
}

.addIconBtn .MuiButton-label,
.removeIconBtn .MuiButton-label,
.editIconBtn .MuiButton-label {
  font-size: 16px !important;
  text-transform: none !important;
  justify-content: left !important;
  text-align: left !important;
}

.addIconBtn .MuiButton-label img,
.removeIconBtn .MuiButton-label img,
.editIconBtn .MuiButton-label img {
  margin-right: 10px !important;
}

.country_list {
  list-style: none !important;
  padding: 0 !important;
}

.country_list .hubs {
  padding: 0 10px !important;
}

.country_list .affiliates {
  padding: 0 20px !important;
}

.country_list .country_list_inner {
  width: 24%;
  display: inline-block;
}

.content_alignCenter {
  justify-content: center !important;
  align-items: center !important;
}

.stepper_container {
  margin: 0 auto !important;
  background: #F9F9F9 !important;
  border: 1px solid #FFFFFF !important;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25) !important;
  border-radius: 2px !important;
  margin-top: 1% !important;
  margin-bottom: 10% !important;
}

.content_form1 .MuiStepper-root {
  background: #F9F9F9 !important;
}

.content_labelColor .MuiStepLabel-label {
  color: #82786f !important;
  font-family: 'DINPro' !important;
  font-size: 14px !important;
}

.content_labelColor .MuiStepLabel-label.MuiStepLabel-active {
  color: #d52b1e !important;
}

.content_labelColor .MuiStepLabel-label.MuiStepLabel-completed {
  color: #d52b1e !important;
}

.content_dZone {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding: 3% 0;
  background-color: #f9f9f9;
  color: #82786F;
  font-size: 18px !important;
}

.content_font16 {
  font-size: 16px;
}

.content_uploadSectionF {
  padding: 5% 9%;
}

.content_uploadSectionU {
  padding-right: 5%;
  padding-left: 9%;
}

.content_uploadSection {
  display: inline-flex !important;
  vertical-align: middle !important;
  width: 100%;
}

.content_disclaimer {
  color: #A59D95;
  font-size: 14px;
  line-height: 20px;
  margin-top: 3%;
  margin-right: 20px;
}

.content_kalturaLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.011em;
  color: #82786F;
  padding: 0.7% 0;
  line-height: 17px;

}

.content_textfield {
  width: 100%;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 41px !important;
  letter-spacing: -0.011em !important;
  color: #9B9B9B !important;
  background: #FFFFFF !important;
  border: 1px solid #E4E4E4 !important;
  border-radius: 3px !important;
}

.content_textfield .MuiFormLabel-root {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #9B9B9B !important;
  line-height: 1px;
}

.content_uploadBtnSec {
  margin-top: 5px !important;
}

.content_uploadBtnSec button {
  background-color: #ffffff;
  font-size: 16px !important;
}

.MuiLinearProgress-bar {
  background-color: #d52b1e !important;
}

.content_input {
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 41px;
  letter-spacing: -0.011em;
  color: #9B9B9B;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  border-radius: 3px;
}

.content_inputSec {
  margin: 0 20px !important;
}

.content_loader {
  height: 26px;
}

.dialogTitle {
  padding: 3% !important;
  text-align: right !important;
}

.dialogContent {
  color: #8a6d3b;
  background-color: #fcf8e3;
  margin: 0 3% !important;
  font-family: Din Pro !important;
  border: 1px solid #faebcc;
  border-radius: 4px;
}

.dialogContent p {
  font-size: 14px !important;
  line-height: 20px !important;
  font-family: 'DINPro' !important;
}

.dialogCloseBtn {
  color: #333 !important;
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  font-size: 16px !important;
  text-transform: none !important;
  font-family: 'DINPro' !important;
  margin: 3% !important;
  padding: 0.8% 2% !important;
}

.dialogActions {
  border-top: 1px solid #e5e5e5;
  margin-top: 7% !important;
  padding: 0 !important;
}

.content_updateStepperCss .MuiStepConnector-alternativeLabel {
  top: 22px !important;
}

.content_updateStepperCss .MuiStepConnector-active .MuiStepConnector-line {
  background-color: #d52b1e !important;
}

.content_updateStepperCss .MuiStepConnector-completed .MuiStepConnector-line {
  background-color: #d52b1e !important;
}

.content_updateStepperCss .MuiStepConnector-line {
  height: 2px !important;
  border: 0 !important;
  background-color: #eaeaf0 !important;
  border-radius: 1px !important;
}


.alignContent {
  display: flex;
  margin-bottom: 3% !important;
  word-wrap: break-word;
}

.alignContent .MuiGrid-root {
  font-family: 'DINPro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.011em;
  color: #82786F;
}

.preview_container {
  width: 332px !important;
  height: auto !important;
  max-height: 149px !important;
  border: 1px solid #E9E9E9;
  position: relative;
}

.preview_content {
  width: 150px !important;
}

.loadingDiv {
  text-align: center;
  padding: 100px 0;
}

.loadingDiv img {
  vertical-align: middle;
}

.loadingDiv span {
  color: #d52b1e;
  margin-left: 10px;
}

.close_preview {
  position: absolute !important;
  top: 0;
  right: 0;
  background: #E9E9E9 !important;
  height: 38px !important;
  width: 38px !important;
  min-width: 38px !important;
  border-radius: 0 !important;
}

.vvpmTbl {
  border-collapse: collapse !important;
  margin-bottom: 2.5% !important;
  width: 100% !important;
}

.timeRange {
  border-collapse: collapse !important;
  margin-bottom: 2.5% !important;
  width: 100% !important;
}

.alignCenter .formControl {
  text-align: center !important;
}


.quizTextfield .textfiledBxModal .MuiInputBase-input {
  background-color: #eee !important;
  color: #555 !important;
}

.closeBtn {
  width: 100% !important;
}

.vvpmTbl td {
  margin: 0 auto !important;
  padding: 0 !important;
  font-family: dinpro;
  color: #a59d95;
  font-size: 14px;
  font-weight: normal;
}

.videoTbl {
  width: 100% !important;
  border-collapse: collapse;
}

.videoTbl tr {
  border-bottom: 1px solid #ddd !important;
}

.videoTbl th {
  padding-bottom: 1.5% !important;
}

.videoTbl td {
  margin: 0 auto !important;
  padding: 0 !important;
  font-family: dinpro;
  color: #a59d95;
  font-size: 14px;
  font-weight: normal;
}

.videoTbl tr td:first-child, .videoTbl tr th:first-child {
  padding-left: 0px !important;
}

.videoTbl tr td:last-child, .videoTbl tr th:last-child {
  padding-right: 0px !important;
}

.timeRangeBx {
  margin-top: 0px !important;
  width: 80%;
  border: 1px solid #adadad;
  height: 37px;
  border-radius: 4px;
}

.timeRange td {
  margin: 0 auto !important;
  padding: 0 !important;
  font-family: dinpro;
  color: #a59d95;
  font-size: 14px;
  font-weight: normal;
 
}
.timeRange td .MuiGrid-item {
  margin-top: 1.5% !important;
}

.contentLibrary .ul_list {
  margin: 0 !important;
}

.libraryCountrySelected {
  line-height: 18px !important;
}

/* table tr td:first-child,
table tr th:first-child {
  padding-left: 0px !important;
  padding-right: 0px !important;
} */

.vvpmTbl table td {
  color: #383739 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  text-align: center !important;
  padding: 0.7% 0 !important;
}

.vvpmTbl table tr:nth-child(even) {
  background: #EEEDED !important;
}

.cancelApproval {
  width: 17% !important;
  font-size: 15px !important;
}

.vvpmTbl .textfiledBx {

  width: 80% !important;
}

.vvpmTbl table {
  margin-top: 2% !important;
  width: 100% !important;
  border-spacing: 0 !important;
}

.vvpmTbl table thead th {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #82786F;
  background: #F2F0EF !important;
  border-radius: 4px 4px 0px 0px !important;
  padding: 0.4% !important;
}

.vvpmTbl thead {
  background: #F2F0EF;
  border: 2px solid #D3D3D3;
}

.contentPublish .vvpmTbl thead {
  text-align: left !important;
}

.approvedIcon {
  margin-top: 50%;
}

#kaltura_video_player {

  width: 100%;

  height: 242px;

}


.searchVVPMCodeTxt {
  color: #1592E6;
  font-weight: 700;
  cursor: pointer;
}

.vvpmDialogTitle h2 {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #1692E6;
  font-family: 'DINPro' !important;
}

.vvpmDetailsDialogTitle h2 {
  font-weight: 700 !important;
  font-size: 22px !important;
  color: #3F5580;
  font-family: 'DINPro' !important;
  border-bottom: 1px solid #DCD9D6;
  padding-bottom: 1.5% !important;
}

.searchVVPMItem {
  width: 49%;
  margin-left: 2%;
}

.VVPMDetailsGrid table {
  margin-top: 0 !important;
}

.VVPMDetailsGrid {
  width: 90%;
  margin: 30px auto;
  background-color: #fff;
  position: relative;
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=10, Direction=0, Color=#A59D95)";

  border: 2px solid #D3D3D3;
  border-radius: 4px 11px 11px 4px;
}


.VVPMDetailsGrid thead {
  background: #F2F0EF !important;
}

.searchLabel {
  font-size: 20px !important;
  color: #333333 !important;
  font-weight: 700;
  line-height: 33px;
}

.VVPMDetailsGrid .MuiTableCell-alignLeft {

  font-size: 16px !important;
  text-align: center !important;
}

.VVPMDetailsGrid .MuiTableHead-root {
  border-top: none !important;
  border-radius: 4px 11px 11px 4px;

}

/* .VVPMDetailsGrid table tr td:first-child,
table tr th:first-child {
  padding-left: 2% !important;
} */

.textfiledBxModal {
  width: 95% !important;
  margin: 0 !important;

}

.disabledText input {
  background-color: #eee !important;
  padding: 8px 4px !important;
  color: #555 !important;
  font-size: 15px !important;
  cursor: not-allowed;
}

.selectVVPMdetails input {
  font-size: 14px !important;
}

.selectQuizdetails input {
  font-size: 14px !important;
}

.selectVVPMdetails .MuiFormControl-fullWidth {
  width: 95% !important;
}

.textfiledBxModal input {
  font-size: 14px !important;
}

.vvpmDialogContent {
  margin-bottom: 3% !important;
}

.margin5 {
  margin-left: 5% !important;
}


.searchVVPMCodeDisabled {
  background-color: #919191 !important;
  border-radius: 5px;
  width: 95% !important;
  color: #FFFFFF !important;
  margin-top: 10% !important;
  margin-left: 5% !important;
  text-transform: none !important;
}

.selectedText {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #000000 !important;
}

.taxonomyDataDialogContent .formControl .MuiFormLabel-root {
  font-weight: 700 !important;
}

.brandDialogTitle {
  border-bottom: 1px solid #ccc;
}

.brandDialogTitle h2 {
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #1692E6;
  font-family: 'DINPro' !important;
}

.brandModal .modalBtn {
  width: 50% !important;
  margin: 0;
}

.searchVVPMCode {
  background-color: #D52B1E !important;
  border-radius: 5px;
  width: 95% !important;
  color: #FFFFFF !important;
  margin-left: 5% !important;
  margin-top: 10% !important;
  border: none !important;
  text-transform: none !important;
}

.justifyCenter {
  justify-content: center !important;
}

.selectVVPMCode {
  background-color: #D52B1E !important;
  border-radius: 5px;
  color: #FFFFFF !important;
  border: none !important;
  text-transform: none !important;
}

.disable-btn{
  background-color: darkgrey !important;
}

.alignCenter {
  text-align: center !important;
}

.timeRange .textfiledBx {
  width: 92% !important;
}

.deleteVVPMDetail {
  width: 16% !important;
}

.productChar .MuiInput-underline.Mui-error:after {
  border-bottom: 1px solid #ccc !important;
}

.canclBtn {
  background-color: #F2F0EF !important;
  text-transform: none !important;
}

.normalFont {
  font-weight: normal !important;
}

.ds_form .reducePadding {
  margin: 0 !important;
}

.editIMG{
  cursor: pointer;
}


@media (max-width:500px) {

  .overFlowAuto {
    overflow: auto;
  }

  .playlistBtns .ml10 {
    margin: 0 !important;
  }
  
  .playlistBtns .MuiButton-root {
  margin-top: 2% !important;
  }

  .content_uploadSection {
    display: block !important;
  }

  .content_kalturaLabel {
    text-align: center !important;
    padding: 0 !important;
  }

  .content_inputSec {
    margin: 4% 0 !important;
  }

  .content_uploadBtn {
    margin: 0 auto !important;
    display: block !important;
    width: 100%;
  }

  .selectedAction {
    text-align: center !important;
  }

  .approvedIcon {
    margin-top: 8% !important;
    margin-bottom: 0 !important;
  }

  .alignCenter .MuiButtonBase-root {
    justify-content: left !important;
  }


  .alignCenter .formControl {
    text-align: left !important;
  }


  .quizSection .ml10 {
    margin-left: 0 !important;
  }

  .vvpmTbl .textfiledBx {
    width: 90% !important;
  }
}


@media only screen and (max-width:767px) {
  .ds_form .inlineFields .MuiFormControl-root {
    width: 100% !important;
  }

  .ds_form .inlineFields .field {
    margin: 0 !important;
  }

  .country_list .country_list_inner {
    width: 50%;
  }
}