/* Content List CSS */
.search_header {
  box-shadow: none !important;
  margin: 20px 0 20px 0 !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 10px !important;
  letter-spacing: -0.011em !important;
  color: #82786F !important;
}

@media (min-width: 1050px) {

  .container-height {
    min-height: 78vh !important
  }

}

.search_header .search_header_subsection {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.search_header span {
  font-size: 16px;
  color: #D52B1D;
}

.search_header div.section-title {
  padding: 8px
}
.search_header div.section-title span{
  line-height: 1.2rem;
}

.search_header .secTitle span b {
  font-weight: unset !important;
}

.search_header .secTitle {
  display: inline-block;
  line-height: normal;
  word-break: break-word;
  /* margin-top: 20px; */
}

.search_header .sortOrderDP {
  display: inline-block;
  margin-left: 32px;
  border: 1px solid #d52b1d;
  font-size: 16px;
  color: #d52b1d;
  padding: 0 !important;
  height: 24px !important;
  padding-top: 4px !important;
}

.search_header .sortOrderDP span {
  font-weight: 900 !important;
  margin-left: 5px;
}

.search_header .sortOrderDP .MuiSelect-select {
  color: #d52b1d;
  padding: 0 40px !important;
}

.search_header .sortOrderDP .MuiSelect-select:focus {
  background: none;
}

.search_header .sortOrderDP .selectDP .MuiInput-input {
  font-size: 16px !important;
  font-family: DinPro !important;
}

.search_header .sortOrderDP svg {
  display: none;
}

.search_header .sortOrderDP div:after {
  content: '>';
  font: 15px "Consolas", monospace;
  color: #d52b1d;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 8px;
  position: absolute;
  pointer-events: none;
}

.sortOrderDPItem .MuiPaper-root {
  top: 205px !important;
  box-shadow: none !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 0 0 4px 4px !important;
}

.sortOrderDPItem .MuiMenu-list {
  padding: 0 !important;
  color: #82786F !important;
}

.sortOrderDPItem .MuiMenu-list .MuiMenuItem-root {
  padding: 2px 0px 1% 25% !important;
  border-bottom: 1px solid #e0e0e0 !important;
  font-size: 16px !important;
  font-family: DinPro !important;
}

.sortOrderDPItem .MuiListItem-root.Mui-selected {
  background-color: unset !important;
}

.sortOrderDPItem .MuiListItem-button:hover {
  color: #d52b1d;
  background-color: rgba(0, 0, 0, 0.04) !important;
}


/*custom dropdown starts*/

.custom-sortOrderDPItem .MuiPaper-root {
  margin-top: 5px !important;
  /* top: 205px !important; */
  box-shadow: none !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 0 0 4px 4px !important;
}

.custom-sortOrderDPItem .MuiMenu-list {
  padding: 0 !important;
  color: #82786F !important;
}

.custom-sortOrderDPItem .MuiMenu-list .MuiMenuItem-root {
  /* padding: 2px 0px 1% 25% !important; */
  border-bottom: 1px solid #e0e0e0 !important;
  font-size: 16px !important;
  font-family: DinPro !important;
}

.custom-sortOrderDPItem .MuiListItem-root.Mui-selected {
  background-color: unset !important;
}

.custom-sortOrderDPItem .MuiListItem-button:hover {
  color: #d52b1d;
  background-color: rgba(0, 0, 0, 0.04) !important;
}

/*custom dropdown ends*/

.simg {
  width: 100%;
}

.podcastTag-content {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  display: inline-block !important;
  align-items: center !important;
  letter-spacing: -0.011em !important;
  text-transform: uppercase !important;
  color: #8DC211 !important;
  border: 2px solid#8DC211 !important;
  padding: 1px 14px !important;
  margin: 0px 10px 0 0px !important;
  border-radius: 12px !important;
  float: none !important;
  box-shadow: none !important;

}

.playlistTag-content {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  display: inline-block !important;
  align-items: center !important;
  letter-spacing: -0.011em !important;
  text-transform: uppercase !important;
  color: #5b544e !important;
  border: 2px solid#5b544e !important;
  padding: 1px 14px !important;
  margin: 0px 10px 0 0px !important;
  border-radius: 12px !important;
  float: none !important;
  box-shadow: none !important;

}

.liveTag-content {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  display: inline-block !important;
  align-items: center !important;
  letter-spacing: -0.011em !important;
  text-transform: uppercase !important;
  color: #1138c2 !important;
  border: 2px solid#1138c2 !important;
  padding: 1px 14px !important;
  margin: 0px 10px 0 0px !important;
  border-radius: 12px !important;
  float: none !important;
  box-shadow: none !important;

}

.videoTag-content {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  display: inline-block !important;
  align-items: center !important;
  letter-spacing: -0.011em !important;
  text-transform: uppercase !important;
  color: #d52b1d !important;
  border: 2px solid#d52b1d !important;
  padding: 1px 14px !important;
  margin: 0px 10px 0 0px !important;
  border-radius: 12px !important;
  float: none !important;
  box-shadow: none !important;

}

.black-triangle-showmore {
  color: black;
}

.cont-title {
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #A59D95 !important;
  margin: 5px 0 !important;
  text-align: left;
}

.cont-title:hover {
  cursor: pointer;
  text-decoration: underline;
}

.cont-speaker {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #A59D95 !important;
  margin: 5px 0 !important;
  text-align: left;
}

.cont-desc {
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #82786F !important;
  margin: 5px 0 !important;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  width: 100%;
  /* height: 30px; */
}


.cont-desc div span,
.cont-desc div p,
.cont-desc div div {
  font-size: 12px !important;
}

.svgIcon-content {
  margin: 0 auto;
  vertical-align: bottom;
  line-height: normal;
  height: -webkit-fill-available;
  margin-top: 70%;
  display: inline !important;
  width: 20px;
}

.m20Top {
  margin-top: 20px;
}

.noData {
  color: #82786F !important;
  font-weight: 800;
  margin-top: 20px;
}

.svgIcon-content.playlist {
  margin: 0 auto;
  vertical-align: bottom;
  line-height: normal;
  /* height: fit-content; */
  margin-top: 80%;
  display: inline !important;
  width: 50px !important;
}

.sOuter {
  background: #fff !important;
  margin: 10px 10px 20px 10px !important;
  width: 95% !important;
  padding: 0 0.7%;
}

.loadSvg {
  width: 25px;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.clickableLink {
  display: table;
  cursor: pointer;
}

@media (max-width:960px) {
  .sortOrderDPItem .MuiPaper-root {
    top: 261px !important;
  }

  .sOuter {
    width: 100% !important;
    margin: 0 !important;
  }

  .desc-content {
    width: 100% !important;
  }

  .svgIcon-content {
    margin-top: 100% !important;
    width: 25px !important;
  }

  .svgIcon-content.playlist {
    height: 50% !important;
    margin-top: 70% !important;
  }
}

@media (max-width: 700px) {
  .search_header .sortOrderDP .selectDP .MuiInput-input {
    max-width: 225px;
    padding-left: 7px !important;
  }

  .sortOrderDPItem .MuiPaper-root {
    top: 322px !important;
  }

  .search_header .secTitle span {
    /* display: block !important; */
    line-height: 40px;
  }

  .search_header .secTitle span b {
    /* display: none !important; */
    font-weight: unset;

  }

  .sortOrderDP {
    margin-left: 24% !important;
  }

}

@media (max-width:600px) {

  .cont-desc {
    height: 70px !important;
  }


  .desc-content {
    width: 100% !important;
  }

  .svgIcon-content {
    margin-top: 100% !important;
    width: 25px !important;
  }

  .sortOrderDPItem .MuiPaper-root {
    /* top: 310px !important; */
    top: 320px !important;
  }

  .search_header {
    display: inline-block;
    width: 100%;
  }

  .search_header .secTitle {
    display: block;
  }

  .search_header .sortOrderDP {
    float: right;
    width: max-content;
    margin: 10px 0 10px 0 !important;
  }

  .svgIcon-content.playlist {
    height: 40% !important;
    margin-top: 136% !important;
  }
}

@media (min-width: 961px) and (max-width: 1200px) {

  .svgIcon-content.playlist {
    height: 40% !important;
    margin-top: 90% !important;
  }

}

@media (min-width: 1600px) {
  .svgIcon-content.playlist {
    width: 50px !important;
    height: 50px !important;
    margin-top: 100% !important;
  }
}

.m-0-5 {
  /* margin-top: 0px !important; */
  margin-bottom: 25px !important;
}

@media (resolution:1.25dppx) or (resolution:1.1dppx) {
  .custom-clickableLink {
    font-size: 13px;
  }

  .custom-shared-video-tag {
    line-height: 12px !important;
    padding: 1px 7px 2px 7px !important;
  }
}

@media (max-width: 325px) {
  .svgIcon-content.playlist {
    height: 40% !important;
    margin-top: 110% !important;
  }
}

@media (min-width: 1280px) {
  .large-device-contents {
    display: block;
  }

  .medium-device-contents {
    display: none;
  }

  .small-device-contents {
    display: none;
  }
}

@media (min-width: 577px) and (max-width: 1279px) {
  .large-device-contents {
    display: none;
  }

  .small-device-contents {
    display: none;
  }

  .medium-device-contents {
    display: block;
  }
}

@media (max-width: 576px) {
  .large-device-contents {
    display: none;
  }

  .medium-device-contents {
    display: none;
  }

  .small-device-contents {
    display: block;
  }
}

@media (min-width: 767px) and (max-width: 1181px) {
  .shared-cont-title {
    margin: 4px 0 !important;
  }

  .simg-lg {
    min-height: 88px;
    min-width: 177px;
  }

}

.related-contents-pl10 {
  padding-left: 10px;
  overflow: hidden;
}

.cont-desc p {
  margin: 0px;
}

.medium-editor-placeholder:after {
  font-style: normal !important;
  content: 'Video Description' !important;
}