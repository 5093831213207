@media (max-width:767px) {

    .slick-arrow {
        display: none !important;
    }

    .header_lillylogo {
        width: 45% !important;
        vertical-align: middle !important;
        height: 68px !important;
    }

    /* .slick-initialized .slick-slide {
        width: 11% !important;
        margin-left: 0.7%;
    }

    .slick-list {
        padding: 0 !important;
    } */

    .audArea {
        width: 100% !important;
    }

    .label-hd {
        font-size: 1.2rem;
    }

    

}

@media (max-width:1023px) {
    .sOuterlg {
        width: 330px !important;
        /* min-height: 300px !important; */
        margin: 0 6px 0 4px;
    }

    .sOuter {
        width: 222px !important;
    }
}

@media (max-width:960px) {
    .svgIcon {
        width: 20px !important;
        /* top: 45px !important; */
        top: 23% !important;
    }

    .label-hd {
        font-size: 20px !important;
    }
}

@media (min-width: 820px) and (max-width: 900px) {
    .sOuterlg {
        width: 352px !important;
        margin: 0 6px 0 4px !important;
    }

    .sOuter {
        width: 238px !important;
    }

    .svgIcon {
        width: 28px !important;
    }

}

@media (min-width: 961px) and (max-width:1024px) {
/*@media (max-width:1024px) */
    /* .slick-arrow {
        display: none !important;
    } */
    .svgIcon {
        width: 20px !important;
        /* top: 45px !important; */
        /* top: 45px !important; */
    }
}

@media (max-width:600px) {
   .sOuterlg {
    margin: 0 6px 0 4px!important;
    min-width: 215px!important;
    width: 250px!important;
    }

    .cont-startdate-home.hide-xs {
        display: none;
    }

    .cont-startdate-home.show-xs {
        display: block;
    }

    .most-recent-slider .sOuterlg .podcastTag,
    .most-recent-slider .sOuterlg .liveTag,
    .most-recent-slider .sOuterlg .videoTag,
    .most-recent-slider .sOuterlg .playlistTag {
        padding-left: 8px !important;
        padding-right: 8px !important;
        max-width: 86px;
    }

    .most-recent-slider .sOuterlg .podcastTag,
    .most-recent-slider .sOuterlg .liveTag,
    .most-recent-slider .sOuterlg .videoTag,
    .most-recent-slider .sOuterlg .playlistTag,
    .most-recent-slider .sOuterlg .upName,
    .most-recent-slider .sOuterlg .cont,
    .most-recent-slider .sOuterlg .contDate,
    .most-recent-slider .cont-startdate-home,
    .most-recent-slider .cont-piinfo-home,
    .most-recent-slider .BtnSec {
        margin-left: 8px !important;
        margin-right: 8px !important;
    }

    .timerArea {
        padding-right: 8px !important;
    }

    .watch-resourceSec {
        min-height: 190px !important;
        width: 90% !important;
    }

    .shared-svgIcon-content-playlist, .svgIcon-content-playlist {
        margin-top: 120px;
    }

    .showBtn {
        margin-right: -10px !important;
    }
}

@media (max-width: 400px) and (min-width: 371px) {
    .svgIcon {
      top: 26% !important;
    }
    .samsung-brow-svgIcon {
       top: 20% !important;
    }
  }
  
  @media (max-width: 370px) and (min-width: 300px) {
    .svgIcon {
      top: 50% !important;
      }
      .samsung-brow-svgIcon{
        top: 25% !important;
      }
  }

  @media (resolution:1.5dppx){
    .svgIcon {
      top: 53% !important;
      }
  }
  
  @media (max-width: 800px) and (min-width: 700px) {
    .sOuter {
        width: 235px !important;
    }
} 