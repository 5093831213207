.formControl.select .MuiInputLabel-formControl {
    padding: 0 !important;
    line-height: normal;
}
.autocompleteInput input{
    width: 100% !important;
}
.filter_form .MuiOutlinedInput-root {
    width: 100% !important;
}
.event_details{
    padding-top: 1%;
}
.event_logo_upload_btn{
    border: 1px solid #0599cd !important;
    color: #0599cd !important;
}
.content p {
    margin: 0 !important;
}
.lillyIcon {
    height: auto;
    width: 38%;
    padding: 3% 5%;
}
.header {
    background: #f9f9f9;
    border-bottom: none !important;
}
.headerContent {
    margin: 0 2%;
    border-bottom: 1px solid #ddd;
}
.headerContent h6 {
    margin: 0 !important;
}
.grey_row {
    background: #f9f9f9;
}
.content {    
    border-bottom: 1px solid #ddd;
    padding: 10px 20px;
    font-size: 14px !important;
    font-weight: 700;
    line-height: 24px;
}


.field_tbl {
    margin-bottom: 2.5% !important;
    width: 100% !important;
}
.field_tbl tr {
    padding: 8px !important;
    border-bottom: 1px solid #ddd;
}
.field_tbl td {
    margin: 0 auto;
    padding: 0;
    font-family: dinpro;
    color: #a59d95;
    font-size: 14px;
    font-weight: normal;
}
.tableData {
    margin: 0 0 1.5% 0;
    padding: 0;
    font-family: dinpro;
    color: #a59d95;
    font-size: 14px;
    font-weight: normal;
}
/* .tableData .MuiTreeItem-label {
    font-size: 14px;
} */
.blankDiv {
    padding: 20px;
    border-bottom: 1px solid #ddd;
    width: 100%;
}
.speaker-modal .MuiPaper-root {
    min-width: 25%;
}

@media (max-width:600px) {
    .uploadHdSec {
        padding: 7px !important;
    }
    .speaker-modal .MuiPaper-root {
        width: 100% !important;
    }
}

.eventActionStyle{
    display: flex; align-items: center; justify-content: center;
}