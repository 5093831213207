.calendar-container .calendar-header {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.calendar-container .calander-name {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #F2F4F7;
}

.calendar-container .calander-name p {
    color: #d52b1e;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    margin: 5px;
}

.calendar-header span {
    color: #667085;
}

.calendar-header span:hover {
    cursor: pointer;
}

.calendar-header p {
    color: #344054;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
}

.calendar-body {
    display: grid;
    justify-content: space-evenly;
}

.calendar-body ul {
    list-style: none;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.calendar-body .calendar-weekdays {
    color: #344054;
    font-weight: 600;
    text-align: center;
}

.calendar-body .day {
    text-align: center;
}

.calendar-body .calendar-dates li {
    padding: 10px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    color: #344054
}


.calendar-dates li.active-day {
    background: #F2F4F7;
    color: #344054;
    border-radius: 50%;
    font-weight: 600;
    text-align: center;
}

.calendar-dates li.range-day {
    background: #F2F4F7;
    color: #344054;
}

.calendar-dates li.range-day:hover {
    background: #F2F4F7;
    color: #344054;
    border-radius: 0px;
}
.calendar-dates li.disabled-day {
    color: #A59D9596;
}
.calendar-dates li.disabled-day:hover {
    cursor: not-allowed;
}

.calendar-dates .day:hover {
    background: #F2F4F7;
    color: #344054;
    border-radius: 50%;
}

.calendar-dates li.selected-day {
    background: #E02F2B;
    color: #fff;
    /* border-radius: 50%; */
    text-align: center;
    font-weight: 600;
}

.calendar-dates li.selected-day.start {
    border-radius: 50% 0% 0% 50%;
}

.calendar-dates li.selected-day.end {
    border-radius: 0% 50% 50% 0%;
}